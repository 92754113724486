import { useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

function safeJSONParse(value:string) {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
}

function safeParamValue(value:any):string {
  if (typeof value === "string") return value;
  return JSON.stringify(value);
}

/**
 *
 * This function enriches the default state with the values from the url query string.
 */
export function getQueryState<State extends object>(defaultState:State, urlStateKeys:string[]):State {

  let location = window.location;
  const query = new URLSearchParams(location.search);

  function getParam(param:string, defaultValues:any) {
    let values;
    
    if (Array.isArray(defaultValues)) {
      values = query.getAll(param) || defaultValues;
      
      values = values.map(value=> {
        if (typeof value === "string") value = JSON.parse(value)
        return value;
      });
    } else {
      values = query.get(param) || defaultValues;
      
      if (typeof values === "string"){
        values = safeJSONParse(values);
      }
    }
    return values;
  }

  const state = {} as State;
  Object.keys(defaultState).forEach((key) => {
    let value = defaultState[key as keyof State];
    if (urlStateKeys.includes(key)){
      value = getParam(key, value);
    };
    state[key as keyof State] = value;
  });
  return state;
}

export function updateStateFromUrl<State extends object>(state:State, urlStateKeys:string[]) {

  let location = window.location;
  const query = new URLSearchParams(location.search);

  function getParam(param:string, defaultValues:any) {
    let values;
    
    if (Array.isArray(defaultValues)) {
      values = query.getAll(param) || defaultValues;
      
      values = values.map(value=> {
        if (typeof value === "string") value = JSON.parse(value)
        return value;
      });
    } else {
      values = query.get(param) || defaultValues;
      
      if (typeof values === "string"){
        values = safeJSONParse(values);
      }
    }
    return values;
  }

  urlStateKeys.forEach((key) => {
    let value = state[key as keyof State];
    if (urlStateKeys.includes(key)){
      value = getParam(key, value);
    };
    state[key as keyof State] = value;
  });
}

/**
 * A react hook that returns a function to update the url query string
 * when the current state is different from the default state.
 */
export function useSetQueryParams<State extends object>(defaultState:State) {
  let history = useHistory();
  let location = useLocation();

  const setParam = useCallback(
    function setParam(key:string, values:any, query:URLSearchParams) {
      // remove the existing value from the query string
      query.delete(key);

      // ensure the values is an array to make it easier to process both types
      if (!Array.isArray(values)) values = [values];
      
      // look at each value and add it to the query string
      // if it is different from the default state value
      for (let index = 0; index < values.length; index++) {
        const value = safeParamValue(values[index]);
        const defaultValue = safeParamValue(defaultState[key as keyof State]);
        // skip if the value is the same as the default
        if (value === defaultValue) continue;
        query.append(key, value);
      }
    },
    [defaultState]
  )

  const setParams = useCallback(
    function setParams(params:object) {
      const query = new URLSearchParams(location.search);
      const oldQueryString = query.toString();

      // update the query params
      Object.entries(params).forEach(([key, values]) =>
        setParam(key, values, query)
      );
      // push the updated query string to history
      const newQueryString = query.toString();
      
      if (newQueryString === oldQueryString) return;
      history.replace({
        pathname: window.location.pathname,
        search: newQueryString,
      });
    },
    [history, location, setParam]
  );

  return [setParams];
}

interface UrlState {
  [key: string]: any;
}

/**
 * Updates the url params for the given keys if they different
 * from the value in initialState.
 *
 * @param {object} state - The current state
 * @param {array} urlKeys - The keys in the state that should be stored in the url
 * @param {object} defaultState - the state to compare against
 */
export function useStoreQueryString<State extends object>(state:State, urlKeys:string[], defaultState:State) {
  const [setParams] = useSetQueryParams<State>(defaultState);
  const watchValues = urlKeys.map((key:string) => state[key as keyof State]);
  useEffect(
    () => {
      const urlState: UrlState = {};
      urlKeys.forEach((key) => (urlState[key] = state[key as keyof State]));
      setParams(urlState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...watchValues]
  );
}
