import { useEffect } from "react";

import {useSelectContext} from "common/contexts/SelectContext";
import {ContextValue } from "../types";

export default function useSelectedNode(context:ContextValue){
  const {editor} = context;
  const {selectedNodeId, selectedNodeIdRenderCount} = editor;
  const {setSelection} = useSelectContext();

  useEffect(()=>{
    if (!selectedNodeId) return;
    setSelection(selectedNodeId);
  }, [selectedNodeId, selectedNodeIdRenderCount, setSelection])
}