import React, { useRef, useEffect} from "react";
import { useStateRef } from "common/hooks/state";
import { getDebugLog } from "common/utils";

const log = getDebugLog(false, "ClickOutsideContainer");

interface Props {
  children: React.ReactNode,
  onClose: () => void,
  [key: string]: unknown;
}

export function ClickOutsideContainer(props:Props) {
  const {children, onClose, ...other} = props;
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutsideRef(ref, onClose);

  return (
    <div
      ref={ref}
      {...other}
    >
      {children}
    </div>
  )
}

function useOnClickOutsideRef(ref:React.RefObject<HTMLElement>, onClick:()=>void){

  const [canClose, setCanClose, canCloseRef] = useStateRef(false);

  // use a timeout to prevent the close from happening too quickly
  useEffect(()=>{
      if (canClose) return;
      const timeout = setTimeout(()=>{
          log("can close");
          setCanClose(true);
      }, 500);

      return ()=> clearTimeout(timeout);
  }, [canClose, setCanClose])

  function isElementInRef(target:HTMLElement){
      if (!ref.current) return false;
      if (ref.current === target) return true;
      if (ref.current.contains(target)) return true;
      return false
  }

  function closeModalOutsideClick(event:MouseEvent | FocusEvent){
      const inRef = isElementInRef(event.target as HTMLElement);
      log("click outside", inRef, event.target, ref.current);
      if (inRef) {
          // temporarily prevent closing
          setCanClose(false);
          return;
      };
      if (!canCloseRef.current) return;
      log("click outside close");
      onClick();
  }
  
  useEffect(()=>{
      window.addEventListener("click", closeModalOutsideClick, false);
      document.addEventListener('focus', closeModalOutsideClick, true);
      return function cleanup(){
          window.removeEventListener("click", closeModalOutsideClick, false);
          document.removeEventListener('focus', closeModalOutsideClick, true);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
}