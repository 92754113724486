import useErrors, {Provider, errorStore} from "./context";
import ErrorStore from "./ErrorStore";

export default useErrors

export {
  errorStore,
  ErrorStore,
}

export {
  Provider as ErrorProvider
}