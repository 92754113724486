import React, { createContext, useContext, useEffect} from "react";
import { observer } from "mobx-react-lite";

import OrphanStore from "./OrphanStore";

const orphanStore = new OrphanStore();

export const Context = createContext<OrphanStore>({} as OrphanStore);

type ProviderProps = {
  children: React.ReactNode,
}

export const Provider = observer(function(props: ProviderProps){
  const {
    children
  } = props;

  useEffect(()=>{
    // get the first page of orphans on load
    orphanStore.getOrphanNodes();
  }, [])
  
  return (
    <Context.Provider value={orphanStore}>
      {children}
    </Context.Provider>
  );
})

/**
 * A convenience hook for accessing the context value
 */
 export default function useController(){
  return useContext(Context);
}