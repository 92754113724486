import { TagType } from "../types";
import { State } from "./types";

export default function defaultState(overrides={}): State {
  return {
    id:"",
    parentId:"",
    valueId:"",
    include:true,
    type:TagType.FILTER,
    showSuggestions:false,
    needsSelection:false,
    showRemove:true,
    ...overrides,
  };
}