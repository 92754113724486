import React from 'react';

export function SubGroupIcon() {
  return (
    <svg
      className='icon'
      width="33"
      height="24"
      viewBox="0 0 33 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2214 18.9572C9.82274 16.9948 9 14.5935 9 12C9 9.40652 9.82274 7.00521 11.2214 5.04281C7.72186 5.43006 5 8.39717 5 12C5 15.6028 7.72186 18.5699 11.2214 18.9572ZM19 12C19 14.1525 18.0285 16.078 16.5 17.3621C14.9715 16.078 14 14.1525 14 12C14 9.84752 14.9715 7.92196 16.5 6.63789C18.0285 7.92196 19 9.84752 19 12ZM21.7786 5.04281C23.1773 7.00521 24 9.40652 24 12C24 14.5935 23.1773 16.9948 21.7786 18.9572C25.2781 18.5699 28 15.6028 28 12C28 8.39717 25.2781 5.43007 21.7786 5.04281ZM16.5 23.1277C15.1104 23.6902 13.5914 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C13.5914 0 15.1104 0.309761 16.5 0.872276C17.8897 0.309761 19.4086 0 21 0C27.6274 0 33 5.37258 33 12C33 18.6274 27.6274 24 21 24C19.4086 24 17.8897 23.6902 16.5 23.1277Z"
        fill="currentColor"
    />
    </svg>
  );
}

