import { ContextValue } from "../types";
import usePersist from "controllers/usePersist";

export default function useNodes(context:ContextValue):void{
  const {parentId, valueId} = context;
  const {persist} = usePersist();

  context.parentNode = persist.getNodeMaybe(parentId);
  context.valueNode = persist.getNodeMaybe(valueId);
}
