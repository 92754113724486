import React, { createContext, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";

import {useContextController} from "common"

import { TagProps } from "../types";
import { ContextValue, State} from "./types";
import defaultState from "./constants";
import reducerFunctions from "./reducers"
import methodHooks from "./hooks";

type ProviderProps = TagProps & {
  children: React.ReactNode,
}
const Context = createContext<ContextValue>({} as ContextValue);

export const Provider = observer(function (props: ProviderProps){
  const {
    children,
    type,
    id,
    include=true,
    parentId,
    valueId,
    number,
    boolean,
    datetime,
    operator,
    onRemove,
    onPropValueUpdate,
    onAltClick,
    showRemove=true,
  } = props;

  const initialState = defaultState({
    type,
    id,
    parentId,
    valueId,
    include,
    number,
    boolean,
    datetime,
    operator,
    showRemove,
  })

  const passedValues = {
    // inherited methods or values
    onRemove,
    onPropValueUpdate,
    onAltClick,
  }
  const value: ContextValue = useContextController<State, ContextValue>(initialState, reducerFunctions, methodHooks, passedValues)

  const {dispatch} = value;

  useEffect(()=>{
    dispatch({
      type:"updateState",
      payload:{
        type,
        id,
        parentId,
        valueId,
        include,
        number,
        boolean,
        datetime,
        operator,
        showRemove,
      }
    })
    },
    [
      dispatch,
      type,
      id,
      parentId,
      valueId,
      include,
      number,
      boolean,
      datetime,
      operator,
      showRemove,
    ]
  )

  return (<Context.Provider value={value}>{children}</Context.Provider>);
})

/**
 * A convenience hook for accessing the context value
 */
export default function useView(){
  return useContext(Context);
}
