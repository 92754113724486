import React from 'react';

export function SubItemIcon() {
  return (
    <svg
      className='icon'
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="4"
        strokeWidth="3"
        stroke="currentColor"
      />
      <path
        d="M6 12L6 18L14 18"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="18"
        cy="18"
        r="4"
        strokeWidth="3"
        stroke="currentColor"
      />
    </svg>
  );
}
