import React, { createContext, useContext } from "react";
import { observer } from "mobx-react-lite";

import LoadingStore from "./LoadingStore";

export const loadingStore = new LoadingStore();

const Context = createContext<LoadingStore>({} as LoadingStore);


type ProviderProps = {
  children: React.ReactNode,
}

export const Provider = observer( function (props: ProviderProps){
  const {
    children,
  } = props;

  return (
    <Context.Provider value={loadingStore}>
      {children}
    </Context.Provider>
  );
})

/**
 * A convenience hook for accessing the context value
 */
export default function useLoading(){
  return useContext(Context);
}
