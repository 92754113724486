import { useCallback } from "react";

import { ContextValue } from "../types";
import { getDebugLog } from "common";

const log = getDebugLog(false, "useClose");

export default function useClose(context:ContextValue){
  const { dispatch, preventClose, onClose} = context;


  context.close = useCallback(
    ()=> {
      log("close", preventClose);
      if (preventClose) return;
      dispatch({
        type:"updateState",
        payload: {
          showSuggestions:false,
        }
      })
      if (onClose) onClose();
    },
    [preventClose, dispatch, onClose]
  )

}