import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";

import ButtonNavBar from "components/ButtonNavBar";
import Button from 'components/Button';
import MetaEditor from "components/MetaEditor";
import Sort from "components/Sort";

import useEditor, {VIEW} from "./useEditor";
import Branch from "./components/Branch"
import Filters, {FilterButton} from "./components/Filters";
import { SortParams, SortParamsButton } from "components/SortParams/SortParams";
import { getDebugLog } from "common";
import NodeActionBar from "components/NodeActionBar";
import { MaxWidthWrapper } from "components/layout";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = getDebugLog(false, "Editor App");

const EditorRouter = observer(function(){
  const {editor:{view}} = useEditor();
  if (view === VIEW.META) return <MetaEditor />
  if (view === VIEW.SORT) return <Sort />
  return <Editor/>
})

export default EditorRouter;

export const Editor = observer(function(){
  const {editor} = useEditor();

  return (
    <div className="editor">
      <NodeActionBar/>
      
      <MaxWidthWrapper>
        <ButtonNavBar>
          <SettingsButton/>
          <PinButton/>
          <CompareButton/>
          <LayoutButton/>
          <FilterButton/>
          <SortParamsButton/>
          <SaveViewButton/>
        </ButtonNavBar>

        <Filters/>
        <SortParams/>
      </MaxWidthWrapper>
      
      <Branch
        key={editor.rootNodeId}
        nodeId={editor.rootNodeId || ""}
        isRoot
        level={0}
        parentId=""
        bondId=""
        parentBondId=""
        grandBondId=""
        layout={editor.layout}
      />
    </div>
  )
})

const SettingsButton = observer(function(){
  const {editor} = useEditor()
  return (
    <Button
      isSelectable
      tooltip="Edit the behavior of this tag"
      onClick={editor.openMetaEditor}
    >
      Settings
    </Button>
  )
})

const LayoutButton = observer(function(){
  const {editor} = useEditor()
  return (
    <Button
      isSelectable
      tooltip="Toggle between a row and column layout"
      onClick={editor.toggleLayout}
    >
      {editor.layout === "columns" ? "Columns" : "Rows"}
    </Button>
  )
})

const CompareButton = observer(function(){
  const {editor} = useEditor()
  return (
    <Button
      isSelectable
      tooltip="Compare items to find the greatest one"
      onClick={editor.openSort}
    >
      Compare
    </Button>
  )
})


const SaveViewButton = observer(function(){
  const {
    editor
  } = useEditor()

  function handleClick(){
    editor.updateViewParams()
  }
  // Hide button if the current filters are default filters
  if (!editor.canSaveView) return null;

  return (
    <Button
      tooltip="Save the current filters and order as the default"
      onClick={handleClick}
    >
      Save View
    </Button>
  )
})


const PinButton = observer(function(){
  const {editor} = useEditor()

  const isPinned = editor.persist.getIsPinned(editor.rootNodeId || "");
  
  const togglePinned = useCallback(
    ()=>{
      if (isPinned) editor.persist.removePin(editor.rootNodeId || "")
      else editor.persist.addPin(editor.rootNodeId || "")
    },
    [editor, isPinned]
  )
  
  return (
    <Button
      isSelectable
      onClick={togglePinned}
      tooltip={isPinned ? "Remove this pin" : "Pin this page in the menu"}
    > 
      {isPinned ? "Unpin" : "Pin"}
    </Button>
  )
})