import { FilterParam } from "components/graphql";

export enum TagType {
  FILTER = "filter",
  BOND = "bond"
}

export interface StateProps extends FilterParam {
  id:string,
  parentId?:string,
  valueId?:string | null,
  include?:boolean,
  type:TagType,
  showRemove?:boolean,
}

export interface FunctionProps {
  onRemove(id:string):void,
  onPropValueUpdate(id:string, payload:FilterParam):void,
  onAltClick?: (params:FilterParam) => void,
}

export interface TagProps extends StateProps, FunctionProps {
 
}