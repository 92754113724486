import React from "react"

import {LineProps} from "./types"
import {LineContextProvider} from "./useLineContext"
import Line from "./Line"

export default function WrappedLine(props:LineProps){
  return (
    <LineContextProvider {...props}>
      <Line/>
    </LineContextProvider>
  )
}