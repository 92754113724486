import { action } from "mobx"

import { CreateSiblingBondParams, EditorStoreType } from "./storeTypes";
import { getDebugLog } from 'common';

import { NodePartial, BondPartial } from "controllers/usePersist";
import { BondKind } from "components/graphql";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = getDebugLog(false, "createSiblingBond");

function generateSibling(
  editor:EditorStoreType,
  params:CreateSiblingBondParams,
):{bonds:BondPartial[], nodes:NodePartial[], newBond:BondPartial}{
  const {
    bondId,
    parentBondId,
    text = "",
    before = false,
    positionIndex = 0,
  } = params;
  
  const {parentId, nodeId} = editor.persist.getBond(bondId)
  const parentBond = editor.persist.getBond(parentBondId)

  // create the new node
  const newNodeId = editor.persist.newId();
  const nodes:NodePartial[] = [{
    id: newNodeId,
    text,
  }]

  // generate the new bond's position
  let {position} = editor.getNewPosition(parentBondId, nodeId,  before ? 0 : 1)
  // add the positionIndex to the position to support creating
  // multiple siblings at once
  position = position + positionIndex;
  
  // create the new sibling bond
  const newBond = {
    id: editor.persist.newId(),
    createdAt: editor.persist.now(),
    parentId,
    nodeId: newNodeId,
    position,
  }
  
  const bonds:BondPartial[] = [newBond]
  
  /**
   * If the parent bond is a subgroup
   * also create a bond to the parent's parent.
  */
  let subGroupParentId = null;
  if (parentBond?.kind === BondKind.SUBGROUP) {
    subGroupParentId = parentBond.parentId;
    bonds.push({
      id: editor.persist.newId(),
      createdAt: editor.persist.now(),
      parentId:subGroupParentId,
      nodeId: newNodeId,
    })
  }

  editor.addBondsForFilters(bonds, newNodeId);
  return {bonds, nodes, newBond}
}

/**
 * Creates a new bond as a sibling of the given bond.
 */
export const createSiblingBond = action(
  function(
    this:EditorStoreType,
    params: CreateSiblingBondParams,
  ){
    
    const {
      bonds,
      nodes,
      newBond,
    } = generateSibling(this, params)
    
  
    this.persist.updateObjects({
      nodes,
      bonds,
    })

    // set the new bond as selected
    this.setSelectedBond({
      nodeId: newBond.nodeId!,
      parentId: newBond.parentId!,
    })
  }
)

/**
 * Create a list of new sibling bonds.
 */
export const createManySiblingBonds = action(
  function(
    this:EditorStoreType,
    params: CreateSiblingBondParams[],
  ){
    const bonds:BondPartial[] = []
    const nodes:NodePartial[] = []
    let newBond:BondPartial = null!;

    log("createManySiblingBonds", params)
    
    params.forEach((params) => {
      const {
        bonds: newBonds,
        nodes: newNodes,
      } = generateSibling(this, params)
      bonds.push(...newBonds)
      nodes.push(...newNodes)
      newBond = newBonds[0]
    })

    this.persist.updateObjects({
      nodes,
      bonds,
    })
    if (!newBond) return;
    // set the last new bond as selected
    this.setSelectedBond({
      nodeId: newBond.nodeId!,
      parentId: newBond.parentId!,
    })
  })