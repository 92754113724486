import {useCallback} from "react"

import { BondKind } from "components/graphql";
import usePersist from "controllers/usePersist";
import { ContextValue } from "../types";

export default function useCreateAutoSubgroup(context: ContextValue){
  const {stateRef} = context;

  const {persist} = usePersist(); 

  context.createAutoSubgroup = useCallback(
    (subgroupId:string) => {
      const {nodeId} = stateRef.current;
      const newBond = persist.newBond({
        parentId:nodeId,
        nodeId:subgroupId,
        kind: BondKind.AUTO_SUBGROUP
      });
      persist.updateObjects({bonds:[newBond]})
    },
    [persist, stateRef]
  )
}