import { ContextValue } from "../types";
import useView from "./useView";
import useSelect from "./useSelect";
import useLocalDatetime from "./useLocalDatetime";

const methodHooks: {(context: ContextValue): void}[] = [
  useView,
  useSelect,
  useLocalDatetime,
];

export default methodHooks