import { useCallback } from "react";


import { ContextValue, View} from "../types";

export default function useView(context:ContextValue):void{
  const { dispatch} = context;

  const setView = useCallback(
    (view:View)=>{
      dispatch({
        type: "setView",
        payload: {
          view,
        },
      })
    },
    [dispatch]
  )
  
  context.openEditor = useCallback(
    () => {
      setView(View.EDIT)
    },
    [setView]
  )

  context.openCalendar = useCallback(
    () => {
      setView(View.CALENDAR)
    },
    [setView]
  )

  context.close = useCallback(
    () => {
      setView(View.DISPLAY)
    },
    [setView]
  )
}
