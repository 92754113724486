export enum View {
  DISPLAY = "display",
  EDIT = "edit",
  CALENDAR = "calendar",
}

export interface State {
  datetime: Date | null,
  localDatetimeText:string,
  localDatetime: Date | null,
  view:View
}

export interface Methods {
  onDatetimeSelect:(datetime:string | null) => void,
  updateState(overrides:Partial<State>):void,
  select:(datetime:Date | null) => void,
  selectRelative:(daysOffest:number) => void,
  setLocalDatetime:(datetime:Date) => void,
  setLocalDatetimeText(datetimeText:string):void,
  openEditor:() => void,
  openCalendar:() => void,
  close:() => void,
}

export interface StateRef {
  current: State,
}

export interface SetDatetimePayload {
  datetime:Date | null,
}

export interface SetViewPayload {
  view:View,
}

export interface SetLocalDatetimeTextPayload {
  localDatetimeText:string
}

type ReducerAction = {
  type:"updateState",
  payload:Partial<State>,
} | {
  type:"setDatetime",
  payload:SetDatetimePayload
} | {
  type:"setView",
  payload:SetViewPayload
} | {
  type:"setLocalDatetimeText",
  payload:SetLocalDatetimeTextPayload
}

export type Dispatch = (a:ReducerAction) => void;

export type ContextValue = State & Methods & {
  state: State,
  stateRef: StateRef,
  dispatch: Dispatch,
  methods: Methods,
}

