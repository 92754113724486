import React from "react"
import { observer } from "mobx-react-lite"

import ButtonNavBar from "components/ButtonNavBar";
import Button from "components/Button";
import NodeLookupInput from "components/NodeLookupInput";

import useRoot, {VIEW} from "controllers/useRoot";
import {MenuIcon} from "common/icons"
import "./main-menu.scss"
import { Node } from "components/graphql";
import useViewport from "common/contexts/useViewport";
import useAuth from "components/AuthPage";
import { getDebugLog } from "common";
import { MaxWidthWrapper } from "components/layout";
import useOrphans from "controllers/useOrphans";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = getDebugLog(false, "LaunchPad");

const LaunchPad = observer(()=>{
  const {view} = useRoot();

  if (view !== VIEW.MAIN_MENU) return null;
  
  return (
    <div className="launch-pad">
      <MaxWidthWrapper>
        <ButtonNavBar>
          <NewNodeButton/>
          <RandomButton/>
          <OrphanButton/>
          <InfoButton/>
          <LogoutButton/>
        </ButtonNavBar>
        <Search/>
      </MaxWidthWrapper>
    </div>
  )
})
export default LaunchPad

const  OrphanButton = observer(()=>{
  const {openOrphan} = useRoot()
  const {total} = useOrphans()


  if (total === 0) return null;

  return (
    <Button
      tooltip="View unconnected nodes"
      onClick={openOrphan}
    >
      Inbox ({total})
    </Button>
  )
})

const NewNodeButton = observer(()=>{
  const {createNewPage} = useRoot()
  return (
    <Button 
      tooltip="Create a new node"
      onClick={createNewPage}
    >
      New
    </Button>
  )
})

const InfoButton = observer(()=>{
  const {openInfo} = useRoot()
  return (
    <Button
      tooltip="Learn more about this app"
      onClick={openInfo}
    >
      Info
    </Button>
  )
})

const RandomButton = observer(()=>{
  const {openRandomPage} = useRoot()
  return (
    <Button
      tooltip="Revisit a random node"
      onClick={openRandomPage}
    >
      Random
    </Button>
  )
})

const LogoutButton = observer(()=>{
  const {logout} = useAuth()

  return (
    <Button
      tooltip="Logout of this app"
      onClick={logout}
    >
      Logout
    </Button>
  )
})

const Search = observer(()=>{
  const { setRoot, searchTerm, setSearchTerm} = useRoot();
  const {isMobile} = useViewport();

  const onSelect = (node: Node, created: boolean) => {
    setRoot(node.id, {selectRoot:created})
  }

  function handleBackspaceEmpty(){
    log("onBackspaceEmpty")
  }
  const selectionId = isMobile ? "search" : "";
  log("selectionId", selectionId, isMobile)
  return (
    <NodeLookupInput
      selectionId={selectionId}
      preventClose
      showSuggestions
      onSelectNode={onSelect}
      onBackspaceEmpty={handleBackspaceEmpty}
      orderBy="last_root"
      pageSize={30}
      paginate
      showZeroState
      searchTerm={searchTerm}
      onTextChange={setSearchTerm}
    >
      <ZeroState/>
    </NodeLookupInput>
  )
})

function ZeroState(){
  return (
    <>
      <h1>Looks like you&apos;re new here!</h1>
      <p>
        To get started, click &quot;New&quot; or start typing in the search field
        and hit enter to store whatever you want to remember.
      </p>
      <p>
        Afterwards, this page will show your pins and your mostly recently visited content.
      </p>
    </>
  )
}


export const DesktopNav = observer(()=>{
  const {openLaunchPad, view} = useRoot()

  return (
    <div className="nav">
      {view !== VIEW.MAIN_MENU && (
        <button className="line-action-button" onClick={openLaunchPad}>
          <MenuIcon/>
        </button>
      )}
    </div>
  )
})
