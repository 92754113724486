import { useStoreQueryString } from "common/hooks/useQueryParamState";

import { ContextValue } from "../types";
import { EditorStore, URL_STATE_KEYS} from "../store";


export default function useUpdateQueryString(context: ContextValue){
  const {editor} = context;
  
  // set defaultFilterParams to filterParams so we only add 
  // filterParams to the url if it is different from defaultFilterParams
  const defaultState = new EditorStore(editor.persist, editor.rootNodeId);
  defaultState.setFilterParams(editor.defaultFilterParams, false);
  defaultState.setSortParams(editor.defaultSortParams, false);
  useStoreQueryString(editor, URL_STATE_KEYS, defaultState);
}
