import { gql } from '@apollo/client';
export interface ResObj {
  success: boolean;
  errorMessage: string;
  errorData: string;
}

export enum NodeKind {
  NODE = 'ND',
  PROP = "PR",
}
export enum BondKind {
  CONTENT = "ct",
  SUBGROUP = "sg",
  AUTO = "at",
  AUTO_SUBGROUP = "asg",
  PIN = "pn",
}
export enum PropType {
  BOOLEAN = "BL",
  NUMBER = "NM",
  DATETIME = "DT",
  NODE = "ND",
}

export enum BondNodeKey {
  NODE = "node",
  PARENT = "parent",
  VALUE = "value",
}

export interface Node {
  id:string,
  icon:string,
  text:string,
  isParent:boolean,
  kind:NodeKind,
  propType:PropType,
  createdAt:string,
  updatedAt:string,
  archivedAt?:string|null,
  lastTaggedAt?:string|null,
  lastRootAt?:string|null,
  matchText?:boolean,
  replaceId?:string|null,
  // orphan fields
  isOrphan?:boolean,
  requiresParent?:boolean,
  requiresAdditionalParent?:boolean,
}

export const FRAG_NODE = gql`
  fragment NodeBase on Node {
    id
    text
    isParent
    kind
    propType
    createdAt
    updatedAt
    archivedAt
    replaceId
    isOrphan
    requiresParent
    requiresAdditionalParent
  }
`;

export interface BondNodeIds {
  nodeId:string,
  parentId:string,
  valueId:string | null,
}


export interface Bond extends BondNodeIds {
  id:string,
  position:number,
  boolean:boolean,
  datetime:string|null,
  number?:number|null,
  kind?:BondKind,
  sortIndex?:number,
  isHidden:boolean,
  createdAt:string,
  updatedAt:string,
  archivedAt?:string|null,
}

export const FRAG_BOND_BASE = gql`
  fragment BondBase on Bond {
    id
    position
    nodeId
    parentId
    valueId
    boolean
    datetime
    number
    kind
    isHidden
    createdAt
    updatedAt
    archivedAt
  }
`;

export interface FilterParam {
  parentId?: string,
  valueId?: string | null,
  boolean?: boolean,
  number?: number | null,
  datetime?: string | null,
  include?: boolean,
  operator?: string,
}

export const FRAG_FILTER_BASE = gql`
  fragment FilterBase on FilterParam {
    parentId
    valueId
    boolean
    number
    datetime
    include
    operator
  }
`;

export interface FilterWithNodes extends FilterParam {
  [BondNodeKey.PARENT]: Node | undefined,
  [BondNodeKey.VALUE]: Node | undefined,
  __typename:string | undefined,
}

export const FRAG_FILTER_WITH_NODES = gql`
  fragment FilterWithNodes on FilterParam {
    ...FilterBase
    parent{
      ...NodeBase
    }
    value {
      ...NodeBase
    }
  }
  ${FRAG_NODE}
  ${FRAG_FILTER_BASE}
`;


export interface SortParam {
  key:string,
  desc?:boolean,
  parentId?:string,
}


export interface SortParamWithNodes extends SortParam {
  [BondNodeKey.PARENT]: Node | undefined,
  __typename:string | undefined,
}

export const FRAG_SORT_WITH_NODES = gql`
  fragment SortParamWithNodes on SortParam {
    key
    desc
    parentId
    parent {
      ...NodeBase
    }
  }
  ${FRAG_NODE}
`;

export interface BondParent extends Bond {
  [BondNodeKey.PARENT]: AnyNode
  [BondNodeKey.VALUE]: AnyNode | null
}


export const FRAG_BOND_PARENT = gql`
  fragment BondParent on Bond {
    ...BondBase
    parent {
      ...NodeBase
    }
    value {
      ...NodeBase
    }
  }
  ${FRAG_NODE}
  ${FRAG_BOND_BASE}
`;

export interface BondAutoSubgroup extends Bond {
  [BondNodeKey.NODE]: Node
  [BondNodeKey.VALUE]: Node | null
}

export const FRAG_AUTO_BOND_SUBGROUP = gql`
  fragment BondAutoSubgroup on Bond {
    ...BondParent
    node {
      ...NodeBase
    }
    value {
      ...NodeBase
    }
  }
  ${FRAG_BOND_PARENT}
  ${FRAG_NODE}
`;



export interface NodeWithParents extends Node {
  parentBonds: AnyBond[]
}


export const FRAG_NODE_WITH_PARENTS = gql`
  fragment NodeWithParents on Node {
    ...NodeBase
    parentBonds{
      ...BondParent
    }
  }
  ${FRAG_BOND_PARENT}
`;

export interface BondRoot extends Bond {
  node:NodeWithParents
}

export const FRAG_BOND_ROOT = gql`
  fragment BondRoot on Bond {
    ...BondBase
    node {
      ...NodeWithParents
    }
  }
  ${FRAG_BOND_BASE}
  ${FRAG_NODE_WITH_PARENTS}
`;

export interface BondChild extends BondParent {
  node:NodeWithParents
}

export const FRAG_BOND_CHILD = gql`
  fragment BondChild on Bond {
    ...BondParent
    node {
      ...NodeWithParents
    }
  }
  ${FRAG_BOND_BASE}
  ${FRAG_NODE_WITH_PARENTS}
`;

export interface AnyBond extends Bond {
  [BondNodeKey.VALUE]?: Node | NodeWithParents | null
  [BondNodeKey.PARENT]?: Node | NodeWithParents | null
  [BondNodeKey.NODE]?: Node | NodeWithParents | null
}

/**
 * This is used when we dont know if it's a parent
 * or child bond
 */
export const FRAG_BOND_ANY = gql`
  fragment BondAny on Bond {
    ...BondBase
    parent {
      ...NodeBase
    }
    value {
      ...NodeBase
    }
    node {
      ...NodeWithParents
    }
  }
  ${FRAG_NODE}
  ${FRAG_BOND_BASE}
  ${FRAG_NODE_WITH_PARENTS}
`;


export interface AnyNode extends Node {
  parentBonds?: AnyBond[]
}
