import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { truncate } from "common/utils";
import useEditor from 'components/editor/useEditor';
import { Node, SortParam } from 'components/graphql';
import { option as selectOption, SelectDropDown, SelectToggle } from 'common/components/Selectors'
import { ChevronIcon, CloseIcon } from 'common/icons';
import NodeLookupInput from "components/NodeLookupInput";
import Button from 'components/Button';


export const SortParamsButton = observer(function(){
  const {editor} = useEditor()
  const {addSortParam} = editor

  function handleClick(){
    addSortParam({
      key: "created_at",
    })
  }

  return (
    <Button
      isSelectable
      tooltip='Apply an order to this list.'
      onClick={handleClick}
    >
      + Order
    </Button>
  )
})

export const SortParams = observer(function(){
  const {editor} = useEditor()
  const {sortParams} = editor

  // only render if there are sort params
  if (sortParams.length === 0) return null

  return (
    <div className='label-container'>
      <span className='label-title'>Order by:</span>

      {sortParams.map((param, idx) => (
        <SortParamPill key={idx} param={param} idx={idx}/>
      ))}
    </div>
  )
})

const DESC_OPTIONS = [
  selectOption("▲",true),
  selectOption("▼", false),
];

const SORT_OPTIONS = [
  selectOption("Created", "created_at"),
  selectOption("Updated", "updated_at"),
  selectOption("Text", "alpha"),
  selectOption("Random", "random"),
  selectOption("Prop", "parent"),
];

interface SortParamProps{
  param: SortParam,
  idx: number
}

const SortParamPill = observer(function(props: SortParamProps){
  const {param, idx} = props

  const {editor} = useEditor()
  const {removeSortParam, updateSortParam, rootNodeId} = editor

  const [showSearch, setShowSearch] = useState(false)

  function remove(){
    removeSortParam(idx)
  }

  const updateDesc = useCallback(
    (desc:boolean)=> updateSortParam({idx, payload:{desc}}),
    [updateSortParam, idx]
  )
  
  const openSearch = useCallback(
    ()=> setShowSearch(true),
    [setShowSearch]
  )

  const updateKey = useCallback(
    (key:string)=> {
      if (key === "parent") {
        // if we're selecting a parent, show the search bar
        openSearch()
      } else {
        updateSortParam({idx, payload:{key, desc:false}})
      }
    },
    [openSearch, updateSortParam, idx]
  )


  const selectSortProp = useCallback(
    (node:Node)=> {
      updateSortParam({
        idx, 
        payload:{key:"parent", desc:false, parentId:node.id}
      })
      setShowSearch(false)
    },
    [updateSortParam, setShowSearch, idx]
  )

  return (
    <span className='tag'>
      {/* toggle desc */}
      <button onClick={
        () => updateDesc(!param?.desc)}
      >
        <ChevronIcon fill direction={param?.desc ? "up" : "down"}/>
      </button>

      {showSearch ? (
        <NodeLookupInput
          onSelectNode={selectSortProp}
          orderBy="last_filter"
          showSuggestions
          filterNodeId={rootNodeId || ""}
          placeholder=""
        />
      ) : param.parentId ? (
        <button
          data-selection-nav  
          onClick={openSearch}
        >
          {truncate((editor.persist.getNodeMaybe(param.parentId))?.text || "")}
        </button>
      ) : (
        <SelectDropDown
          value={param.key}
          options={SORT_OPTIONS}
          setValue={updateKey}
        />
      )}
      <button data-selection-nav onClick={remove}>
        <CloseIcon/>
      </button>
    </span>
  )
})
