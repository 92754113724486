
export class LocalStorageKey {
  constructor(prefix){
    this.prefix = prefix;
  }
  
  get(key, defaultValue) {
    return getLocalWithExpiry(getLocalKey(this.prefix, key)) || defaultValue;
  }
  set(key, value, ttl=null) {
    setLocalWithExpiry(getLocalKey(this.prefix, key), value, ttl);
  }
  remove(key) {
    localStorage.removeItem(getLocalKey(this.prefix, key));
  }
}

/**
 * Extends localStorage.setItem to also set
 * the value should expire
 *
 */
export function setLocalWithExpiry(key, value, ttl=null) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: ttl ? now.getTime() + ttl : null,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

/**
 * Extends localStorage.getItem to check if the value
 * is still valid.
 */
export function getLocalWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (item.expiry && now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function getLocalStorageState(prefix, defaults) {
  const state = {};
  Object.entries(defaults).forEach(([key, defaultValue]) => {
    const localStorageKey = getLocalKey(prefix, key);
    try {
      // Get from local storage by key
      const value = getLocalWithExpiry(localStorageKey);
      // Parse stored json or if none set defaultValue
      state[key] = value ? JSON.parse(value) : defaultValue;
    } catch (error) {
      // If error also set defaultValue
      console.log(error);
      state[key] = defaultValue;
    }
  });
  return state;
}

export function setLocalStorageState(prefix, state, ttl=null) {
  Object.entries(state).forEach(([key, value]) => {
    const localStorageKey = getLocalKey(prefix, key);
    console.log("store", localStorageKey, value);
    setLocalWithExpiry(localStorageKey, JSON.stringify(value), ttl);
  });
}

export function getLocalKey(prefix, key) {
  return `${prefix}-${key}`;
}