import { State } from "../types";
import { MAX_MOBILE } from "constants/styles";
/**
 * Sets the state when the viewport changes
 */
 export function setViewport(state:State, payload:Partial<State>): State {
  const {
    width = 0,
    height = 0,
    offsetTop = 0,
    offsetLeft = 0,
  } = payload

  // console.log("setViewport", payload)

  const isMobile = width <= MAX_MOBILE

  let {keyboardOpen} = state
  // if the height is a lot less than the last height, assume the keyboard is open
  if (height < state.height - 200) {
    keyboardOpen = true
  } else if (height > state.height + 200) {
    keyboardOpen = false
  }

  return {
    ...state,
    isMobile,
    keyboardOpen,
    width,
    height,
    offsetTop,
    offsetLeft,
    bottom: offsetTop + height,
  }
}