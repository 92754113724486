import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";

import { getDebugLog } from "common";
import NodeLookupInput from "components/NodeLookupInput";
import Tag from "components/Tag"
import { TagType } from "components/Tag/types";
import useEditor from "components/editor/useEditor";
import Button from "components/Button";
import { FilterParam, Node } from "components/graphql";

const log = getDebugLog(false, "Filters")

const Filters = observer(function(){
  const {
    editor
  } = useEditor();
  
  const {
    rootNodeId,
    filterParams,
    addFilter,
    updateFilter,
    removeFilter,
    setOpenFilter,
    openFilter
  } = editor;

  const closeFilter = useCallback(
    ()=>{
      log("closeFilter")
      setOpenFilter(false)
    },
    [setOpenFilter]
  )

  const addNodeFilter = useCallback(
    (node:Node)=>{
      closeFilter()
      addFilter({parentId:node.id})
    },
    [addFilter, closeFilter]
  )

  const handleParamUpdate = useCallback(
    (idx:number|string, payload:Partial<FilterParam>)=>{ 
      updateFilter({idx, payload})
    },
    [updateFilter]
  )
  
  // only show this section if there are filters
  // or the user is adding a new filter
  if (!filterParams.length && !openFilter) return null;

  return (
    <div className="label-container">  
      <span className="label-title">Filters:</span>
      
      {openFilter && (
        <NodeLookupInput
          showSuggestions
          onSelectNode={addNodeFilter}
          orderBy="last_filter"
          filterNodeId={rootNodeId}
          onClose={closeFilter}
          onBackspaceEmpty={closeFilter}
        />
      )} 

      {filterParams.map((param, idx) => (
        <Tag
          key={idx}
          id={idx.toString()}
          type={TagType.FILTER}
          onRemove={removeFilter}
          onPropValueUpdate={handleParamUpdate}
          {...param}
        />
      ))}
    </div>
  )
})
export default Filters;

export const FilterButton = observer(function(){
  const {
    editor:{
      setOpenFilter
    }
  } = useEditor();

  const showSuggestions = useCallback(  
    ()=>setOpenFilter(true),
    [setOpenFilter]
  )

  return (
    <Button
      isSelectable
      tooltip="Filter this list"
      onClick={showSuggestions}
    >
      + Filter
    </Button>
  )
})
