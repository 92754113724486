import { useMemo } from "react";

import usePersist from "controllers/usePersist";

import { ContextValue } from "../types";
import {EditorStore} from "../store"


export default function useHelper(context:ContextValue) {
  const {rootNodeId} = context;
  const {persist} = usePersist();

  context.editor = useMemo(
    () => {
      return new EditorStore(persist, rootNodeId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootNodeId]
  );

  // @ts-ignore ts(2339)
  // window.editor = context.editor;
}