import { State } from "./types";

export default function defaultState(overrides:object={}): State {
  return {
    touched:false,
    loading:true,
    orderBy:"last_root",
    pageSize:10,
    pageNumber:1,
    activeSuggestion:-1,
    nodeText:"",
    showSuggestions:true,
    preventClose:false,
    nodeIds:[],
    selectionId:"",
    paginate:false,
    hasMore:true,
    showZeroState:false,
    ...overrides,
  };
}