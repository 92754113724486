import React, { createContext, useContext } from "react";
import { observer } from "mobx-react-lite";

import {useContextController} from "common"

import { ContextValue, State} from "./types";
import defaultState from "./constants";
import reducerFunctions from "./reducers"
import methodHooks from "./hooks";

type ProviderProps = {
  children: React.ReactNode,
  nodeId?:string
}
const Context = createContext<ContextValue>({} as ContextValue);

export const Provider = observer(function (props: ProviderProps){
  const {
    children,
    nodeId,
  } = props;

  const initialState = defaultState({
    nodeId,
  })

  // inherited methods or values
  const passedValues = {
  }
  const value: ContextValue = useContextController<State, ContextValue>(initialState, reducerFunctions, methodHooks, passedValues)

  return (<Context.Provider value={value}>{children}</Context.Provider>);
})

/**
 * A convenience hook for accessing the context value
 */
export default function useMetaEditor(){
  return useContext(Context);
}
