import React, { useMemo }from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';


interface StyleProps {
  $fullWidth?:boolean,
}

const StyledButton = styled.button<StyleProps>`
  width: ${(props:StyleProps) => props.$fullWidth ? "100%" : "auto"};
`

interface Props {
  children: React.ReactNode;
  onClick: (event:React.MouseEvent<HTMLButtonElement>) => void;
  // the text to show when hovering over the button
  tooltip: string;
  // When true, the arrow keys allow the user to navigate to other selectable elements
  isSelectable?: boolean;
  // When true, the arrow keys allow the user to navigate to this element and from this
  autoSelectionId?: boolean;
  // allow any other props
  [key:string]: unknown;
}

export default function Button(props:Props) {
  const {
    children,
    onClick,
    isSelectable,
    autoSelectionId,
    tooltip,
    ...buttonProps
  } = props;

  const selectionId = useMemo(() => uuidv4(), []);

  function handleClick(event:React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.stopPropagation();
    onClick(event);
  }

  return (
    <StyledButton
      onClick={handleClick}
      data-selection-nav={isSelectable || autoSelectionId}
      data-selection-id={autoSelectionId ? selectionId : undefined}
      title={tooltip}
      {...buttonProps}
    >
      {children}
    </StyledButton>
  )
}