import { useCallback } from "react";

import { ContextValue } from "../types";

export default function useRemove(context:ContextValue):void{
  const {dispatch, stateRef, onRemove} = context;

  context.remove = useCallback(
    () => {
      const {id} = stateRef.current;
      onRemove(id)
    },
    [stateRef, onRemove]
  )

  context.setShowSuggestions = useCallback(
    (showSuggestions:boolean) => {
      dispatch({
        type: "updateState",
        payload:{
          showSuggestions,
        }
      })
    },
    [dispatch]
  )
}
