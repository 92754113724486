
import React, {useCallback, useEffect, useRef, useState, useMemo} from "react";
import clsx from "clsx";
import { v4 as uuidv4 } from 'uuid';

import { setCursorPosition } from "common/hooks/dom";
import { getDebugLog } from "common/utils";
import { useKeyDown } from "common/hooks/dom";
import { convertSpecialText } from "common/text";
import "./line-input.scss"
import { CloseIcon } from "common/icons";

const log = getDebugLog(false, "LineInput:");

interface LineInputProps {
  actionBarLabel?: string,
  text?:string | number | null,
  className?:string,
  inputRef?:React.RefObject<HTMLTextAreaElement>,
  isSelectable?:boolean,
  autoSelectionId?:boolean,
  selectionId?:string,
  inputMode?:"text" | "decimal" | "numeric",
  hasFocus?:boolean,
  autoFocus?:boolean,
  placeholder?:string,
  minWidth?:number,
  onBlur?:(text:string)=>void,
  onPasteMany?:(currentText:string, pastedLines:string[])=>void,
  onFocus?:(text:string)=>void,
  onTextChange?:(text:string)=>void,
  onDrop?:(files:object)=>void,
  onMouseEnter?:()=>void,
  onClear?:(event:React.MouseEvent<HTMLButtonElement>)=>void,
  [keyDownFn:string]:unknown,
}

export default function LineInput(props:LineInputProps){
  const {
    actionBarLabel = "",
    inputMode = "text",
    text,
    isSelectable,
    autoSelectionId,
    selectionId,
    className,
    hasFocus,
    placeholder = "",
    minWidth = 10,
    onBlur,
    onPasteMany,
    onFocus,
    onTextChange,
    onDrop,
    onClear,
    autoFocus=false,
    onMouseEnter = ()=>{},
    ...keyDownProps
  } = props;
  
  const localSelectionId = useMemo(() => {
    if (autoSelectionId) return uuidv4()
    return selectionId
  }, [selectionId, autoSelectionId]);

  // either use the inputRef passed in, or create a local one
  const localInputRef = useRef<HTMLTextAreaElement>(null!);
  const inputRef = props.inputRef || localInputRef
  
  const sizerRef = useRef<HTMLDivElement>(null!);
  const resize = useResizeTextArea(inputRef, sizerRef, minWidth);

  const getCurrentText = useCallback(
    ()=>{
      if (!inputRef.current) return "";
      // return valueToText(inputRef.current.value);
      return inputRef.current.value;
    },
    [inputRef]
  )

  // update localText when text changes
  const [localText, setLocalText] = useState(textToValue(text));
  useEffect(()=>{
    log("useEffect textChange", "text", text)
    const localText = textToValue(text)
    setLocalText(
      localText
    )
  }, [text, setLocalText])

  // update input value when localText changes
  useEffect(()=>{
    log("useEffect localTextChange", "localText", localText)
    inputRef.current!.value = textToValue(localText)
    resize()
  }, [localText, inputRef, resize])

  log("render", "localText", localText, "text", text)

  const handleKeyUp = useCallback(
    () => {
      const newText = getCurrentText()
      log("handleKeyUp", "start", newText, localText)

      let [ updatedText, hadSpecialText ] = convertSpecialText(newText, inputMode)
      
      log("onTextChange", "hadSpecialText", hadSpecialText, "updatedText:", updatedText, "localText:", localText)
      if (onTextChange) onTextChange(updatedText);

      // only update localText if the text had special text
      if (!hadSpecialText) return
      
      log("setLocalText", updatedText)
      setLocalText(updatedText);
    },
    [
      onTextChange,
      getCurrentText,
      localText,
      setLocalText,
      inputMode,
    ]
  )

  const handleBlur = () => {
    if (!onBlur) return;
    const newText = getCurrentText()
    log("onBlur", newText)
    onBlur(newText);
  }

  const handleFocus = (event:React.FocusEvent<HTMLTextAreaElement>) => {
    log("handleFocus", event)
    // set the cursor to the end of the text
    setCursorPosition(event.target, -1)
    // resize the textarea
    resize()
    if (!onFocus) return;
    const newText = getCurrentText()
    log("onFocus", newText)
    onFocus(newText);
  }

  function handleDrop(e:any){
    if (!onDrop) return;
    const files = e.dataTransfer.files;
    if (!files) return;
    if (!files[0]) return;
    onDrop(files);
  }
  
  function handlePaste(e:any){
    if (!onPasteMany) return;
    const pastedText = e.clipboardData.getData('Text');
    const pastedLines = pastedText.split("\n");
    // only hijack normal paste if there are multiple lines
    if (pastedLines.length < 2) return;
    e.stopPropagation();
    e.preventDefault();
    const text = getCurrentText()
    onPasteMany(text, pastedLines)
    log("onPasteMany", pastedLines)
  }

  // assign keydown handlers
  useKeyDown(inputRef, keyDownProps, "LineInput");

  const classes = clsx(
    "line-input",
    "selectable",
    className,
  )

  const sizerClasses = clsx(
    "line-input",
    "line-input-sizer",
    className,
  )
  log("isSelectable", {
    isSelectable,
    autoSelectionId,
    localSelectionId,
    autoFocus,
  })

  const showClear = onClear && inputRef?.current?.value;

  const localOnClear = useCallback((event:React.MouseEvent<HTMLButtonElement>)=>{
    setLocalText("");
    onClear?.(event);
    if (!inputRef?.current) return;
    inputRef.current.value = "";
    inputRef.current.focus();
  }, [onClear, inputRef])

  const containerClasses = clsx(
    "line-input-container",
    showClear && "has-button"
  )
  return (
    <div className={containerClasses}
      onMouseEnter={onMouseEnter}
    >
      <textarea
        ref={inputRef}
        className={classes}
        defaultValue={localText}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={handleKeyUp}
        data-selection-nav={isSelectable || autoSelectionId}
        data-selection-id={localSelectionId}
        data-action-bar-label={actionBarLabel}
        inputMode={inputMode}
        onBlur={handleBlur}
        onDrop={handleDrop}
        onFocus={handleFocus}
        onPaste={handlePaste}
        onInput={resize}
      />
      <div className={sizerClasses} ref={sizerRef}></div>
      {showClear && (
        <div
          className="line-input-button"
        >
          <button
            onClick={localOnClear}
          >
            <CloseIcon/>
          </button>
        </div>
      )}
    </div>
  )
}

function useResizeTextArea(ref:React.RefObject<HTMLTextAreaElement>, sizerRef:React.RefObject<HTMLDivElement>, minWidth=50){
  const resize = useCallback(
    ()=> {
      const textarea = ref.current as HTMLTextAreaElement;
      const sizerDiv = sizerRef.current as HTMLDivElement;
      if (!textarea || !sizerDiv) return;
      // get the height and width of the sizer div and set the textarea to that
      // Add placeholder text if there is none to make sure the sizer div has a height
      // The placeholder should only be 1 character or else the width will be bigger than the min-width
      sizerDiv.innerText = textarea.value || textarea.placeholder || ".";
      // NOTE: we add 1px to the height and width to account decimal rounding errors
      textarea.style.height = `${sizerDiv.offsetHeight}px`;
      let width = sizerDiv.offsetWidth + 1
      // use the min width if the width is less than the min width
      const hasFocus = document.activeElement === textarea;
      if (hasFocus && width < minWidth) width = minWidth;
      textarea.style.width = `${width}px`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    resize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return resize;
}


function textToValue(text:any):string{
  if (typeof text === "string") return text;
  if (typeof text === "number") return `${text}`;
  return "";
}

export function valueToText(value:string):any{;
  // check if the string is a number
  const number = Number(value);
  if (!isNaN(number)) return number;
  return value;
}