import { useEffectDeep } from "common/hooks/useEffectHelpers";


import {ContextValue } from "../types";
import useConnection from "controllers/useConnection";


export default function useRootNode(context:ContextValue){
  const {editor} = context;
  const {isOffline} = useConnection();

  useEffectDeep(
    ()=>{
      if (isOffline) return;
      editor.getRoot()
    },
    [
      editor,
      editor.rootNodeId,
      editor.sortParams,
      editor.sortParamsTouched,
      editor.filterParams,
      editor.filtersTouched,
      isOffline,
    ]
  )
}