import { gql } from '@apollo/client';
import { action } from 'mobx';

import { getDebugLog } from 'common';
import { apollo } from "common/apollo";
import { ResObj } from "common/hooks/useHandlerErrors";
import {
  Node,
  NodeWithParents,
  BondRoot,
  FRAG_NODE_WITH_PARENTS,
  FRAG_BOND_ROOT,
  FRAG_FILTER_WITH_NODES,
  FRAG_SORT_WITH_NODES,
  FRAG_BOND_BASE,
  Bond,
  FilterWithNodes,
  SortParamWithNodes,
} from "components/graphql"
import {EditorStoreType} from "./storeTypes";
import { loadingStore } from 'controllers/useLoading';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = getDebugLog(false, "getRoot");

interface UpdateRootPayload {
  rootNodeId: string,
  metaBonds: Bond[],
  filterParams?: FilterWithNodes[],
  defaultFilterParams?: FilterWithNodes[],
  sortParams?: SortParamWithNodes[],
  defaultSortParams?: SortParamWithNodes[],
}


interface Root extends ResObj, UpdateRootPayload {
  node:NodeWithParents,
  childBonds: BondRoot[],
}

interface Data {
  root:Root
}

export const getRoot = action(function(
  this:EditorStoreType,
){
  if (!this.rootNodeId) return;
  // only include filter params if they are given or they've been changed
  const ignoreFilters = this.filterParams.length  === 0 && !this.filtersTouched
  const ignoreSorts = this.sortParams.length === 0 && !this.sortParamsTouched
  const variables = {
    nodeId: this.rootNodeId,
    filterParams: ignoreFilters ? undefined : this.filterParams,
    sortParams: ignoreSorts ? undefined : this.sortParams,
  }
  log('variables', variables)
  
  loadingStore.startLoading();
  apollo.query({
    query:GQL,
    variables,
    fetchPolicy: "no-cache",
  }).then((response:{data:Data})=>{
    loadingStore.stopLoading();
    const res = response.data.root;
    
    if (this.errorStore.hasResponseError(res)) return;

    const {
      node,
      childBonds,
      filterParams = [],
      defaultFilterParams = [],
      sortParams = [],
      defaultSortParams = [],
      metaBonds = [],
    } = res;
    log("response", response)
    // add filter params
    const filterParamNodes: Node[] = []
    filterParams.forEach((filter)=>{
      if (filter.value) filterParamNodes.push(filter.value)
      if (filter.parent) filterParamNodes.push(filter.parent)
    })

    // add sort params
    const sortParamsNodes: Node[] = []
    sortParams.forEach((sort)=>{
      if (sort.parent) sortParamsNodes.push(sort.parent)
    })

    this.persist.storeObjects({
      nodes: [
        node,
        ...filterParamNodes,
        ...sortParamsNodes,
      ],
      bonds: [
        ...childBonds,
        ...metaBonds,
      ],
    })

    this.rootNodeId = node.id;
    // only set the filters if they haven't been touched
    if (!this.filtersTouched) {
      log("setFilterParams", filterParams, this.filtersTouched)
      this.setFilterParams(filterParams, this.filtersTouched);
    }
    this.setDefaultFilterParams(defaultFilterParams);
    
    // only set the sorts if they haven't been touched
    if (!this.sortParamsTouched) {
      log("setSortParams", sortParams, this.sortParamsTouched)
      this.setSortParams(sortParams, this.sortParamsTouched);
    }

    this.setDefaultSortParams(defaultSortParams);

    // Always show the children of the new root
    this.showChildren[this.rootNodeId || ""] = true;

    this.checkSelectedIsEmpty();
  })    
})

const GQL = gql`
  query Root(
    $nodeId:ID,
    $filterParams:[FilterParamInput],
    $sortParams:[SortParamInput],
  ) {
    root(
      nodeId: $nodeId,
      filterParams: $filterParams,
      sortParams: $sortParams,
    ) {
      success
      errorMessage
      errorData
      node {
        ...NodeWithParents
      }
      sortParams {
        ...SortParamWithNodes
      }
      defaultSortParams {
        ...SortParamWithNodes
      }
      filterParams {
        ...FilterWithNodes
      }
      defaultFilterParams {
        ...FilterWithNodes
      }
      childBonds {
        sortIndex
        ...BondRoot
      }
      metaBonds {
        ...BondBase
      }
    }
  }
  ${FRAG_NODE_WITH_PARENTS}
  ${FRAG_BOND_ROOT}
  ${FRAG_FILTER_WITH_NODES}
  ${FRAG_SORT_WITH_NODES}
  ${FRAG_BOND_BASE}
`;