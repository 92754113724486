export const NODE_KINDS = {
  NODE: 'ND',
  PROP: "PR",
}

export const PROP_TYPES = {
  BOOLEAN:"BL",
  NUMBER:"NM",
  DATETIME:"DT",
  NODE:"ND",
}