import { useCallback } from "react";

import useRoot from "controllers/useRoot";

import { ContextValue } from "../types";

export default function useSelect(context:ContextValue):void{
  const {stateRef, onAltClick} = context;

  const {setRoot} = useRoot();
  
  context.select = useCallback(
    (altKey:boolean) => {
      const {
        parentId,
        valueId,
        boolean,
        number,
        datetime,
      } = stateRef.current;
      
      if (altKey && onAltClick) {
        onAltClick({
          parentId,
          valueId,
          boolean,
          number,
          datetime,
        })
      } else {
        setRoot(parentId!)
      }

    },
    [setRoot, stateRef, onAltClick]
  )
}
