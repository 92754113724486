import { useCallback } from "react";
import { ContextValue } from "../types";


export default function useUpdateState(context:ContextValue){
  const {dispatch} = context

  context.updateState = useCallback(
    (payload:object) => {
      dispatch({
        type:"updateState",
        payload,
      })
    },
    [dispatch]
  )
}
