import moment from "moment"

export function convertSpecialText(text:string, inputMode:string=""):[string, boolean]{
  let hasChanged = false;
  let trimmedText = (text || "").trim().toLowerCase();
  const initialText = trimmedText;


  // convert the value into a number
  if (inputMode === "number"){
    trimmedText = trimmedText.replace(/[^0-9]/g, '');
    if (trimmedText !== initialText) hasChanged = true;
  }

  // convert @today into today's date
  if ( trimmedText === "@today"){
    const currentDate = new Date();
    text = moment(currentDate).format('YYYY/MM/DD');
    hasChanged = true
  }
  return [text, hasChanged]
}