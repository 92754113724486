import { gql } from '@apollo/client';
import { action } from 'mobx';

import { apollo } from "common/apollo";
import { BondKind, FRAG_BOND_ROOT, ResObj, BondRoot } from 'components/graphql';
import {EditorStoreType, ToggleChildrenParams} from "./storeTypes";

interface Res extends ResObj {
  childBonds:BondRoot[]
}
interface Response {
  data:{
    nodeChildBonds: Res
  }
}

export const toggleChildren = action(function(
  this:EditorStoreType,
  params:ToggleChildrenParams,
){
  const {
    bondId,
    force=false,
  } = params;
  
  // Do not toggle the root
  if (!bondId) return;

  // if its already open, close it
  const isOpen = this.getShowChildren(bondId)
  if (isOpen && !force){
    this.showChildren[bondId] = false;
    return
  }

  // if its a subgroup, do not fetch children
  // from the backend because the children should
  // already be added from the parent.
  const bond = this.persist.getBond(bondId);
  if (bond.kind === BondKind.SUBGROUP){
    this.showChildren[bondId] = true;
    return
  }

  // display any local children for this node
  this.showChildren[bondId] = true;
  
  // fetch the children for this node from the backend
  const {nodeId} = bond;
  // display the children for this node
  this.showChildren[bondId] = true;
  // fetch the children for this node
  apollo.query({
    fetchPolicy: "no-cache",
    query:GQL,
    variables:{
      nodeId,
    },
  }).then((response:Response)=>{
    const res = response.data.nodeChildBonds;
    if (this.errorStore.hasResponseError(res)) return;
    const {childBonds} = res;
    // add the children to persist
    this.persist.storeObjects({
      bonds:childBonds,
    })
  })
})

const GQL = gql`
  query NodeChildBonds(
    $nodeId:ID!,
    $filterParams:[FilterParamInput],
  ) {
    nodeChildBonds(
      nodeId: $nodeId,
      filterParams:$filterParams,
    ) {
      success
      errorMessage
      errorData
      childBonds {
        ...BondRoot
      }
    }
  }
  ${FRAG_BOND_ROOT}
`;