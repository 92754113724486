import React, { useRef, useState, useCallback, useEffect, useMemo} from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { getDebugLog } from "common/utils";
import { TargetIcon } from "common/icons";
import { getPrevNodeId, useSelectContext } from "common/contexts/SelectContext";
import {formatSelectedNodeId} from "common/contexts/SelectContext"
import { LineTags } from "components/Line/LineTags";
import LineInput from "components/LineInput"
import NodeLookupInput from "components/NodeLookupInput";
import ToggleChildrenButton from "components/Line/ToggleChildrenButton";
import useEditor from "components/editor/useEditor";
import { Node } from "components/graphql";

import { CreateSiblingBondParams } from "components/editor/useEditor/store/storeTypes";
import useLineContext from "./useLineContext"
import "./line.scss"
import useRoot from "controllers/useRoot";
import { MAX_MOBILE } from "constants/styles";


const log = getDebugLog(false, "Line");


const Line = observer(function(){
  const {level, parentId, nodeId} = useLineContext()

  return (
    <div
      className={lineClass(level)}
    >
      <Nesting/>
      <div className="editor-line-content">
        <TextInput/>
        <NodeSelect/>
        <div className="label-container">
          <ToggleChildrenButton/>      
          <SetAsRootButton/>
          <LineTags/>
        </div>
        {/* <div>{parentId}:{nodeId}</div> */}
      </div>
    </div>
  )
})
export default Line

/**
 * A function that returns the classes
 * based on the given nesting level
 */
function lineClass(level=0){
  let lineClass = 'editor-line';

  if (level === 0){
    lineClass += " root";
  }
  else if (level === 1){
    lineClass += " card-header"
  } else {
    lineClass += " card-body"
  }
  return lineClass
}

/**
 * Nesting React Component
 * 
 * Visually indents lines based on the nesting level.
 */
const Nesting = observer(function(){
  const {level = 0} = useLineContext()
  const loop = [...Array(level)]
  return (
    <>
      {loop.map((e, i)=> (
        <div key={i} className="nesting-line"></div>
      ))}
    </>
  )
})

/**
 * The actual text input for the line.
 */
const TextInput = observer(function(){
  const {
    isRoot,
    nodeId,
    bondId,
    parentBondId,
    grandBondId,
    bond,
    parentId,
    node,
    setNodeAsRoot,
  } = useLineContext()
  
  const {editor} = useEditor()

  const {
    rootNodeId,
    updateText,
    replaceParent,
    adjustPosition,
    deleteBond,
    createChildBond,
    createSiblingBond,
    createManySiblingBonds,
    setSelectedBondInfo,
    selectedBond,
    showSelectedLookup,
    setShowSelectedLookup: setShowSelect,
    convertSelectedToSubgroup,
  } = editor

  const showSelect = showSelectedLookup && selectedBond?.bondId === bondId;

  const {selectRoot} = useRoot();

  const nodeText = node?.text || "";

  const inputRef = useRef<HTMLTextAreaElement>(null!);

  const {setSelection} = useSelectContext();

  const currentSelectionId = useMemo(()=>{
    return formatSelectedNodeId(parentId, nodeId);
  },  [parentId, nodeId])

  const [localText, setLocalText] = useState(nodeText);

  const handleFocus = useCallback(
    ()=>{
      log("handleFocus", "nodeId", nodeId)
      setSelectedBondInfo({
        isRoot,
        bondId,
        parentBondId,
        grandBondId,
        nodeId,
        parentId,
      })
    },
    [
      isRoot,
      bondId,
      grandBondId,
      nodeId,
      parentId,
      parentBondId,
      setSelectedBondInfo,
    ]
  )

  const setLocalIfChanged = useCallback(
    (text:string)=>{
      if (text === localText) {
        log("setLocalIfChanged SKIP", text)
        return
      }
      log("setLocalIfChanged", text)
      setLocalText(text);
    },
    [localText, setLocalText]
  )

  useEffect(
    ()=>{
      log("nodeText changed", nodeText)
      setLocalIfChanged(nodeText);
    },
    [
      nodeText,
      setLocalIfChanged,
    ]
  )

  // ACTIONS

  function setNodeText(text:string){
    log("setNodeText", text)
    updateText({nodeId, text});
  }

  
  function indentLine(){
    const prevSiblingBond = editor.getSiblingBond(bondId, parentBondId, -1);
    if (!prevSiblingBond) return;
    replaceParent({
      bondId,
      parentBondId,
      newParentBondId:prevSiblingBond.id,
      indent:true,
    })
  }

  function unindentLine(){
    replaceParent({
      bondId,
      parentBondId,
      newParentBondId:grandBondId,
      indent:false,
    })
  }

  function moveLineDown(){
    adjustPosition({parentBondId, parentId, nodeId, adjustment:2})
  }

  function moveLineUp(){
    adjustPosition({parentBondId, parentId, nodeId, adjustment:-1})
  }

  /**
   * Remove the current line and select the previous line.
   */
  function removeBond(event:React.ChangeEvent<HTMLTextAreaElement>){
    const [prevNodeId, prevSelectionId] = getPrevNodeId(event.target, rootNodeId);
    log("removeBond", "prevSelectionId", prevSelectionId, "prevNodeId", prevNodeId, event.target)
    setSelection(prevSelectionId);
    deleteBond({bondId, parentBondId})
  }

  function combineWithPreviousLine(event:React.ChangeEvent<HTMLTextAreaElement>){
    log("combineWithPreviousLine")
    const text = event.target.value;
    log("combineWithPreviousLine", "text", text)
    let [prevNodeId, prevSelectionId] = getPrevNodeId(event.target, rootNodeId);
    
    updateText({
      nodeId:prevNodeId,
      text:text,
      append:true,
      debounce:false,
    });
    deleteBond({bondId, parentBondId});
    setSelection(prevSelectionId);
  }

  function getTextSplit(position:number, text:string){
    const front = text.substring(0, position).trim();
    const back = text.substring(position).trim();
    return [
      front,
      back,
    ]
  }

  const setText = useCallback(
    (text:string)=>{
      log("setText", text)
      // only update text if it's changed
      if (text === localText) {
        log("setText skip", text, localText)
        return
      } 
      setLocalText(text);
      updateText({nodeId, text, debounce:false});
    },
    [localText, nodeId, updateText, setLocalText]
  )

  const copyLineUp = useCallback(
    (event:React.KeyboardEvent<HTMLTextAreaElement>)=>{
      // get the text in the input
      const {value} = (event.target as HTMLTextAreaElement)
      const text = value?.trim() || ""

      createSiblingBond({
        text:text,
        bondId,
        parentBondId,
        before:true,
      })
    },
    [createSiblingBond, bondId, parentBondId]
  )

  const copyLineDown = useCallback(
    (event:React.KeyboardEvent<HTMLTextAreaElement>)=>{
      // get the text in the input
      const {value} = (event.target as HTMLTextAreaElement)
      const text = value?.trim() || ""

      createSiblingBond({
        text:text,
        bondId,
        parentBondId,
        before:false,
      })
    },
    [createSiblingBond, bondId, parentBondId]
  )

  const createNewLine = useCallback(
    (event:React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (!event || !event.target) return
      
      log("createNewLine")

      // get the text in the input
      const {value, selectionStart} = (event.target as HTMLTextAreaElement)
      const text = value?.trim() || ""

      // determine the text before and after the cursor
      const [frontText, backText] = getTextSplit(
        selectionStart || 0,
        text,
      )
      const cursorAtStart = backText && !frontText;
      
      /**
       * The root should always create a new child
       */
      if (isRoot){
        // we split the text so the front half remains the root
        log("createNewLine", "create root child", {frontText, backText})
        setText(frontText);
        createChildBond({
          parentId: nodeId,
          text: backText
        })
        return
      }

      /**
       * When the cursor is at the start of the line
       * insert an empty sibling ahead of this line
       */
      if (cursorAtStart){
        log("createNewLine", "createSibling")
        createSiblingBond({
          bondId,
          parentBondId,
          before:true,
        })
        return;
      }

      /**
       * If the cursor is not at the front, use the text before
       * the cursor as the current node's text
       */
      log("createNewLine", "setText", frontText)
      setText(frontText);
      
      
      /**
       * Make a new child of the node if the children
       * are shown, or if the shift key is pressed.
       * 
       * If are shown and the shift key is pressed, make a sibling.
       */

      // create a child if the shift key is pressed
      const shouldMakeChild = event.shiftKey && window.visualViewport && window.visualViewport.width > MAX_MOBILE;

      if (shouldMakeChild){
        log("createNewLine", "createChild", backText)
        createChildBond({
          bondId: bondId,
          text: backText,
        });
        return
      }

      log("createNewLine", "createSibling", backText)
      /**
       * A normal return should make a sibling node right after this line.
       */
      createSiblingBond({
        bondId,
        parentBondId,
        text: backText,
      })

    }, 
    [isRoot, bondId, parentBondId, nodeId, createChildBond, createSiblingBond, setText]
  )

  /*
  * Create a new child for each line break in the pasted text
  */
  function handlePasteMany(currentText:string, pastedLines:string[]){
    const siblingParams:CreateSiblingBondParams[] = []
    pastedLines.forEach((text:string, index:number)=>{
      // skip empty lines
      if (!text) return;
      if (index === 0){
        // TODO: delete the current text if selected
        setText(currentText + text);
      } else {
        siblingParams.push({
          bondId,
          parentBondId,
          text,
          positionIndex:index,
        })
      }
    })
    createManySiblingBonds(siblingParams);
  }

  // RENDER
  
  // do not include the line input if the select input is open
  if (showSelect) return null;
  
  log("render line localText", localText)
  log("selectRoot", selectRoot)
  const classNames = clsx("editor-line-input",  bond?.kind)
  return (
    <>
      <LineInput
        onFocus={handleFocus}
        actionBarLabel={"node"}
        
        autoFocus={isRoot && selectRoot}
        inputRef={inputRef}
        className={classNames}
        text={localText}
        onEnter={createNewLine}
        onEnterAlt={setNodeAsRoot}
        isSelectable
        selectionId={currentSelectionId}
        onSlashEmpty={() => setShowSelect(true)}
        // support mobile GreaterThan key
        onGreaterThanEmpty={convertSelectedToSubgroup}
        // support desktop GreaterThan alt key
        onGreaterThanShiftEmpty={convertSelectedToSubgroup}
        onTextChange={setNodeText}
        onTabStart={indentLine}
        onTabShiftStart={unindentLine}
        onArrowDownAlt={moveLineDown}
        onArrowUpAlt={moveLineUp}
        onArrowUpAltShift={copyLineUp}
        onArrowDownAltShift={copyLineDown}
        onBackspaceEmpty={removeBond}
        onBackspaceShiftEmpty={removeBond}
        onBackspaceAltEmpty={removeBond}
        onBackspaceMetaEmpty={removeBond}
        onBackspaceStart={combineWithPreviousLine}
        onBackspaceShiftStart={combineWithPreviousLine}
        onPasteMany={handlePasteMany}
      />
    </>
  )
})

/**
 * The select input for replacing this node with an existing node.
 */
const NodeSelect = observer(function(){
  // IMPORTS
  const {
    bondId,
    parentBondId,
  } = useLineContext()
  const {editor} = useEditor()
  const {
    replaceChild,
    selectedBond,
    showSelectedLookup,
    setShowSelectedLookup: setShowSelect,
  } = editor;

  const showSelect = showSelectedLookup && selectedBond?.bondId === bondId;

  // ACTIONS
  function handleSelectNode(node:Node){
    const newNodeId = node?.id;
    replaceChild({
      bondId,
      newNodeId,
      parentBondId,
    });
    setShowSelect(false);
  }

  //  RENDER
  if (!showSelect) return null;
  return (
    <NodeLookupInput
      showSuggestions
      onSelectNode={handleSelectNode}
      onBackspaceEmpty={()=>setShowSelect(false)}
      orderBy="last_subgroup"
    />
  )
})


const SetAsRootButton = observer(function(){
  const {
    setNodeAsRoot,
    isRoot,
  } = useLineContext();

  // We don't need this button if the line is already the root
  if (isRoot) return null;

  return (
    <button
        data-selection-nav
        onClick={setNodeAsRoot}
    >
      <TargetIcon/>
    </button>
  )
})
