import React, {createContext, useContext, useEffect, useState} from "react"
import {debounce} from 'lodash';

import "./connection.scss"
/**
 * The point of this provider is to track if the websocket connection
 * is currently connected.
 * This is in an effort to debug and eventually give the user options
 * when disconnected.
 */

type ConnectionState = "Connecting" | "Connected" | "Disconnected" | "Reconnecting" | ""

const DISPLAY_STATE_MAP = {
  "Connecting": "Connecting...",
  "Connected": "",
  "Disconnected": "Offline Mode",
  "Reconnecting": "Reconnecting...",
}

interface ContextValue {
  connectionState:ConnectionState
  isConnected: boolean
  isOffline: boolean
}

const Context = createContext<ContextValue>({} as ContextValue);


type ProviderProps = {
  children: React.ReactNode,
}

export function Provider(props: ProviderProps){
  const [connectionState, setConnectionState] = useState<ConnectionState>("Connecting");

  const debouncedSetState = debounce(
    setConnectionState,
    200,
    {leading:false, trailing:true},
  );

  function handleWebsocketConnect(event:CustomEvent<string>){
    debouncedSetState(event.detail as ConnectionState)
  }

  const value:ContextValue = {
    connectionState,
    isConnected: connectionState === "Connected",
    isOffline: connectionState === "Disconnected",
  }

  console.log("connectionState", connectionState)

  useEffect(
    ()=>{
      debouncedSetState("Connecting");
      // add an appropriate event listener
      window.addEventListener("websocketConnect", handleWebsocketConnect as EventListener);
      return function cleanup(){
        window.removeEventListener("websocketConnect", handleWebsocketConnect as EventListener)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return (
    <Context.Provider value={value}>
      <ConnectionIndicator/>
      {props.children}
    </Context.Provider>
  )
}

export default function useConnection(){
  return useContext(Context);
}

function ConnectionIndicator(){
  const {connectionState, isConnected} = useConnection()
  // if we have no value for some reason, don't show anything
  if (connectionState === "") return null;
  // if we are connected, don't show anything
  if (isConnected) return null;
  // otherwise, show the connection state

  const displayState = DISPLAY_STATE_MAP[connectionState] || connectionState;

  return (
    <div className="connection">{displayState}</div>
  )
}