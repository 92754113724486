import { NodeKind, Bond, FilterParam} from "components/graphql";
import { EditorStoreType, DisplayParentBondParams } from "./storeTypes";

export const displayParentBond = function(
  this:EditorStoreType,
  params:DisplayParentBondParams
){
  const {bond, filterParams} = params;
  
  // never display hidden bonds
  if(bond.isHidden) return false;

  // if the bond parent is a node_kind PROP, include it
  const parent = this.persist.getNode(bond.parentId);
  if (parent.kind === NodeKind.PROP) return true;

  // do not include the bond if it matches any filterParam
  for (let i=0; i < filterParams.length; i++){
    if (bondMatchesParam(bond, filterParams[i])) return false
  }
  return true
}

const NON_MATCH_KEYS = ["include", "operator"]

function bondMatchesParam(bond:Bond, filterParam:FilterParam){
  // the bond must match all the keys
  const keyValues = Object.entries(filterParam);
  for (let i=0; i < keyValues.length; i++){
    const [key, value] = keyValues[i] as [keyof Bond, any];
    if (NON_MATCH_KEYS.includes(key)) continue;
    if (bond[key] !== value) {
      return false
    }
  }
  return true
}
