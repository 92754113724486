import { makeObservable, observable, action, computed } from "mobx"
import {computedFn} from "mobx-utils"

import { updateStateFromUrl } from "common/hooks/useQueryParamState";

import {getDebugLog, isSameDict} from "common"
import {
  FilterParam, 
  Bond,
  SortParam,
  BondKind,
} from "components/graphql";
import {errorStore, ErrorStore} from "controllers/useErrors"
import {formatSelectedNodeId} from "common/contexts/SelectContext"

import { PersistState, BondPartial, NodePartial } from "controllers/usePersist";
import {
  EditorStoreType,
  VIEW,
  ToggleChildrenParams,
  SetSelectedBondParams,
  ConvertToSubgroupParams,
  UpdateKindParams,
  AdjustPositionParams,
  SetPositionIndexParams,
  DeleteBondParams,
  CreateChildBondParams,
  CreateParentBondParams,
  CreateSiblingBondParams,
  AddFilterParams,
  UpdateFilterParams,
  UpdateSortParamParams,
  ReplaceChildParams,
  ReplaceParentParams,
  UpdatePropValueParams,
  UpdateTextParams,
  DisplayParentBondParams,
  SelectedBond,
  LAYOUT,
} from "./storeTypes";
import { toggleChildren } from "./toggleChildren"
import { getFilteredChildBonds } from "./getFilteredChildBonds";
import { orderBonds, getOrderValue } from "./orderBonds";
import { adjustPosition, getNewPosition, setPositionIndex } from "./position";
import { deleteBond } from "./deleteBond";
import { createChildBond } from "./createChildBond";
import { createParentBond } from "./createParentBond";
import { createSiblingBond, createManySiblingBonds } from "./createSiblingBond";
import { addFilter, removeFilter, setDefaultFilterParams, setFilterParams, updateFilter } from "./filters";
import { replaceChild } from "./replaceChild";
import { replaceParent } from "./replaceParent";
import { addSortParam, removeSortParam, setDefaultSortParams, setSortParams, updateSortParam } from "./sortParams";
import { updateText } from "./updateText";
import { displayParentBond } from "./displayParentBond";
import { getRoot } from "./getRoot";
import { updatePropValue } from "./updatePropValue";
import { updateViewParams } from "./viewParams";
import { setSelectedBondInfo, convertSelectedToSubgroup, setShowSelectedLookup, checkSelectedIsEmpty } from "./selectedBond";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = getDebugLog(false, "EditorStore");

export const URL_STATE_KEYS = ["filterParams", "sortParams", "layout"]; 

export class EditorStore implements EditorStoreType {

  constructor(persist:PersistState, rootNodeId:string | null = null){
    console.log("EditorStore constructor", {rootNodeId})
    
    this.persist = persist;
    this.errorStore = errorStore;
    this.rootNodeId = rootNodeId;
    

    this.toggleChildren = toggleChildren.bind(this);
    this.getFilteredChildBonds = computedFn(getFilteredChildBonds.bind(this));
    this.getOrderValue = getOrderValue.bind(this);
    this.orderBonds = orderBonds.bind(this);
    this.getNewPosition = getNewPosition.bind(this);
    this.adjustPosition = adjustPosition.bind(this);
    this.setPositionIndex = setPositionIndex.bind(this);
    this.deleteBond = deleteBond.bind(this);
    this.createChildBond = createChildBond.bind(this);
    this.createParentBond = createParentBond.bind(this);
    this.createSiblingBond = createSiblingBond.bind(this);
    this.createManySiblingBonds = createManySiblingBonds.bind(this);
    this.setFilterParams = setFilterParams.bind(this);
    this.setDefaultFilterParams = setDefaultFilterParams.bind(this);
    this.removeFilter = removeFilter.bind(this);
    this.updateFilter = updateFilter.bind(this);
    this.addFilter = addFilter.bind(this);
    this.setSortParams = setSortParams.bind(this);
    this.setDefaultSortParams = setDefaultSortParams.bind(this);
    this.addSortParam = addSortParam.bind(this);
    this.updateSortParam = updateSortParam.bind(this);
    this.removeSortParam = removeSortParam.bind(this);
    this.replaceChild = replaceChild.bind(this);
    this.replaceParent = replaceParent.bind(this);
    this.updateText = updateText.bind(this);
    this.displayParentBond = displayParentBond.bind(this);
    this.getRoot = getRoot.bind(this);
    this.updatePropValue = updatePropValue.bind(this);
    this.updateViewParams = updateViewParams.bind(this);
    this.setSelectedBondInfo = setSelectedBondInfo.bind(this);
    this.convertSelectedToSubgroup = convertSelectedToSubgroup.bind(this);
    this.setShowSelectedLookup = setShowSelectedLookup.bind(this);
    this.checkSelectedIsEmpty = checkSelectedIsEmpty.bind(this);
    
    makeObservable(this);

    updateStateFromUrl(this, URL_STATE_KEYS)
  }

  // PROPS
  persist:PersistState
  errorStore:ErrorStore

  // OBSERVABLES
  @observable rootNodeId?: string | null = null;
  @observable pinned:boolean = false;
  @observable view:VIEW = VIEW.EDITOR;
  @observable layout:LAYOUT = LAYOUT.ROWS;
  @observable filtersTouched:boolean = false;
  @observable filterParams:FilterParam[] = [];
  @observable defaultFilterParams:FilterParam[] = [];
  @observable sortParams:SortParam[] = [];
  @observable sortParamsTouched:boolean = false;
  @observable defaultSortParams:SortParam[] = [];
  // the node that has focus
  @observable selectedNodeId: string = '';
  @observable selectedNodeIdRenderCount: number = 0;
  // A map of which bonds should display their children
  @observable showChildren: {[bondId:string]:boolean} = {};
  @observable openFilter: boolean = false;
  @observable selectedBond?: SelectedBond | null = null;
  @observable showSelectedLookup: boolean = false;
  @observable selectedIsEmpty: boolean = true;
  
  // COMPUTED
  @computed get hasSortParams():boolean {
    return this.sortParams.length > 0;
  }

  @computed get canSaveView():boolean {
    const filtersAreDefault = isSameDict(this.filterParams, this.defaultFilterParams);
    const sortParamsAreDefault = isSameDict(this.sortParams, this.defaultSortParams);
    const hasChange = !filtersAreDefault || !sortParamsAreDefault;
    log("canSaveView", {filtersAreDefault, sortParamsAreDefault, hasChange})
    return hasChange;
  }

  @computed get rootText():string {
    return this.persist.getNodeMaybe(this.rootNodeId)?.text || "";
  }

  getShowChildren = computedFn((bondId:string):boolean => {
    // show children of the root
    if (!bondId) return true
    let showChildren = this.showChildren[bondId];
    if (showChildren === undefined) {
      // show subgroup children by default
      const bond = this.persist.getBond(bondId);
      showChildren = bond.kind === BondKind.SUBGROUP;
    }
    return showChildren;
  })

  // ACTIONS
  @action setSelectedBond = (params: SetSelectedBondParams) => {
    const {
      parentId,
      nodeId,
    } = params;

    this.selectedNodeId = formatSelectedNodeId(parentId, nodeId);
    this.selectedNodeIdRenderCount += 1;
  }

  @action convertToSubgroup = (params: ConvertToSubgroupParams) => {
    const {
      bondId,
    } = params;

    this.persist.updateObjects({
      bonds:[{
        id:bondId,
        kind:BondKind.SUBGROUP,
      }],
    })
  }

  @action updateKind = (params: UpdateKindParams): void => {
    const {
      nodeId,
      kind,
      propType,
    } = params;

    const node: NodePartial = {
      id: nodeId,
    }

    if (kind) node.kind = kind;
    if (propType) node.propType = propType;

    this.persist.updateObjects({
      nodes:[node],
    })
  }

  @action setOpenFilter = (openFilter: boolean): void => {
    this.openFilter = openFilter;
  }

  @action openMetaEditor = () => {
    this.view = VIEW.META;
  }
  @action openEditor = () => {
    this.view = VIEW.EDITOR;
  }
  @action openSort = () => {
    const bonds = this.getFilteredChildBonds("");
    if (bonds.length < 2) {
      this.errorStore.addError("You must have at least 2 items to compare.")
      return;
    }
    this.view = VIEW.SORT;
  }

  @action toggleLayout = () => {
    if (this.layout === LAYOUT.ROWS) this.layout = LAYOUT.COLUMNS;
    else this.layout = LAYOUT.ROWS;
  }
  
  // METHODS
  isRoot = (nodeId:string):boolean => {
    return nodeId === this.rootNodeId;
  }

  /**
   * Create bonds for each filter is the root is a new parent
   */
  addBondsForFilters = (bonds:BondPartial[], nodeId:string):void => {
    const {rootNodeId, filterParams} = this;
    const rootIsParent = Boolean(bonds.find(bond => bond.parentId === rootNodeId))
    // log("addBondsForFilters", {rootIsParent, bonds, nodeId, filterParams});
    if (!rootIsParent) return;
    
    // for each filter, create or update the bond to the node
    filterParams.forEach((filterParam:FilterParam)=>{
      const {
        parentId,
        include=true,
        operator="",
        boolean,
        number,
        datetime,
        valueId,
      } = filterParam;
      // skip if the filter is excluding the parent
      if (!include) return;
      if (!parentId) return;

      // check if the bond is already in the list
      let filterBond = bonds.find(bond => bond.parentId === parentId);
      // if not, create a new bond
      if (!filterBond) {
        filterBond = {
          parentId,
          nodeId,
        }
      }
      
      // have it match the current filter
      if (valueId) filterBond.valueId = valueId;
      if (boolean !== undefined) filterBond.boolean = boolean;
      if (number !== undefined && number !== null) {
        filterBond.number = number;
        if (operator === "gt") filterBond.number += 1;
        else if (operator === "lt") filterBond.number -= 1;
      };
      if (datetime) {
        filterBond.datetime = datetime;
        log("datetime", datetime)
        // add a day to the datetime
        if (operator === "gt") {
          const date = new Date(datetime);
          date.setDate(date.getDate() + 1);
          filterBond.datetime = date.toISOString();
        }
        // subtract a day from the datetime
        else if (operator === "lt") {
          const date = new Date(datetime);
          date.setDate(date.getDate() - 1);
          filterBond.datetime = date.toISOString();
        }
      }

      log("new filterBond", filterBond)
      // add to the beginning of the bonds array so they are
      // created before auto bonds are created
      bonds.unshift(filterBond);
    })
  }

  /**
   * returns the bondId for the bond the given bondId
   */
  getSiblingBond = (bondId:string, parentBondId:string, offset:number):Bond | void => {
    const siblingBonds = this.getFilteredChildBonds(parentBondId);
    const idx = this.persist.getBondIdx(siblingBonds, bondId);
    const siblingIdx = idx + offset;
    if (siblingIdx < 0) return;
    if (siblingIdx > siblingBonds.length - 1) return;
    return siblingBonds[siblingIdx];
  }


  // IMPORTED ACTIONS
  @action toggleChildren = (params:ToggleChildrenParams)=> {};
  @action adjustPosition = (params: AdjustPositionParams) => {};
  @action setPositionIndex = (params: SetPositionIndexParams) => {};
  @action deleteBond(params: DeleteBondParams) {}
  @action createChildBond(params: CreateChildBondParams) {}
  @action createParentBond(params: CreateParentBondParams) {}
  @action createSiblingBond(params: CreateSiblingBondParams){}
  @action createManySiblingBonds(params: CreateSiblingBondParams[]){}
  @action setFilterParams(params: FilterParam[], filtersTouched: boolean) {}
  @action setDefaultFilterParams(params: FilterParam[]) {}
  @action removeFilter(idx: number|string) {}
  @action addFilter(params: AddFilterParams) {}
  @action updateFilter(params: UpdateFilterParams) {}
  @action setSortParams(params: SortParam[], touched: boolean){}
  @action setDefaultSortParams(params: SortParam[]) {}
  @action addSortParam(params: SortParam){}
  @action updateSortParam(params: UpdateSortParamParams) {}
  @action removeSortParam(idx: number){}
  @action replaceChild(params: ReplaceChildParams){}
  @action replaceParent(params: ReplaceParentParams){}
  @action updatePropValue(params: UpdatePropValueParams){}
  @action updateText(params: UpdateTextParams){}
  @action updateViewParams(){}
  @action getRoot(){
    console.log("set up getRoot")
  }
  @action setSelectedBondInfo(selectedBond:SelectedBond){}
  @action convertSelectedToSubgroup(){}
  @action setShowSelectedLookup(show:boolean){}
  @action checkSelectedIsEmpty(){}

  // IMPORTED METHODS
  getFilteredChildBonds = (bondId:string) => {return [] as Bond[]};
  orderBonds = (parentId:string, bondsHere:Bond[])=> {};
  getOrderValue = (sortParam:SortParam, bond:Bond) => {return null};
  getNewPosition(parentBondId: string, nodeId: string, adjustment: number): { bond: Bond; position: number; } {
    return {
      bond: {} as Bond,
      position: 0,
    }
  }
  displayParentBond = (params:DisplayParentBondParams) => {}
}
