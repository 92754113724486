import { useCallback, useReducer } from "react";

import { useRefFromState } from "common/hooks/state";

/**
 * A reusable hook for managing state and methods available via a context provider
 */
export function useContextController<State, ContextValue>(
  initialState: State,
  reducerMap: {
    [key: string | number]: (state: State, payload: any) => State;
  },
  methodHooks: { (context: ContextValue): void }[],
  passedValues: { [key:string]: any} = {},
): ContextValue {

  /**
   * A simple reducer function that maps the state and payload a reducer function
   * defines in reducerMap
   */
  const reducer = useCallback(
    (state: State, action:{type:any, payload:object}):State =>{
      const { type, payload } = action;
      const fn = reducerMap[type]
      const isInvalid = !fn || !(fn instanceof Function)
      if (isInvalid) {
        // use default update state if no reducer function
        // already exists for the action type
        if (type === "updateState") return {
          ...state,
          ...payload,
        }
        console.error("invalid reducer function:", type)
        return state
      }
      return fn(state, payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const [state, dispatch] = useReducer(reducer, initialState);

  // adds the current state to ref.current any time state changes
  // so functions can easily access values of the current state
  const stateRef = useRefFromState<State>(state);

  let contextValue = {
    ...passedValues,
    ...state,
    dispatch,
    state,
    stateRef,
  } as unknown as ContextValue;

  // Here we're looping over our method hooks so
  // so each methods hook can utilize the methods defined before it
  methodHooks.forEach((hook) => {
    hook(contextValue);
  });

  // state and methods are made available to UI components
  return contextValue;
}
