import React, { createContext, useContext } from "react";

import { useWindowHotkeys } from "common/hooks/dom";
import { getDebugLog } from "common/utils";

const log = getDebugLog(false, "SelectContext")

const initialContext = {};

class SelectionStore {
  setSelection = (selectedId:string, attemptCount=0) => {
    if (!selectedId) return;
    log("setSelection focus", selectedId)
    const elements = document.querySelectorAll(`[data-selection-id="${selectedId}"]`);
    if (elements.length === 0) {
      log("no element found")
      if (attemptCount > 3) return;
      log("retrying")
      setTimeout(()=>{
        this.setSelection(selectedId, attemptCount+1)
      });
      return;
    }
    const element = elements[0] as HTMLElement;
    element?.focus();
  }
}
export const selectionStore = new SelectionStore();

interface SelectedNodeIds {
  parentId:string,
  nodeId:string,
}

export function getIdsFromSelectionId(selectionId:string):SelectedNodeIds{
  const [parentId, nodeId] = selectionId.split(":")
  return {
    parentId,
    nodeId,
  }
}

export type ContextValue = {
  setSelection(selectionId:string):void, 
}

const SelectContext = createContext<ContextValue>(initialContext as ContextValue);

type ProviderProps = {
  children: React.ReactNode,
}
export function SelectProvider(props:ProviderProps) {
  const { children } = props;

  useSelectHotkeys(selectionStore.setSelection);

  return <SelectContext.Provider value={selectionStore}>{children}</SelectContext.Provider>;
}

export function useSelectContext(){
  return useContext(SelectContext)
}


function useSelectHotkeys(setSelection: (selectionId:string)=> void){
  
  function onArrowDown(event:Event){
    const {target} = event
    if (!(target instanceof HTMLElement)) return;
    if (!shouldHandleArrow(target)) return;
    const {next, last} = getSelectableNeighborElements(target);
    selectElement(next, last);
  }

  function onArrowUp(event:Event){
    const {target} = event
    if (!(target instanceof HTMLElement)) return;
    if (!shouldHandleArrow(target)) return;
    const {prev, first} = getSelectableNeighborElements(target);
    selectElement(prev, first);
  }

  function selectElement(el?:HTMLElement, backupEl?:HTMLElement){
    // first clear the selection
    // in order to toggle isSelected for the line
    log("selectElement", {el, backupEl})
    setSelection("");
    if (!el) {
      el = backupEl;
    }
    if (!el) return; 
    const selectionId = getSelectionId(el);
    if (!selectionId) return;
    setSelection(selectionId)
  }

  useWindowHotkeys({
    onArrowDown,
    onArrowUp,
  });
}

function shouldHandleArrow(element?:HTMLElement):boolean{
  // select an element when nothing is selected
  if (element === document.body) return true;
  // select an element when a selectable element is selected
  const selectionId = getSelectionId(element);
  if (selectionId) return true;
  return isSelectNavItem(element);
};

function getSelectionId(el?:HTMLElement): string{
  if (!el) return "";
  return el?.dataset.selectionId || "";
}

// elements that should allow nav on arrow moves
function isSelectNavItem(el?:HTMLElement):boolean{
  if (!el) return false;
  return !!el.dataset.selectionNav;
}

function getSelectableNeighborElements(targetEl:HTMLElement){
  const inputs = document.querySelectorAll('[data-selection-nav]');
  
  let first: HTMLElement | undefined;
  let last: HTMLElement | undefined;
  let next: HTMLElement | undefined;
  let prev: HTMLElement | undefined;
  let hasPassedTargetEl = false;

  inputs.forEach(function(el){
    const isHtmlEl = el instanceof HTMLElement
    if (!isHtmlEl) return;

    const selectionId = getSelectionId(el);
    // set the first in the dom
    if (!first && selectionId) {
      first = el;
    }
    // if this is the target, make the last element the prev
    if (el === targetEl){
      prev = last;
    } else if (!next && hasPassedTargetEl && selectionId){
      next = el
    }
    if (selectionId) last = el
    if (targetEl === el) hasPassedTargetEl = true;
  })
  log("getSelectableNeighborElements", {targetEl, first, last, prev, next})
  return {first, last, prev, next}
}

export function formatSelectedNodeId(parentId="", nodeId=""){
  if (!parentId) return "root";
  return `${parentId}:${nodeId}`
}

export function getPrevNodeId(element:HTMLElement, rootNodeId?:string|null): [string, string]{
  const {prev} = getSelectableNeighborElements(element);
  const prevSelectionId = getSelectionId(prev);
  if (prevSelectionId === "root") return [rootNodeId || "", "root"];
  const {nodeId} = getIdsFromSelectionId(prevSelectionId);
  return [nodeId, prevSelectionId];
}
