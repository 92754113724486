import * as React from "react";
import "./modal.scss";

interface ModalProps {
  children: React.ReactNode;
  onCancel(): void;
}

export default function Modal(props: ModalProps) {
  const { children, onCancel } = props;

  return (
    <div className="modal-container">
      <div className="modal">
        <div onClick={onCancel} className="modal-close">
          X
        </div>
        {children}
      </div>
    </div>
  );
}
