import { action } from "mobx";

import { AdjustPositionParams, EditorStoreType, SetPositionIndexParams } from "./storeTypes";
import { Bond } from "components/graphql";
import { getDebugLog } from "common";


const log = getDebugLog(false, "editor:store:position")

/**
 * Adjusts the index of the bond by the given adjustment
 */
export const getNewPosition = function(
  this:EditorStoreType,
  parentBondId:string,
  nodeId:string,
  adjustment:number,
):{bond:Bond, position:number}
{
  const childBonds = this.getFilteredChildBonds(parentBondId);
  // find the index of the bond for the nodeId
  const idx = this.persist.getBondNodeIdx(childBonds, nodeId);
  // create a new position for the bond at the new index
  const position = this.persist.generatePositionAfterIndex(childBonds, idx + adjustment);
  return {
    bond: childBonds[idx],
    position,
  }
}

/**
 * Adjusts the position of the given bond by the number
 * of index positions given.
 */
export const adjustPosition = action(function(
  this:EditorStoreType,
  params: AdjustPositionParams,
){
  const {
    parentBondId,
    parentId,
    nodeId,
    adjustment,
  } = params;

  log("adjustPosition", params)

  // prevent adjusting the position when results are sorted
  if (this.isRoot(parentId) && this.hasSortParams) return;
  
  const {
    bond,
    position,
  } = this.getNewPosition(parentBondId, nodeId, adjustment)
  
  this.persist.updateObjects({
    bonds: [{
      id: bond.id,
      position,
    }]
  })
})

/**
 * This method is used to explicitly set the position of a bond
 * relative to it's parent.
 */
export const setPositionIndex = action(function(
  this:EditorStoreType,
  params: SetPositionIndexParams,
){
  const {
    bondId,
    index,
  } = params;

  const bond = this.persist.getBond(bondId)
  const position = this.persist.getNewPositionForIndex(bond.parentId, index)
  
  this.persist.updateObjects({
    bonds: [{
      id: bondId,
      position,
    }]
  })
})