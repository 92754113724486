import React from 'react';

export function CloseIcon() {
  return (
    <svg
      className='icon'
      width="24"
      height="24"
      viewBox="-2 -3 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L14 14M2 14L14 2"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}