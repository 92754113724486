import { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";

export function useEffectDeep(callback:() => void, dependencies:any[], shouldLog=false) {
  const firstRenderRef = useRef(true);
  const dependenciesRef = useRef(dependencies);

  useEffect(() => {
    const dependenciesChanged = 
      firstRenderRef.current ||
      !isEqual(dependencies, dependenciesRef.current);


    if (shouldLog) console.log("Dependencies changed:", dependenciesChanged, firstRenderRef.current, dependencies);
    if (!dependenciesChanged) return;


    firstRenderRef.current = false;
    dependenciesRef.current = dependencies;

    callback();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export function useDeepMemo<T>(callback:() => T, dependencies:any[], shouldLog=false){
  const [value, setValue] = useState<T>(callback());
  useEffectDeep(
    ()=>{
      const newValue = callback();
      console.log("useDeepMemo callback", newValue)
      setValue(newValue)
    },
    dependencies,
    shouldLog
  )
  return value;
};

export function useLogChange(name:string, state:any){
  const prev = useRef(state);
  useEffect(
    ()=>{
      console.log(name, "changed", state)
      console.log(name, "prev", prev.current)
      prev.current = state;
    },
    [name, state]
  )
}

export function useLogChangeDeep(name:string, state:any){
  const prev = useRef(state);
  useEffectDeep(
    ()=>{
      console.log(name, "deep changed", state)
      console.log(name, "deep prev", prev.current)
      prev.current = state;
    },
    [name, state]
  )
}