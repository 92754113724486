import React from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";


import { apollo } from "common/apollo";
import Home from "components/Home";
import {ErrorProvider} from "controllers/useErrors"
import { ConnectionProvider } from "controllers/useConnection";
import Errors from "components/Errors"
import {AuthProvider} from "components/AuthPage";
import "./css/App.scss";
import { LoadingProvider } from "controllers/useLoading";
import LoadingIndicator from "components/LoadingIndicator";

function App() {
  return (
    <GlobalProviders>
      <Switch>
        <Route path="/" component={Home} />
      </Switch>
    </GlobalProviders>
  );
}

export default App;


type ProvidersProps = {
  children: React.ReactNode,
}
function GlobalProviders(props:ProvidersProps) {
  return (
    <ApolloProvider client={apollo}>
      <LoadingProvider>
        <ErrorProvider>
          <AuthProvider>
              <BrowserRouter>
                <ConnectionProvider>
                  <Errors/>
                  <LoadingIndicator/>
                  {props.children}
                </ConnectionProvider>
              </BrowserRouter>
          </AuthProvider>
        </ErrorProvider>
      </LoadingProvider>
    </ApolloProvider>
  )
}
