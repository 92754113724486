import {State, View, SetViewPayload as Payload} from "../types";

export function setView(state:State, payload:Payload): State {
  let { view } = payload;
  let { localDatetime } = state;

  if (view === View.CALENDAR && localDatetime === null) {
    localDatetime = new Date();
  }
  if (view === View.DISPLAY) {
    localDatetime = null;
  }
  return {
    ...state,
    view,
    localDatetime,
  }
}