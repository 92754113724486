import React, { useEffect, createContext, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import { useWindowHotkeys } from "common/hooks/dom";

import RootStore from "./RootStore";

const rootStore = new RootStore();

const Context = createContext<RootStore>({} as RootStore);

interface UrlParams {
  nodeId?:string,
}

type ProviderProps = {
  children: React.ReactNode,
}

export const Provider = observer( function (props: ProviderProps){
  const {
    children,
  } = props;

  let { nodeId } = useParams<UrlParams>();

  console.log("url nodeId", nodeId)

  // set the nodeId in the store when the url changes
  useEffect(()=>{
    rootStore.setNodeId(nodeId)
  }, [nodeId])

  // set up hotkeys
  useWindowHotkeys({
    on1Meta: rootStore.openLaunchPad,
    on2Meta: rootStore.createNewPage,
    on3Meta: rootStore.openRandomPage,
  });

  return (
    <Context.Provider value={rootStore}>
      {children}
    </Context.Provider>
  );
})

/**
 * A convenience hook for accessing the context value
 */
export default function useRoot(){
  return useContext(Context);
}
