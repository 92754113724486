import { action } from "mobx"
import { EditorStoreType, UpdatePropValueParams } from "./storeTypes";
import { BondPartial } from "controllers/usePersist";
import { BondKind } from "components/graphql";


export const updatePropValue = action(function(
  this:EditorStoreType,
  params: UpdatePropValueParams,
){
  const {
    bondId,
    valueId,
    datetime,
    number,
    boolean,
  } = params;

  const bonds:BondPartial[] = [
    {
      id: bondId,
      valueId,
      datetime,
      number,
      boolean,
    }
  ]

  const bond = this.persist.getBond(bondId);
  const {nodeId} = bond;
  
  const previousValueId = valueId ? bond.valueId : undefined;
  
  // Only content bonds should inherit the value_id as a hidden parent.
  // We only need to inherit when the value_id is updated.
  const shouldInherit = bond.kind === BondKind.CONTENT && valueId
  if (shouldInherit){
    const existingBond = this.persist.getBondFromNodes(valueId, nodeId) || {id:this.persist.newId()};
    // Make a hidden bond between the bond.valueId and the bond.nodeId
    bonds.push({
      id: existingBond.id,
      parentId:valueId,
      nodeId,
      isHidden: true,
    })
  }

  if (previousValueId){
    // fetch the pervious hidden bond and archive it
    const previousHiddenBond = this.persist.getBondFromNodes(previousValueId, nodeId);
    if (previousHiddenBond){
      bonds.push({
        id: previousHiddenBond.id,
        archivedAt: this.persist.now(),
      })
    }
  }

  this.persist.updateObjects({bonds})
})