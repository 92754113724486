import { ContextValue } from "../types";
import useCreateAutoBond from "./useCreateAutoBond";
import useGetAutoBonds from "./useGetAutoBonds";
import useRemoveAutoBond from "./useRemoveAutoBond";
import useUpdateAutoPropValue from "./useUpdateAutoPropValue";
import useUpdateState from "./useUpdateState";
import useCreateAutoSubgroup from "./useCreateAutoSubgroup";
import useRemoveAutoSubgroup from "./useRemoveAutoSubgroup";

const methodHooks: {(context: ContextValue): void}[] = [
  useUpdateState,
  useGetAutoBonds,
  useCreateAutoBond,
  useRemoveAutoBond,
  useUpdateAutoPropValue,
  useCreateAutoSubgroup,
  useRemoveAutoSubgroup,
];

export default methodHooks