import { useEffect } from "react";

import {NodeKind, PropType } from "components/graphql";
import { ContextValue } from "../types";

export default function useNeedsSelection(context:ContextValue):void{
  const {
    dispatch,
    stateRef,
    parentNode,
    number,
    datetime,
    valueId,
    boolean,
  } = context;

  useEffect(
    () => {
      const {needsSelection: oldNeedsSelection} = stateRef.current;
      let  needsSelection = false;
      const {propType, kind} = parentNode || {};
      
      if (kind === NodeKind.NODE) {
        needsSelection = false;
      } else if (propType === PropType.NUMBER) {
        needsSelection = !number
      } else if (propType === PropType.NODE) {
        needsSelection = !valueId
      } else if (propType === PropType.BOOLEAN) {
        needsSelection = boolean === null;
      } else if (propType === PropType.DATETIME) {
        needsSelection = datetime == null;
      }
      
      if (needsSelection === oldNeedsSelection) return;

      dispatch({
        type: "updateState",
        payload:{
          needsSelection,
        }
      })
    },
    [
      dispatch,
      stateRef,
      parentNode,
      number,
      valueId,
      boolean,
      datetime,
    ]
  )
}
