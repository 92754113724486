
import React from "react";
import { observer } from "mobx-react-lite";

import clsx from "clsx";
import {SelectProvider} from "common/contexts/SelectContext";
import {Provider as ViewportProvider} from "common/contexts/useViewport";
import useViewport from 'common/contexts/useViewport';
import { PersistProvider } from "controllers/usePersist";

import LaunchPad, {DesktopNav} from "components/LaunchPad";
import Editor from "components/editor";
import useRoot, {RootProvider, VIEW} from "controllers/useRoot";
import InfoView from "components/InfoView";
import OrphansView from "components/Orphans";
import NavActionBar from "components/NavActionBar";
import { OrphanProvider } from "controllers/useOrphans";

export default function WrappedApp(){
  return (
    <ViewportProvider>
      <SelectProvider>
        <PersistProvider>
          <RootProvider>
            <OrphanProvider>
              <EditorApp/>
            </OrphanProvider>
          </RootProvider>
        </PersistProvider>
      </SelectProvider>
    </ViewportProvider>
  )
}

function EditorApp(){
  const {isMobile} = useViewport();

  const viewContainerCls = clsx("view-container", isMobile && "is-mobile")
  const windowCls = clsx("view-window")

  return (
    <div className={viewContainerCls}>
      <div className={windowCls}>
        <div className="view-window__background">
          <RootRouter/>
        </div>
      </div>
      {isMobile ? (
        <NavActionBar/>
      ): (
        <DesktopNav/>
      )}
    </div>
  )
}


const  RootRouter = observer(() => {
  const {view, nodeId} = useRoot();
  if (view === VIEW.EDITOR) return <Editor key={nodeId} nodeId={nodeId}/>
  if (view === VIEW.INFO) return <InfoView/>
  if (view === VIEW.ORPHANS) return <OrphansView/>
  return <LaunchPad/>
})
