import React from "react"
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { observer } from "mobx-react-lite"

import useAuth from "controllers/useAuth";
import useErrors from "controllers/useErrors";


export default observer(function AuthPage(){
  const {authGoogle} = useAuth()
  const errorStore = useErrors();

  function onSuccess(credentialResponse:CredentialResponse){
    authGoogle(credentialResponse.credential);
  }

  function onError(){
    console.log('Login Failed');
  }

  if (!errorStore.showLogin) return null;

  return (
    <div className="overlay">
      <div className="content splash">
        <h1>BrainSpace</h1>
        <h2>
          Organize your mind.
        </h2>
        <div className="button-container">
        <GoogleLogin
          onSuccess={onSuccess}
          onError={onError}
          size="large"
        />
        </div>
        <p>
          Try the beta-release right now. No payment required.
        </p>
        <p>
          Questions?  <a href="mailto:timmerop@gmail.com">Email me.</a>
        </p>
      </div>
    </div>
  )
})