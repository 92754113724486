import React from "react";
import { observer } from "mobx-react-lite";

import { SelectDropDown, option } from "common/components/Selectors";
import useEditor from "../useEditor";
import { NodeKind, PropType } from "components/graphql";


const KIND_OPTIONS = [
  option("None",  ""),
  option("Another Node", PropType.NODE),
  option("Number", PropType.NUMBER),
  option("Checkbox", PropType.BOOLEAN),
  option("Date", PropType.DATETIME),
]

const KindSelect = observer(function(){
    const {editor} = useEditor();
    const {rootNodeId, updateKind} = editor;

    const node = editor.persist.getNodeMaybe(rootNodeId)

    console.log("KindSelect", {node})
    if (!node) return null;

    let {kind, propType} = node;
    const selected = kind === NodeKind.PROP ? propType : "";

    function handleKind(propType:PropType | ""){
      let kind  = NodeKind.PROP;
      if (propType === "") {
        kind = NodeKind.NODE;
        propType = PropType.NODE;
      }
      updateKind({
        nodeId:rootNodeId || "",
        kind,
        propType,
      })
    }

    return (
      <>
        <SelectDropDown
          value={selected}
          setValue={handleKind}
          options={KIND_OPTIONS}
          />
      </>
    )
})
export default KindSelect;