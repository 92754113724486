import React from 'react';

export function EllipsisIcon() {
  return (
    <svg
      className='icon'
      width="24"
      height="24"
      viewBox="2 -4.5 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="12" r="4" fill="currentColor" />
      <circle cx="14" cy="12" r="4" fill="currentColor" />
      <circle cx="24" cy="12" r="4" fill="currentColor" />
    </svg>
  );
}
