import { getDebugLog } from "common/utils";
import {useEffect, useRef} from "react";

const log = getDebugLog(false, "DOM:");

export function useSetFocus(ref, setFocus){
    useEffect(() => {
        if (setFocus) {
            // set position to the end of input
            setCursorPosition(ref.current, -1);
            ref.current.focus();
        } else {
            ref.current.blur();
        }
    }, [ref, setFocus]);
}

const keyNames = {
    "/": "Slash",
    ">": "GreaterThan",
}

export function useKeyDown(ref, commands, name=""){
    
    /**
     * Collect all of the properties of this event
     * to find matching command names
     * 
     * @param {*} event 
     * @returns 
     */
    function getNameParts(event){
        const {key, altKey, metaKey, shiftKey} = event;
        // console.log("key", key, altKey, ref, event);
        const nameParts = []
        
        nameParts.push(keyNames[key] || key)
        
        if (altKey) nameParts.push("Alt")
        
        if (shiftKey) nameParts.push("Shift")
        
        if (metaKey) nameParts.push("Meta")
        
        
        // only check isEmpty if it would match a command
        const [checkEmpty] = commandExists([...nameParts, "Empty"])
        // define isEmpty because isStart checks for it
        if (checkEmpty){
            const hasText = !!ref.current.value;
            log(name, "hasText", hasText, ref.current.value);
            if (!hasText) nameParts.push("Empty")
        }

        // only check isStart if it would match a command
        // since it can mess with selection
        const [checkStart] = commandExists([...nameParts, "Start"])
        log("checkStart", checkStart, nameParts)
        if (checkStart){
            // only check isStart for onStartKeys to prevent weird behavior
            // with non-input selections (like buttons)

            const isStart = ref.current.selectionStart === 0 && ref.current.selectionEnd === 0;
            log("isStart", isStart, ref.current.selectionStart)
            if (isStart) nameParts.push("Start")
        }

        log("nameParts", nameParts);
        return nameParts
    }

    /**
     * checks if the current given name parts are a valid command
     * @param {*} nameParts 
     * @returns 
     */
    function commandExists(nameParts){
        // create the function name
        const commandName = "on" + nameParts.join(""); 
        // check to see if it's defined
        const exists = typeof commands[commandName] == 'function'
        log("commandExists", commandName, exists, commands)
        return [exists, commandName]
    }

    /**
     * Loops true all the name parts until it finds a matching command
     * 
     * @param {*} nameParts 
     * @returns 
     */
    function findCommand(nameParts){
        // if the namePart array is empty, there is no function
        if (nameParts.length < 1) return;

        const [exists, commandName] = commandExists(nameParts)
        if (exists) return commandName
        // if not defined remove the last part of the name and try again
        nameParts.pop();
        return findCommand(nameParts);
    }

    /**
     * trigger a command if it matches the event's properties 
     * 
     * @param {*} event 
     * @returns 
     */
    function handleKeyDown(event) {
        const nameParts = getNameParts(event);
        const commandName = findCommand(nameParts)
        log("onKey", commandName, commands);
        if (!commandName) {
            log(name, "no command found");
            return
        };
        event.preventDefault()
        event.stopPropagation()
        commands[commandName](event)
    }

    /**
     * The listener for keydown events for this ref.
     */
    useEffect(()=>{
        if (!ref || !ref.current) return

        ref.current.addEventListener("keydown", handleKeyDown, false)
        return function cleanup(){
            if (!ref.current) return;
            ref.current.removeEventListener("keydown", handleKeyDown, false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

export function getCursorPosition() {
    var sel = document.getSelection();
    // save the original range
    let range = sel.getRangeAt(0)
    sel.modify("extend", "backward", "paragraphboundary");
    var pos = sel.toString().length;
    const newLocal = sel.anchorNode !== undefined;
    if(newLocal) sel.collapseToEnd();
    // restore the original range
    sel.removeAllRanges();
    sel.addRange(range);
    return pos;
} 

export function setCursorPosition(textarea, position){
    try{
        if (position === -1) position = textarea.value.length;
        log("setCursorPosition", position);
        textarea.selectionStart = position;
        textarea.selectionEnd = position;
        textarea.focus();
    } catch(e) {
        log("setCursorPosition error", e);
    }
}

export function useWindowHotkeys(commands){
    const windowRef = useRef(window);
    useKeyDown(windowRef, commands);
}

