import { 
  ApolloClient,
  ApolloLink,
  split,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
// import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws'
import {csrftoken, GRAPHQL_BACKEND_URL, GRAPHQL_WEBSOCKET_URL } from "common/urls"


const httpLink = new HttpLink({
  uri: GRAPHQL_BACKEND_URL,
  headers: {
    "X-CSRFToken": csrftoken,
  },
  fetchOptions: {
    credentials: "include",
  },
});

const wsClient = new SubscriptionClient(GRAPHQL_WEBSOCKET_URL, {
  reconnect: true,
  connectionParams: {
    headers: {
      "X-CSRFToken": csrftoken,
    },
    fetchOptions: {
      credentials: "include",
    },
  },
})


function dispatchConnectionEvent(detail){
  const event = new CustomEvent("websocketConnect", {detail});
  window.dispatchEvent(event)
}

wsClient.onConnected(() => dispatchConnectionEvent("Connected"))
wsClient.onDisconnected(() => dispatchConnectionEvent("Disconnected"))
wsClient.onReconnecting(() => dispatchConnectionEvent("Reconnecting"))
wsClient.onReconnected(() => dispatchConnectionEvent("Connected"))

const wsLink = new WebSocketLink(wsClient);

document.addEventListener('visibilitychange', function() {
  if (document.hidden) return 
  // try to reconnect if the page is visible again
  wsLink.subscriptionClient.connect();
});

const terminatingLink = split(
  ({ query }) => {
    return true;
    // const { kind, operation } = getMainDefinition(query);
    // use websockets for all actions
    // return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const link = ApolloLink.from([terminatingLink]);

const cache = new InMemoryCache({addTypename:false});

const apollo = new ApolloClient({
  link,
  cache,
});

export { apollo };
