import React from "react"

import View from "./Editor"
import {EditorViewProvider} from "./useEditor"

interface Props {
  nodeId?:string,
}

export default function Editor(props:Props){
  console.log("Editor", props)
  return (
    <EditorViewProvider {...props}>
      <View/>
    </EditorViewProvider>
  )
}