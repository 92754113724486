import { makeObservable, observable, action } from "mobx"
import { gql } from '@apollo/client';

import { PersistState, persist } from "controllers/usePersist"
import {selectionStore} from "common/contexts/SelectContext"
import {errorStore, ErrorStore} from "controllers/useErrors"

import { apollo } from "common/apollo"

export enum VIEW {
  MAIN_MENU = "main_menu",
  EDITOR = "editor",
  INFO = "info",
  ORPHANS = "inbox",
}

export default class RootStore {
  persist: PersistState
  errorStore: ErrorStore

  @observable searchTerm = ""
  @observable nodeId?: string
  @observable view: VIEW = VIEW.MAIN_MENU
  @observable selectRoot: boolean = true

  constructor() {
    this.persist = persist;
    this.errorStore = errorStore;
    makeObservable(this)
    this.checkUrl();
    this.listenToPopState();
  }

  @action checkUrl = () => {
    const path = window.location.pathname;
    this.setNodeId(path.split('/').pop());
  }

  listenToPopState = () => {
    window.addEventListener('popstate', this.checkUrl);
  }

  @action setNodeId(nodeId?:string) {
    if (nodeId === VIEW.ORPHANS) {
      this.nodeId = undefined
      this.view = VIEW.ORPHANS
    }
    else if (nodeId === VIEW.INFO) {
      this.nodeId = undefined
      this.view = VIEW.INFO
    }
    else if (!nodeId){
      this.view = VIEW.MAIN_MENU
      this.nodeId = undefined
      this.getSearchTerm();
    } else {
      this.nodeId = nodeId
      this.view = VIEW.EDITOR
    }
  }

  @action getSearchTerm = () => {
    const query = new URLSearchParams(window.location.search);
    this.searchTerm = query.get("q") || "";
  }
  
  @action setSearchTerm = (text:string) => {
    this.searchTerm = text
    const search = text ? `/?q=${text}` : "/"
    console.log("setSearchTerm", text, search)
    window.history.replaceState({}, "", search);
  }

  @action openOrphan = () => {
    this.view = VIEW.ORPHANS
    window.history.pushState({}, "", `/${VIEW.ORPHANS}`);
  }

  @action openInfo = () => {
    this.view = VIEW.INFO
    window.history.pushState({}, "", `/${VIEW.INFO}`);
  }

  @action openLaunchPad = () => {
    if (this.view === VIEW.MAIN_MENU) {
      selectionStore.setSelection("search");
      return
    };
    this.nodeId = undefined
    this.view = VIEW.MAIN_MENU
    this.selectRoot = false
    window.history.pushState({}, "", "/");
  }


  @action setRoot = (nodeId:string, options = {selectRoot:true}) => {
    const {selectRoot} = options
    this.persist.updateObjects({
      nodes: [{
        id: nodeId,
        lastRootAt: this.persist.now(),
      }],
    });
    this.view = VIEW.EDITOR
    this.nodeId = nodeId
    this.selectRoot = selectRoot
    window.history.pushState({}, "", `/${nodeId}`);
    if (selectRoot) selectionStore.setSelection("root");
  }

  @action createNewPage = () => {
    const newId = this.persist.newId();
    this.setRoot(newId, {selectRoot:true})
  }

  @action openRandomPage = () => {
    apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query NodeRandom{
          nodeRandom {
            success
            errorMessage
            errorData
            node {
              id
            }
          }
        }
      `
    }).then((response)=>{
      const res = response.data.nodeRandom;
      if (this.errorStore.hasResponseError(res)) return;
      const {node} = res;
      this.setRoot(node.id, {selectRoot:false});
    })
  }
  
}