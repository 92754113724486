import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { BondKind, Node, NodeKind} from "components/graphql"
import { dateDisplay } from "common/utils/dates";

import {PROP_TYPES} from "components/editor/constants";
import { PinIcon } from "common/icons";
import { truncate } from "common/utils";
import usePersist from "controllers/usePersist";


const ParentContainer = styled.div`
  margin-left: var(--size-spacing-xs);
  display: inline-block;
`

interface Props {
  nodeId:string,
}

const NodePageDisplay = observer(function(props:Props){
  const {nodeId} = props;
  const {node, parentNodeText, isPinned} = useHelper(nodeId);
  return (
    <>
      {isPinned && (
        <div>
          <PinIcon/>
        </div>
      )}
      <div>
        <div>
          <b>{node.text}</b>
          {parentNodeText && (
            <ParentContainer>({parentNodeText})</ParentContainer>
          )}
        </div>
      </div>
    </>
  )
})
export default NodePageDisplay

/**
 * Returns the text to display for a nodes parent in the node select ui
 * 
 * @param {*} node 
 * @returns 
 */
function useHelper(nodeId:string):{node:Node, parentNodeText:string, isPinned:boolean}{
  const {persist} = usePersist();
  const node = persist.getNode(nodeId);

  // check if the node is pinned
  const isPinned = persist.getIsPinned(nodeId);
  
  let text = "";
  const parentBonds = persist.getParentBonds(nodeId, BondKind.CONTENT);
  if (!parentBonds.length) return {node, parentNodeText:text, isPinned};
  const bond = parentBonds[0];
  const bondParent = persist.getNode(bond.parentId);
  
  // add the first parent's text
  text += truncate(bondParent.text);
  if (bondParent.kind === NodeKind.PROP) {
    const {propType} = bondParent;
    text += ": "
    if (propType === PROP_TYPES.BOOLEAN){   
      text += bond.boolean ? "True" : "False"
    }
    else if (propType === PROP_TYPES.NUMBER){
      text += bond.number || "Not Set";
    }
    else if (propType === PROP_TYPES.NODE){
      const valueNode = persist.getNode(bond.valueId!);
      text += valueNode?.text || "Not Set";
    }
    else if (propType === PROP_TYPES.DATETIME){
      if (bond.datetime) {
        let date = new Date(bond.datetime);
        text += dateDisplay(date);
      }
      else {
        text += "Not Set";
      }
    }
  } 

  
  return {node, parentNodeText:text, isPinned};
}
