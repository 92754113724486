import {useCallback} from "react"

import usePersist from "controllers/usePersist";
import { ContextValue } from "../types";


export default function useRemoveAutoSubgroup(context: ContextValue){
  const {persist} = usePersist();

  context.removeAutoSubgroup = useCallback(
    (bondId:string) => {
      const existingBond = persist.getBond(bondId);
      existingBond.archivedAt = persist.now();
      persist.updateObjects({bonds:[existingBond]})
    },
    [persist]
  )
}