import { useCallback } from 'react';


import { ContextValue, UpdateAutoPropValueParams } from '../types';
import usePersist, {BondPartial} from 'controllers/usePersist';

export default function useUpdateAutoPropValue(context:ContextValue){
  const {persist} = usePersist();
  
  context.updateAutoPropValue = useCallback(
    (params:UpdateAutoPropValueParams)=>{
      const {
        bondId,
        valueId,
        datetime,
        number,
        boolean,
      } = params;

      const bonds:BondPartial[] = [
        {
          id: bondId,
          valueId,
          datetime,
          number,
          boolean,
        }
      ]
      
      persist.updateObjects({bonds})
    },
    [persist]
  )
}