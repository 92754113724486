import { useEffect, useState } from 'react';
import {debounce} from 'lodash';

function useActiveElement() {
    const [activeElement, setActiveElement] = useState(document.activeElement);

    useEffect(() => {
        const handleFocus = debounce(() => setActiveElement(document.activeElement), 100, {trailing:true, leading:false});
        
        handleFocus();

        document.addEventListener('focusin', handleFocus);
        document.addEventListener('focusout', handleFocus);
        document.addEventListener('visibilitychange', handleFocus);

        return () => {
            document.removeEventListener('focusin', handleFocus);
            document.removeEventListener('focusout', handleFocus);
            document.removeEventListener('visibilitychange', handleFocus);
        };
    }, []);

    return activeElement as HTMLElement;
}

export default useActiveElement;