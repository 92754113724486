/*
This is a reusable text input for searching for a node
*/

import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { getDebugLog } from "common/utils";
import { useSetFocus, useKeyDown } from "common/hooks/dom";
import { Node } from "components/graphql"
import LineInput from "components/LineInput"
import { ClickOutsideContainer } from "components/ClickOutsideContainer/ClickOutsideContainer";
import NodePageDisplay from "components/NodePageDisplay";

import useNodeLookup, {Provider} from "./useNodeLookup"
import {ProviderStateProps, ProviderFunctionProps}  from "./useNodeLookup/types"
import "./node-lookup.scss"
import { ArrowIcon } from "common/icons";
import Button from "components/Button";
import ButtonNavBar from "components/ButtonNavBar";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = getDebugLog(false, "NodeSearch");

interface NodeSelectInputProps extends ProviderFunctionProps, ProviderStateProps {
  children?:React.ReactNode,
}

export default function NodeLookupInput(props:NodeSelectInputProps){
  return (
    <Provider {...props}>
      <Container>
        <SearchInput/>
        <NodeSuggestions/>
        <ZeroState>
          {props.children}
        </ZeroState>
      </Container>
    </Provider>
  )
}

function Container(props:{children:React.ReactNode}){
  const {children} = props;

  const { close, showSuggestions} = useNodeLookup();

  const classes = clsx(
    "node-select-input",
    showSuggestions && "is-open",
  )

  if (!showSuggestions) return <div className="node-select-input">{children}</div>

  return (
    <ClickOutsideContainer
      onClose={close}
      className={classes}
    >
      {children}
    </ClickOutsideContainer>
  )
}

function SearchInput(){
  const {
    showSuggestions,
    activeSuggestion,
    createNodeWithText,
    searchNodes,
    setActiveSuggestion,
    onBackspaceEmpty,
    dispatch,
    placeholder,
    selectionId,
    touched,
    nodeText,
    onBlur,
    minWidth,
  } = useNodeLookup();

  function search(){
    setTimeout(()=>{
      dispatch({
        type:"updateState",
        payload: {
          showSuggestions:true
        }
      })
    }, 150)
  }

  function createNode(event:React.KeyboardEvent<HTMLTextAreaElement>){
    const input = event.target as HTMLTextAreaElement
    const text = input.value
    createNodeWithText(text)
  }

  const inputRef = useRef<HTMLTextAreaElement>(null!);

  // only autofocus if there is no selectionId defined
  const autoFocus = showSuggestions && !selectionId;

  // only focus if there is no active suggestion
  useEffect(()=>{
    if (!showSuggestions) return;
    if (activeSuggestion !== -1) return;
    // prevent autofocus when the selectionId is set but the user hasn't touched the input 
    if (selectionId && !touched) return;
    log("focus search input")
    inputRef.current.focus()
  },
  [inputRef, activeSuggestion, showSuggestions, touched, selectionId])
  
  log("autoFocus", {autoFocus, selectionId, showSuggestions})

  // prevent the default text from changing
  const [defaultText] = useState(nodeText);

  const onClear = (e:React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    searchNodes("")
    setActiveSuggestion(-1)
  }

  return (
    <LineInput
      text={defaultText}
      minWidth={minWidth}
      actionBarLabel="search"
      isSelectable
      inputRef={inputRef}
      selectionId={selectionId}
      autoFocus={autoFocus}
      placeholder={placeholder}
      onTextChange={searchNodes}
      onEnter={createNode}
      // prevent anything from happening on arrow up
      onArrowUp={()=>{}}
      onArrowDown={() => setActiveSuggestion(0)}
      onBackspaceEmpty={onBackspaceEmpty}
      onBackspaceShiftEmpty={onBackspaceEmpty}
      onFocus={search}
      onClear={onClear}
      onBlur={onBlur}
    />
  )
}

function NodeSuggestions(){
  const {showSuggestions, nodes, paginate, hasMore, loadMore} = useNodeLookup();
  
  if (!showSuggestions) return null
  if (!nodes || !nodes.length) return null
  return (
    <div className="node-select-options">
      {nodes.map((node:Node, idx:number) => (
        <NodeSuggestion
          key={`${node.id}-${idx}`}
          node={node}
          idx={idx}
        />
      ))}
      {paginate && hasMore && (
        <ButtonNavBar>
          <Button
            $fullWidth
            tooltip="Load more results"
            onMouseEnter={loadMore}
            onClick={loadMore}
            >
              Load More
          </Button>
        </ButtonNavBar>
      )}
    </div>
  )
}

interface NodeSuggestionParams {
  node:Node,
  idx:number,
}

function NodeSuggestion(props:NodeSuggestionParams){
  const {node, idx} = props;
  
  const {
    activeSuggestion,
    setActiveSuggestion,
    onSelectNode,
  } = useNodeLookup();
  
  const isActive = activeSuggestion === idx;

  const ref = useRef(null)
    
  const onArrowUp = () => {
    setActiveSuggestion(idx - 1)
  }
  const onArrowDown = () => {
    setActiveSuggestion(idx + 1)
  }

  function onEnter(){
    onSelectNode(node, false)
  }

  useKeyDown(ref, {onEnter, onArrowDown, onArrowUp})
  useSetFocus(ref, isActive)
  
  function handleClick(){
    onSelectNode(node, false)
  }


  return (
    <div
      ref={ref}
      className={clsx(
        "node-select-option",
        isActive && "selected",
      )}
      tabIndex={-1} // needed to set focus
      onClick={handleClick}
    >
      {isActive && (<div><ArrowIcon/></div>)}
      <NodePageDisplay nodeId={node.id}/>
    </div>
  )
}

function ZeroState(props: {
  children:React.ReactNode,
}){
  const {showZeroState, nodes, loading, nodeText} = useNodeLookup();

  // only show if showZeroState is true
  if (!showZeroState) return null;

  // only show after loading
  if (loading) return null;

  // only show if the users isn't searching
  if (nodeText) return null;

  // only show if there are no nodes
  if (nodes && nodes.length) return null;

  return (
    <div className="node-select-zero-state">
      {props.children}
    </div>
  )
}