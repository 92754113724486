import { State } from "../types";

/**
 * Generic State updater
 */
 export function updateState(state:State, payload:Partial<State>): State {
  return {
    ...state,
    ...payload,
  }
}