import { useCallback, useEffect, useMemo, useState } from "react";
import { gql } from '@apollo/client';
import {debounce} from 'lodash';


import useErrors from "controllers/useErrors";
import {FRAG_NODE_WITH_PARENTS} from "components/graphql"
import { useLazyQuery } from "common/hooks/useLazyQuery";
import { ContextValue } from "../types";
import { getDebugLog } from "common";
import usePersist from "controllers/usePersist";
import useConnection from "controllers/useConnection";
import { loadingStore } from "controllers/useLoading";

const log = getDebugLog(false, "useSearchNodes");


export default function useSearchNodes(context:ContextValue){
  const {dispatch, stateRef, state} = context;
  const {hasResponseError} = useErrors()
  const {persist} = usePersist();

  context.nodes = useMemo(()=>persist.getNodes(state.nodeIds), [persist, state.nodeIds]);

  const [query] = useLazyQuery(GQL, {
    fetchPolicy: "no-cache"
  })

  const [lastText, setLastText] = useState<string>(null!);
  
  const {isOffline} = useConnection();

  const searchNodes = useCallback(
    (text:string) => {
      const {
        nodeId,
        propId,
        filterNodeId,
        orderBy,
        pageSize = 10,
      } = stateRef.current

      let term = text.trim();
      
      log("searchNodes", term, lastText)
      if (lastText === term) return;

      setLastText(term);
      
      if (isOffline) {
        // if offline, search the local cache
        const nodes = persist.searchNodes({term, pageSize});
        dispatch({
          type: "updateSearch",
          payload: {
            text,
            nodes,
          },
        })
        return
      }

      // if online, query the server
      const variables = {
        nodeId,
        propId,
        filterNodeId,
        term,
        orderBy,
        pageSize,
        pageNumber:1,
      }
      loadingStore.startLoading();
      query({variables}).then(({data}) => {
        loadingStore.stopLoading();
        if (hasResponseError(data.nodeSearch)) return;
        const {nodes} = data.nodeSearch;
        
        persist.storeObjects({nodes});
        dispatch({
          type: "updateSearch",
          payload: {
            text,
            nodes,
          },
        })
      })
    },
    [
      dispatch,
      stateRef,
      query,
      hasResponseError,
      lastText,
      setLastText,
      persist,
      isOffline,
    ]
  )

  const searchDebounce = debounce(searchNodes, 300);

  context.searchNodes = useCallback(
    (text:string) => {
      // search immediately if text is empty
      if (!text) searchNodes("");
      else searchDebounce(text);
    }
    , [searchDebounce, searchNodes]
  )

  useEffect(()=>{
    if (!state.showSuggestions) return;
    context.searchNodes(stateRef.current.nodeText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showSuggestions])


  // add a method to load more nodes with the current search term
  context.loadMore = useCallback(
    () => {
      const {
        nodeText,
        nodeId,
        propId,
        filterNodeId,
        orderBy,
        pageSize = 10,
        pageNumber,
      } = stateRef.current

      const variables = {
        nodeId,
        propId,
        filterNodeId,
        term: nodeText,
        orderBy,
        pageSize,
        pageNumber: pageNumber + 1,
      }

      query({variables}).then(({data}) => {
        if (hasResponseError(data.nodeSearch)) return;
        const {nodes} = data.nodeSearch;
        persist.storeObjects({nodes});
        dispatch({
          type: "loadMore",
          payload: {
            nodes,
          },
        })
      })
    }
    , [dispatch, stateRef, query, hasResponseError, persist]
  )

}

export const GQL = gql`
  query NodeSearch(
    $term:String,
    $nodeId:ID,
    $propId:ID,
    $filterNodeId:ID,
    $orderBy:String,
    $pageSize:Int,
    $pageNumber:Int,
  ) {
    nodeSearch(
      term: $term,
      nodeId: $nodeId,
      propId: $propId,
      filterNodeId: $filterNodeId,
      orderBy: $orderBy,
      pageSize:$pageSize,
      pageNumber:$pageNumber,
    ) {
      success
      errorMessage
      errorData
      nodes {
        ...NodeWithParents
      }
    }
  }
  ${FRAG_NODE_WITH_PARENTS}
`;