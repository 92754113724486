import { makeObservable, observable, action, computed } from "mobx"
import { gql } from '@apollo/client';

import { ResObj } from "common/hooks/useHandlerErrors";
import {AnyNode, NodeWithParents, FRAG_NODE_WITH_PARENTS} from "components/graphql"
import { persist, PersistState } from "controllers/usePersist"
import {errorStore, ErrorStore} from "controllers/useErrors"
import { apollo } from "common/apollo";


interface NodeOrphansResponse extends ResObj {
  nodes:NodeWithParents[],
  total:number,
}

interface Data {
  nodeOrphans:NodeOrphansResponse
}

export default class OrphanStore {
  persist: PersistState
  errorStore: ErrorStore
  @observable nodeIds: string[] = []
  @observable total = 0
  @observable pageNumber = 1
  @observable pageSize = 10
  
  constructor() {
      this.persist = persist;
      this.errorStore = errorStore;
      makeObservable(this)
  }

  @computed get nodes():AnyNode[] {
    const nodes:AnyNode[] = []
    this.nodeIds.forEach((id) => {
      const node = this.persist.getNode(id)
      if (!node) return
      // if the text is empty, don't show it
      if (node.text === "") return
      // if the node is no longer an orphan, don't show it
      if (!node.isOrphan) return
      nodes.push(node)
    })
    return nodes
  }

  @action getOrphanNodes = () => {
    apollo.query({
      query: gql`
        ${FRAG_NODE_WITH_PARENTS}
        query NodeOrphans($pageNumber: Int!, $pageSize: Int!) {
          nodeOrphans(pageNumber: $pageNumber, pageSize: $pageSize) {
            success
            errorMessage
            errorData
            nodes {
              ...NodeWithParents
            }
            total
          }
        }
      `,
      variables: {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      },
      fetchPolicy: "no-cache",
    }).then((response:{data:Data})=>{
      const res = response.data.nodeOrphans;
      if (this.errorStore.hasResponseError(res)) return;
      const {
        nodes,
        total,
      } = res;
      this.persist.storeObjects({nodes})
      if (this.pageNumber === 1) {
        this.nodeIds = []
      }
      const newNodeIds =  nodes.map((node:NodeWithParents) => node.id)
      // add the new nodeIds to the existing nodeIds
      this.nodeIds = this.nodeIds.concat(newNodeIds)
      this.total = total
    })
  }

  @computed get hasNextPage():boolean {
    return this.total > (this.pageNumber * this.pageSize)
  }

  @action getNextPage = () => {
    this.pageNumber++
    this.getOrphanNodes()
  }

  @action ignoreOrphan = (nodeId:string) => {
    this.persist.updateObjects({
      nodes: [{
        id: nodeId,
        requiresParent: false,
        isOrphan: false,
      }],
    });
    this.nodeIds = this.nodeIds.filter((id) => id !== nodeId)
  }
}