import React from "react";
import { observer } from "mobx-react-lite";

import useRoot, {VIEW} from "controllers/useRoot";

/**
 * A full screen overlay that displays
 * information about the app.
 */
const InfoView = observer(() =>{
  const {view} = useRoot();
  if (view !== VIEW.INFO) return null;
  
  return (
    <div className="">
      <div className="content">
        <h1>About</h1>
        <InfoBlock lines={INFO.WHY}/>
        <h2>Main Menu</h2>
        <InfoBlock lines={INFO.MENU}/>
        <h2>Editor</h2>
        <InfoBlock lines={INFO.EDITOR}/>
        <h2>Settings Page</h2>
        <InfoBlock lines={INFO.METAPAGE}/>
      </div>
    </div>  
  );
})
export default InfoView;

interface InfoBlockProps {
  lines: string[]
}

function InfoBlock(props:InfoBlockProps){
  return (
    <>
      {props.lines.map((line, i)=>(
        <div key={i}>{line}</div>
      ))}
    </>
  )
}

const INFO = {
  WHY : [
    "Our brains love lists, and they love making connections.",
    "I made this app is to help our brains collect and access information.",
    "There is no up or down, there is no left or right, there is only connections.",
    "Simply put, this app is a list of lists.",
    "For example, I can make a list called 'Books', then add a book I'm are reading to the list.",
    "I can also add that book to a list called 'Non-fiction' and another list called 'Favorites'.",
    "I can also create a list under my book with my notes about about the book.",
    "I might additionally add my notes to the lists 'Quotes', 'Aha!', or whatever else I might want to come back to later.",
    "I might have additional thoughts about these notes, and on and on.",
    "Then when I want to see all my Aha! moments, they are all in one place.",
    "I use this app for as a journal, todo list, as a place to store my ideas, and meeting notes, and more.",
    "At this point it's become an extension of my brain.",
    "I hope it helps you as much as it has helped me.",
    "- Timmer",
  ],
  MENU: [
    "This UI can be cryptic, so here are some less obvious features.",
    "The button the top left opens the menu.",
    "The menu displays the most recently viewed thoughts.",
    "Typing in the input will search all of your notes.",
    "Clicking on a note will bring you to the editor.",
    "Clicking enter will create a new note with the input as the text.",
    "+ - This button creates a new node.",
  ],
  EDITOR: [
    "The editor is where you can create and organize your notes.",
    "Command 1: Open the menu.",
    "Command 2: Create a new thought.",
    "Filters +: Filter the list of thoughts by another list.",
    "Settings Button: Opens up the settings page for top-level node.",
    "Node/Prop: This lets you change the node into a prop or vice versa.",
    "Line Buttons:",
    "> button: Show the nested nodes of this nodes.",
    "+ : Add another parent to the current line.",
    "* : Open this line in the editor.",
    "... button: Show all the hidden parents of this line.",
    "Line Hotkeys:",
    "Enter - Create a new line.",
    "Shift Enter - Add a nested line.",
    "Tab - Nest a line under the previous line.",
    "Shift Tab - Un-nest a line under the previous line.",
    "Alt Up/Down - Move a line up or down.",
    "Backspace - Delete a line (If the cursor is at the beginning of the line).",
    "/ - Replace the current line with a link to an existing thought.",
    "> - This concept is a little tricky, but it's allows you to create a subgroup of another list.",
  ],
  METAPAGE: [
    "The settings page is where you can change the settings for a node.",
    "Node Type - This allows you to define a value type that should be store with this label",
    "For example, I give the node `Rating` the type `number`. Now each item tagged with `Rating` will be able to enter a number along with it.",
    "+ Auto Parent - This allows you set define other notes that will automatically be added to items of this list.",
    "For example, I give the node `Game Ideas` the auto parent `Ideas`, and `Story Ideas` the auto parent `Ideas`. When I label something `Game Ideas` or `Story Ideas` it will also be able be labeled `Ideas` as well.",
    "+ Auto Subgroups - This creates the defined subgroups for items of this list.",
    "For example, I add an auto subgroup called 'Characters' for the list 'Books'. Now each item tagged with 'Books' will have a subgroup called 'Characters'.",
  ],
}