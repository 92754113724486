import { ContextValue } from "../types";
import useClose from "./useClose";

import useCreateNodeWithText from "./useCreateNewNode";
import useSearchNodes from "./useSearchNodes";
import useSetActiveSuggestion from "./useSetActiveSuggestion";

const methodHooks: {(context: ContextValue): void}[] = [
  useCreateNodeWithText,
  useSearchNodes,
  useSetActiveSuggestion,
  useClose,
];

export default methodHooks