import { useCallback } from "react";


import { ContextValue, View} from "../types";

export default function useSelect(context:ContextValue):void{
  const {dispatch, onDatetimeSelect} = context;
  
  const setDatetime = useCallback(
    (datetime:string | null) => {
      dispatch({
        type: "updateState",
        payload: {
          view: View.DISPLAY,
        },
      })
      onDatetimeSelect(datetime)
    },
    [onDatetimeSelect, dispatch]
  )

  context.select = useCallback(
    (datetime: Date | null) => {
      let value = null;
      if (datetime) {
        datetime.setHours(0, 0, 0, 0);
        value = datetime.toISOString();
      }
      setDatetime(value)
    },
    [setDatetime]
  )

  context.selectRelative = useCallback(
    (daysOffset:number) => {
      // get a new date object that is the current date + daysOffset
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const newDate = new Date(today.getTime() + daysOffset * 24 * 60 * 60 * 1000)
      setDatetime(newDate.toISOString())
    },
    [setDatetime]
  )
}
