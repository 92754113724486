import { useEffect, useCallback } from "react";

import { ContextValue } from "../types";


export default function useViewportListener(context:ContextValue){
  const {dispatch} = context

  const setViewport = useCallback(
    ()=>{
      const {
        height = 0, 
        width = 0,
        offsetTop = 0,
        offsetLeft  = 0,
      } = window.visualViewport || {};

      dispatch({
        type:"setViewport",
        payload:{
          height,
          width,
          offsetTop,
          offsetLeft,
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    // call on mount
    setViewport();

    window.visualViewport?.addEventListener("resize", setViewport);
    return () => {
      window.visualViewport?.removeEventListener("resize", setViewport);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
