import { action } from "mobx"

import { EditorStoreType, ReplaceParentParams } from "./storeTypes";
import { BondPartial } from "controllers/usePersist";
import { BondKind } from "components/graphql";

export const replaceParent = action(function(
  this:EditorStoreType,
  params: ReplaceParentParams,
){
  const {
    bondId,
    parentBondId,
    newParentBondId,
    indent=false,
  } = params;
  
  const bonds:BondPartial[] = []; 

  // the old bond node is the one we're moving
  const oldBond = this.persist.getBond(bondId);
  const {nodeId} = oldBond;

  // archive the old bond
  bonds.push(
    {
      id:bondId,
      archivedAt:this.persist.now()
    }
  )

  // if old bond was a subgroup also archive bond to the grandparent
  const oldParentBond = this.persist.getBond(parentBondId);
  if (oldParentBond?.kind === BondKind.SUBGROUP){
    const grandParentBond = this.persist.getBondFromNodes(oldParentBond.parentId, nodeId);
    if (grandParentBond){
      bonds.push(
        {
          id:grandParentBond.id,
          archivedAt:this.persist.now()
        }
      )
    }
  }

  // the node's new parent is the newParentBond.nodeId
  const newParentBond = this.persist.getBondMaybe(newParentBondId) ;
  const { nodeId:newParentId } = newParentBond || {nodeId:this.rootNodeId!};
  let position;
  // figure out the position of child for the new parent
  if (indent){
    // position the child as the last of the new parent
    position = this.persist.lastPosition()
  } else {
    // for un-indenting
    // the spot after the current parent
    position = this.getNewPosition(newParentBondId, oldBond.parentId, 1).position;
  }
  // create the new bond
  bonds.push(
    {
      nodeId,
      parentId:newParentId,
      position,
    }
  )

  // if the newParentBond is a subgroup, create a bond to it's parent as well.
  let subGroupParentId = null;
  if (newParentBond && newParentBond.kind === BondKind.SUBGROUP){
    subGroupParentId = newParentBond.parentId;
    bonds.push({
      parentId:subGroupParentId,
      nodeId,
    })
  };

  this.addBondsForFilters(bonds, nodeId);

  // persist the bond changes
  this.persist.updateObjects({bonds})

  // show the children of the new parent bond
  this.toggleChildren({
    bondId:newParentBondId,
    force:true,
  });

  // Set the new bond as selected
  this.setSelectedBond({
    nodeId,
    parentId:newParentId,
  })
})