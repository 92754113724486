import React from "react"
import { GoogleOAuthProvider } from '@react-oauth/google';

import useAuth, {Provider} from "controllers/useAuth"
import AuthPage from "./AuthPage"

export default useAuth

const CLIENT_ID = process.env.REACT_APP_OAUTH_GOOGLE_CLIENT_ID || "unknown"

type Props = {
  children: React.ReactNode,
}
export function AuthProvider(props:Props){
  
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <Provider>
        <AuthPage/>
        {props.children}
      </Provider>
    </GoogleOAuthProvider>
  )
}
