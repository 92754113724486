import React, { useState } from 'react';
import { observer } from "mobx-react-lite"

import useEditor from 'components/editor/useEditor';
import { ActionBarContainer, ActionButton } from 'components/ActionBar';
import { getDebugLog } from 'common';
import useRoot from 'controllers/useRoot';
import { MultiDirectionalIcon, SubItemIcon, TargetIcon, ChevronIcon, CloseIcon, TextSelectionIcon, SubGroupIcon, SearchIcon } from 'common/icons';


const log = getDebugLog(false, "NodeActionBar")

/**
 * The Action bar for the active editor line
 */
const NodeActionBar = observer(function (){

  const {setRoot} = useRoot();
  const {editor} = useEditor();
  const {
    selectedIsEmpty,
    selectedBond,
    convertSelectedToSubgroup,
    setShowSelectedLookup,
  } = editor;
  const {
    isRoot,
  } = selectedBond || {};

  const [showMove, setMove] = useState(false);


  function createSubLine(){
    if (!editor.selectedBond) return;
    const {
      bondId,
      nodeId,
    } = editor.selectedBond;
    editor.createChildBond({bondId, parentId:nodeId})
  }

  function focusLine(){
    if (!editor.selectedBond) return;
    const {
      nodeId,
    } = editor.selectedBond;
    setRoot(nodeId)
  }

  function selectText(){
    const activeElement = document.activeElement as HTMLTextAreaElement;
    if (activeElement && activeElement.select){
      activeElement.select();
    }
  }

  log("show node action bar")
  return (
    <ActionBarContainer
      label="node"
      mobileOnly={true}
    >
      
      {showMove ? (
        <>
          <MoveButton/>
          <ActionButton onClick={()=>setMove(false)}>
            <CloseIcon/>
            <div className="text">Done</div>
          </ActionButton>
        </>
      ) : (
        <>
          <ActionButton onClick={focusLine} disabled={isRoot}>
            <TargetIcon/>
            <div className="text">Focus</div>
          </ActionButton>

          <ActionButton onClick={()=>setMove(true)} disabled={isRoot}>
            <MultiDirectionalIcon/>
            <div className="text">Move</div>
          </ActionButton>

          <ActionButton 
            onClick={convertSelectedToSubgroup} 
            disabled={!selectedIsEmpty || isRoot}
          >
            <SubGroupIcon/>
            <div className="text">Sub Group</div>
          </ActionButton>

          <ActionButton
            onClick={()=>setShowSelectedLookup(true)}
            disabled={!selectedIsEmpty || isRoot}
          >
            <SearchIcon/>
            <div className="text">Existing</div>
          </ActionButton>

          <ActionButton 
            onClick={selectText}
            disabled={selectedIsEmpty}
          >
            <TextSelectionIcon/>
            <div className="text">Select</div>
          </ActionButton>

          <ActionButton 
            onClick={createSubLine}
            disabled={selectedIsEmpty}
          >
            <SubItemIcon/>
            <div className="text">Sub Node</div>
          </ActionButton>
        </>
      )}
    </ActionBarContainer>
  )
})

export default NodeActionBar;

function MoveButton(){
  const {editor} = useEditor();
  const {adjustPosition, replaceParent} = editor;

  function moveLineDown(){
    if (!editor.selectedBond) return;
    const {
      parentBondId,
      parentId,
      nodeId,
    } = editor.selectedBond;
    adjustPosition({parentBondId, parentId, nodeId, adjustment:2})
  }

  function moveLineUp(){
    if (!editor.selectedBond) return;
    const {
      parentBondId,
      parentId,
      nodeId,
    } = editor.selectedBond;
    adjustPosition({parentBondId, parentId, nodeId, adjustment:-1})
  }

  function indentLine(){
    if (!editor.selectedBond) return;
    const {
      parentBondId,
      bondId,
    } = editor.selectedBond;
    const prevSiblingBond = editor.getSiblingBond(bondId, parentBondId, -1);
    if (!prevSiblingBond) return;
    replaceParent({
      bondId,
      parentBondId,
      newParentBondId:prevSiblingBond.id,
      indent:true,
    })
  }

  function unindentLine(){
    if (!editor.selectedBond) return;
    const {
      bondId,
      parentBondId,
      grandBondId,
    } = editor.selectedBond;
    replaceParent({
      bondId,
      parentBondId,
      newParentBondId:grandBondId,
      indent:false,
    })
  }
  return (
    <>
      <ActionButton onClick={moveLineUp}>
        <ChevronIcon direction='up'/>
        <div className="text">Up</div>
      </ActionButton>
      <ActionButton onClick={moveLineDown}>
        <ChevronIcon direction="down"/>
        <div className="text">Down</div>
      </ActionButton>
      <ActionButton onClick={unindentLine}>
        <ChevronIcon direction="left"/>
        <div className="text">Unindent</div>
      </ActionButton>
      <ActionButton onClick={indentLine}>
        <ChevronIcon direction="right"/>
        <div className="text">Indent</div>
      </ActionButton>
    </>
  )
}