export function addListItem(item:string, list?:string[]):string[]{
  // create a list if the given doesn't exist
  list = list || []
  // if the item is not in the list, add it to the end 
  if (list.indexOf(item) < 0) {
    list.push(item);
  }
  return [...list]
}

export function removeListItem(item:string, list?:string[]):string[]{
  list = list || []
  // if the item is in the 
  const idx = list.indexOf(item);
  if ( idx > -1) list.splice(idx, 1);
  return [...list]
}

export function hasIntersectItems(arrA:string[], arrB:string[]):boolean{
  return !!arrA.find(x => arrB.includes(x));
}

export function isSameDict(dict1:object, dict2:object){
  return JSON.stringify(dict1) === JSON.stringify(dict2)
}