import styled from "styled-components";
import { MAX_MOBILE } from "constants/styles";

interface Props {
  $highlight?:boolean,
}

const ButtonNavBar = styled.div<Props>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.75rem;
  gap: var(--size-spacing-sm);
  button {
    flex-grow: 1;
    padding: var(--size-spacing-xs) var(--size-spacing-md) ;
    border-radius: var(--size-border-radius);
    border:None;
    background-color: var(
      ${(props:Props) => props.$highlight ? "--color-text-highlight" : "--color-text"
    });
    color: var(--color-background);

    @media (min-width: ${MAX_MOBILE}px) {
      flex-grow: 0;
      min-width: 5rem;
    }
  }
`;

export default ButtonNavBar;