import { useCallback } from "react";

import { BACKEND_URL } from "common/urls";

import { ContextValue } from "../types";


export default function useAuthGoogle(context: ContextValue) {
  context.authGoogle = useCallback(
    function authGoogle(credential:string|undefined){
      if (!credential) return;
      fetch(BACKEND_URL + "/api/auth/google/", {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        credentials: 'include',
        body: JSON.stringify({credential})
      }).then((res)=>{ 
        if (!res.ok) return;
        window.location.reload()
      })
    }, []);

}