import { State, View} from "./types";

export default function defaultState(overrides={}): State {
  return {
    view: View.DISPLAY,
    datetime: null,
    localDatetime: new Date(),
    localDatetimeText: "",
    ...overrides,
  };
}