import React, { useEffect } from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';
import useLoading from 'controllers/useLoading';


const StyledLoadingContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  padding: 7px;

  div {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 3px solid  var(--color-background);
    border-radius: 50%;
    border-top-color: var(--color-text);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
`;


const LoadingContainer = observer(() => {
  const {isLoading } = useLoading();

  if (!isLoading) {
    return null;
  }

  
  return <LoadingIndicator/>
})
export default LoadingContainer;


const LoadingIndicator = observer(() => {

  const {loadingCount, stopLoading } = useLoading();

  // automatically stop loading after 10 seconds
  useEffect(() => {
    console.log("loadingCount", loadingCount)
    setTimeout(()=>{
      stopLoading()
    }, 5000)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCount])

  return (
    <StyledLoadingContainer>
      <div></div>
    </StyledLoadingContainer>
  );
})