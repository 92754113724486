import React from 'react';

export function BoxCheckedIcon() {
  return (
    <svg
      className='icon'
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Box */}
      <path
        d="M26 4 l 0 22 l -22 0 l0 -22 l22 0"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* inner box */}
      <path
        d="M19 11 l0 8 l-8 0 l0 -8 l8 0"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function OldBoxCheckedIcon() {
  return (
    <svg
      className='icon'
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Box */}
      <path
        d="M26 16 l 0 10 l -22 0 l0 -22 l14 0"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* Check Mark */}
      <path
        d="M11 14l4 4 12 -12"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function BoxUncheckedIcon() {
  return (
    <svg
      className='icon'
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Box */}
      <path
        d="M26 4 l 0 22 l -22 0 l0 -22 l22 0"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
