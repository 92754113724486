import React from "react";
import { observer } from "mobx-react-lite";

import Line from "components/Line";
import useEditor from "../useEditor/EditorContext";
import { LAYOUT } from "../useEditor";

interface Props {
  nodeId:string,
  parentId:string,
  level?:number,
  bondId:string,
  parentBondId:string,
  grandBondId:string,
  isRoot?:boolean,
  layout?:string,
}


const Branch = observer(function(props:Props){
  const {
    nodeId,
    bondId,
    level = 0,
    parentBondId,
    layout,
  } = props;
  
  const {editor} = useEditor();

  const childBonds = editor.getFilteredChildBonds(bondId)
  const showBranchChildren = editor.getShowChildren(bondId);

  return (
    <div className="branch">
      <Line
        {...props}
      />
      <div className={layout}>
        {showBranchChildren && childBonds.map((bond)=>(
          <Branch
            key={`${nodeId}${bond.nodeId}`}
            level={layout === LAYOUT.COLUMNS ? 0 : level+1}
            bondId={bond.id}
            nodeId={bond.nodeId}
            parentId={nodeId}
            parentBondId={bondId}
            grandBondId={parentBondId}
            layout={LAYOUT.ROWS}
            />
          ))}
      </div>
    </div>
  )
})


export default Branch