import {useCallback} from "react"

import { ContextValue } from "../types";
import { BondKind } from "components/graphql";
import usePersist from "controllers/usePersist";


export default function useCreateAutoBond(context: ContextValue){
  const {stateRef} = context;

  const {persist} = usePersist();

  context.createAutoBond = useCallback(
    (parentId:string) => {
      const {nodeId} = stateRef.current;
      const newAutoBond = persist.newBond({
        nodeId,
        parentId,
        kind: BondKind.AUTO
      });
      persist.updateObjects({bonds:[newAutoBond]})
    },
    [persist, stateRef]
  )
}
