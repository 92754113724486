import React from 'react';

interface ChevronIconProps {
  direction: "up" | "down" | "left" | "right";
  fill?:boolean;
}

export function ChevronIcon(props: ChevronIconProps) {
  let { direction, fill=false } = props;
  const fillValue = fill ? "currentColor" : "none";
  let rotation = 0; // default to up
  if (direction === "down") rotation = 180;
  if (direction === "left") rotation = 270;
  if (direction === "right") rotation = 90;
  return (
    <svg
      className='icon'
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotation}deg)` }}
    >
      <path
        d="M4 22L14 4L24 22L4 22Z"
        fill={fillValue}
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
