import React from "react"

import Component from "./Tag"
import {Provider} from "./useTag"
import {TagProps} from "./types"

export default function Tag(props:TagProps){
  return (
    <Provider {...props}>
      <Component/>
    </Provider>
  )
}