import React from "react";
import { createContext, useContext } from "react";

import { observer } from "mobx-react-lite"

import { ContextValue} from "./types";

import methodHooks from "./hooks";
import { PersistState } from "./state";

type ProviderProps = {
  children: React.ReactNode,
}

export const persist = new PersistState();

const Context = createContext<ContextValue>({} as ContextValue);

// @ts-ignore ts(2339)
// window.persist = persist;

export const Provider = observer(function Provider(props: ProviderProps){
  const {children} = props;

  let contextValue = {
    persist,
  } as unknown as ContextValue;

  methodHooks.forEach((hook) => {
    hook(contextValue);
  });

  return (<Context.Provider value={contextValue}>{children}</Context.Provider>);
})

/**
 * A convenience hook for accessing the context value
 */
export default function useView(){
  return useContext(Context);
}







