import React from "react"

import Component from "./SelectDatetime"
import {Provider} from "./useSelectDatetime"

interface Props {
  datetime?: string | null,
  onDatetimeSelect?(datetime:string | null):void,
}

export default function SelectDatetime(props:Props){
  return (
    <Provider {...props}>
      <Component/>
    </Provider>
  )
}