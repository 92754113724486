import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { MaxWidthWrapper } from "components/layout";
import useOrphans from "controllers/useOrphans";
import Button from "components/Button";
import useRoot from "controllers/useRoot";


const StyledResult = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  &:hover {
    background-color: var(--color-background-inset);
  }
  button {
    padding: var(--size-spacing-xs) var(--size-spacing-md) ;
    border-radius: var(--size-border-radius);
    border:None;
    color: var(--color-background);
    background-color: var(--color-text);
  }
`;

export default function OrphansView() {
  return (
    <MaxWidthWrapper>
      <h1>Inbox</h1>
      <p>This page contains a list of nodes that do not have parent connections. Click on one of the items to add tags OR click <code>Allow</code> to remove it from this list.</p>
      <Results/>
    </MaxWidthWrapper>
  );
}

const Results = observer(()=>{
  const {nodes, hasNextPage, getNextPage, ignoreOrphan} = useOrphans();
  const {setRoot} = useRoot();
  return (
    <div>
      {nodes.map(node => (
        <StyledResult 
          key={node.id}
          onClick={()=>setRoot(node.id, {selectRoot:false})}
        >
          <div>{node.text}</div>
          <Button
            tooltip="Allow this node to not have a parent." 
            onClick={()=>ignoreOrphan(node.id)}
          >Allow</Button>
        </StyledResult>
      ))}
      {hasNextPage && (
        <Button
          tooltip="Load more results"
          onClick={getNextPage}
          onMouseEnter={getNextPage}
          $fullWidth
        >
          Load More
        </Button>
        )}
    </div>
  )
})