import { action } from "mobx"

import { AddFilterParams, EditorStoreType, UpdateFilterParams } from "./storeTypes";
import { FilterParam, PropType } from "components/graphql";

const FILTER_DEFAULTS = {
  parentId: [undefined, null],
  valueId: [undefined, null],
  boolean: [undefined, null],
  number: [undefined, null],
  datetime: ["", null, undefined],
  include: [true, null, undefined],
  operator: ["", null, undefined],
}
const FILTER_REMOVE_KEYS = ["__typename", "parent", "value"]

function cleanFilterParams(filterParams:FilterParam[]){
  // clean the filterParams
  filterParams.forEach(
    (param:FilterParam)=>{
      Object.keys(param).forEach(
        (key)=>{
          const value: any = param[key as keyof FilterParam];
          if (
            FILTER_REMOVE_KEYS.includes(key)
            ||FILTER_DEFAULTS[key as keyof FilterParam].includes(value)
          ) {
            delete param[key as keyof FilterParam]
          }
        }
      )
    }
  )
  return filterParams;
}

export const setFilterParams = action(function(
  this:EditorStoreType,
  filterParams:FilterParam[],
  filtersTouched:boolean,
){
  
  this.filterParams = cleanFilterParams(filterParams);
  this.filtersTouched = filtersTouched;
})

export const setDefaultFilterParams = action(function(
  this:EditorStoreType,
  filterParams:FilterParam[],
){
  this.defaultFilterParams = cleanFilterParams(filterParams);
})

export const removeFilter = action(function(
  this:EditorStoreType,
  idx:number,
){
  const newFilterParams = [...this.filterParams];
  newFilterParams.splice(idx, 1);
  this.setFilterParams(newFilterParams, true);
})

export const addFilter = action(function(
  this:EditorStoreType,
  params: AddFilterParams,
){
  let {
    parentId,
    valueId,
    boolean,
    number,
    datetime,
  } = params;
  

  // if the parent is a boolean prop and boolean is not true
  // then set boolean to false
  if (parentId && !boolean) {
    const parent = this.persist.getNode(parentId);
    if (!parent || parent.propType === PropType.BOOLEAN) {
      boolean = false;
    }
  }

  const param = {
    parentId,
    valueId,
    boolean,
    number,
    datetime,
  }
  this.setFilterParams([...this.filterParams, param], true);
})

export const updateFilter = action(function(
  this:EditorStoreType,
  params: UpdateFilterParams,
){
  let {idx, payload} = params;
  const newFilterParams = [...this.filterParams];
  // ensure idx is converted to a number if it isn't one
  idx = Number(idx);
  const updatedFilter = {
    ...newFilterParams[idx],
    ...payload
  };
  newFilterParams.splice(idx, 1, updatedFilter);
  this.setFilterParams(newFilterParams, true);
})