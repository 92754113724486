import { addListItem } from "common";

import { State, SetDecisionPayload} from "../types";
import { bracketSort } from "../helpers";

/**
 * PersistState the decision of which bond is greater than the other.
 * Then sort the heap again to get the next options.
 */
export function setDecision(state:State, payload:SetDecisionPayload): State {
  let {greaterId, lesserId} = payload;
  
  const {bondWins} = state;

  // add the lesserId to the greaterId's list of wins
  state.bondWins[greaterId] = addListItem(lesserId, bondWins[greaterId]);

  // add all of the lesserId's wins to the greaterId's list of wins
  state.bondWins[greaterId] = state.bondWins[greaterId].concat(bondWins[lesserId]);

  state = bracketSort(state);
  return state;
}