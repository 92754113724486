import { useCallback } from "react";

import usePersist from "controllers/usePersist";
import { ContextValue } from "../types";


export default function useCreateNodeWithText(context:ContextValue){
  const {onSelectNode} = context;

  const {persist} = usePersist();

  context.createNodeWithText = useCallback(
    (text:string)=> {
      // try to find a node with the same text
      const existingNode = persist.getNodeByText(text);
      if (existingNode){
        onSelectNode(existingNode, false);
        return;
      }
      // otherwise create a new node
      // only match text if there is text to match
      const matchText = text.trim().length > 0;
      console.log("creating new node", text, matchText);
      const newNode = persist.newNode({text, matchText});
      persist.updateObjects({
        nodes:[newNode]
      })
      onSelectNode(newNode, true);
    },
    [persist, onSelectNode]
  )

}