import { v4 as uuidv4 } from 'uuid';
import { makeObservable, observable, action, computed } from "mobx"
import { computedFn } from 'mobx-utils';
import {debounce} from 'lodash';

import {
  LocalStorageKey,
} from "common/utils/localStorage";
import { getDebugLog, addListItem} from "common"
import { 
  AnyBond,
  AnyNode,
  Bond,
  BondKind,
  BondNodeIds,
  BondNodeKey,
  Node,
  NodeKind,
  PropType,
} from "components/graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = getDebugLog(false, "PersistStore");

export const localNode = new LocalStorageKey("persist-nodes")
export const localBond = new LocalStorageKey("persist-bonds")

export enum StateNodesKey {
  CHILDREN = "children",
  PARENTS = "parents",
}

export interface NodePartial extends Partial<Node> {
  id:string,
}

export interface BondPartial extends Partial<Bond> {
}

export interface PostPersistNodePayload {
  node: Node,
}

export interface PostPersistBondPayload {
  bond: Bond,
  newBonds?: Bond[],
  deletedBondIds?: string[],
}

export interface RemovePersistBondIdPayload {
  bondId: string,
}

export interface RemovePersistNodeIdPayload {
  nodeId: string,
}

export interface StoreObjectsParams {
  nodes?: Node[],
  bonds?: Bond[],
}

export interface UpdateObjectsParams {
  nodes?: NodePartial[],
  bonds?: BondPartial[],
}

interface updatableObject {
  updatedAt:string,
}
export class PersistState {

  constructor(){
    // get the node ids that should be persisted
    this.persistNodeIds = localNode.get("list") || [];
    // get the bond ids that should be persisted
    this.persistBondIds = localBond.get("list") || [];

    // set debounce methods
    this.debounceUpdateObjects = debounce(
      this.updateObjects,
      1000,
      {
        leading:true,
        trailing:true,
      }
    );
    makeObservable(this);
  }
  // debounced methods
  debounceUpdateObjects: (payload:UpdateObjectsParams) => void;

  // denotes if we are currently syncing with the server
  @observable isSyncing:boolean = false;
  // A list of nodeIds that should be persisted
  @observable persistNodeIds: string[] = [];
  // A list of bondIds that should be persisted
  @observable persistBondIds: string[] = [];
  // a map of nodeIds to node objects
  @observable nodeMap: {[nodeId:string]:Node} = {};
  // a map of bondIds to bond objects
  @observable bondMap: {[bondId:string]:Bond} = {};
  // A map of a node's child bond ids
  @observable children: {[parentNodeId:string]:string[]} = {};
  // A map of a node's parent bond ids
  @observable parents: {[childNodeId:string]:string[]} = {};
  // A map of subgroup bondIds where the node is the child
  @observable parentSubgroupIds: {[nodeId:string]: string[]} = {};
  // A map of subgroup bondIds where the node is the parent
  @observable childSubgroupIds: {[nodeId:string]: string[]} = {};

  @computed get needsSync():boolean {
    return Boolean(this.persistBondIds.length || this.persistNodeIds.length);
  }

  /**
   * Set the isSyncing flag
   */
  @action setSyncing = (isSyncing:boolean):void => {
    this.isSyncing = isSyncing;
  }

  /**
   * If there's a problem persisting to the server,
   * this will remove the bondId from the list of persistBondIds
   */
  @action removePersistBondId = (bondId:string):void => {
    // remove the id from persistBondIds
    this.persistBondIds = this.persistBondIds.filter(id=>id!==bondId);
  
    // remove the bond from local storage
    localBond.set("list", this.persistBondIds);
    localBond.remove(bondId);
    
    // trigger the syncing process to restart
    this.setSyncing(false);
  } 

  /**
   * If there's a problem persisting to the server,
   * this will remove the nodeId from the list of persistNodeIds
   */
  @action removePersistNodeId = (nodeId:string):void => {
    // remove the id from persistNodeIds
    this.persistNodeIds = this.persistNodeIds.filter(id=>id!==nodeId);
  
    // remove the node from local storage
    localNode.set("list", this.persistNodeIds);
    localNode.remove(nodeId);
    
    // trigger the syncing process to restart
    this.setSyncing(false);
  }

  /**
   * After the node has been persisted to the server,
   * this removes the node from local storage 
   * and stores the updated node to the state.
   */
  @action postPersistNode = (payload:PostPersistNodePayload):void => {
    // log("postPersistNode", payload)
    const {node} = payload;
    const savedNode = localNode.get(node.id);
    const {replaceId} = node;

    // trigger the syncing process to restart
    this.isSyncing = false;

    // Only remove the node if it's the same as the locally stored node
    if(this.isNewer(node, savedNode, true)) {
      // remove from local storage
      localNode.remove(node.id);
      // remove the id from persistNodeIds
      this.persistNodeIds = this.persistNodeIds.filter(id=>id!==node.id);
      localNode.set("list", this.persistNodeIds); 
      
      this._storeNode(node);
    } else {
      log("not clearing synced node", node, savedNode)
    }
    
    // if there was no replaceId, we're done
    if (!replaceId) return;
    
    // if the node has a replaceId, the old node should be archived
    // and any bonds to the old node should be updated to point to the new node
    
    // remove the node from the list of nodes to sync
    this.removePersistNodeId(replaceId);
    
    // update any bonds that point to the replaceId
    // to use the new node id
    const updatedBonds:BondPartial[] = [];
    const localBondIds:string[] = localBond.get("list");
    localBondIds.forEach(bondId=>{
      const {
        id,
        nodeId,
        parentId,
        valueId,
      } = localBond.get(bondId);

      let updated = false;
      const updatedBond: BondPartial = {
        id,
        updatedAt:this.now(),
      }
      if (nodeId === replaceId) {
        updatedBond.nodeId = node.id;
        updated = true;
      }
      if (parentId === replaceId) {
        updatedBond.parentId = node.id;
        updated = true;
      }
      if (valueId === replaceId){
        updatedBond.valueId = node.id;
        updated = true;
      }
      if (updated) {
        updatedBonds.push(updatedBond);
      }
    })
    
    updatedBonds.forEach(bond=>{
      this._updateBond(bond);
    })
  }
  /**
   * After the bond has been persisted to the server,
   * this removes the bond from local storage
   * and stores the updated bond to the state.
   */
  @action postPersistBond = (payload:PostPersistBondPayload):void => {
    let {
      bond,
      newBonds = [],
      deletedBondIds = [],
    } = payload;

    const savedBond = localBond.get(bond.id);

    // see if the bond is older than the one in local storage
    if (!this.isNewer(savedBond, bond)) {
      this.removePersistBondId(bond.id);
    } else {
      log("not clearing synced bond", bond, savedBond)
    }
    
    // archive any deleted bonds
    const archivedBonds:AnyBond[] = []
    deletedBondIds.forEach(id=>{
      const bond = this.getBondMaybe(id);
      if (!bond) return;
      bond.archivedAt = this.now();
      archivedBonds.push(bond);
    })

    
    this.storeObjects({
      bonds:[bond, ...newBonds, ...archivedBonds],
    })
    this.setSyncing(false);
  }

   /**
   * Store the value for nodes and bonds to the state.
   */
   @action storeObjects = (params:StoreObjectsParams): void => {
    const {
      nodes = [],
      bonds = [],
    } = params;

    // store nodes
    nodes.forEach(node => {
      this._storeNode(node);
    })

    // store bonds
    bonds.forEach(bond => {
      this._storeBond(bond);
    })
  }

  /**
   * Store a local change to nodes and/or bonds
   * and trigger syncing with the server
   */
  @action updateObjects = (payload:UpdateObjectsParams):void => {
    log("updateObjects", payload, this.persistNodeIds.length)
    const {
      nodes:nodePartials = [],
      bonds:bondPartials = [],
    } = payload;

    const updatedAt = this.now();

    nodePartials.forEach(partial=>{
      this._updateNode({
        ...partial,
        updatedAt,
      });
    })

    bondPartials.forEach(partial=>{
      this._updateBond({
        ...partial,
        updatedAt,
      });
    })
    log("updateObjects after", this.persistNodeIds.length)
  }

  /**
   * Store local changes to a node and queue it to be synced with the server.
   */
  _updateNode = (partial:NodePartial): void => {
    let {
      id,
      updatedAt="",
      // if archivedAt is not set, ensure we un-archive it
      archivedAt=null,
    } = partial;

    // ensure archivedAt is set
    const updatedValues = {
      archivedAt,
      updatedAt,
      ...partial,
    } 

    // if the id is not provided, check if the node exists, otherwise create i
    if (updatedAt === undefined) throw new Error("updatedAt is required")

    // get the existing node
    let node = this.getNodeMaybe(id) || this.newNode({id, updatedAt});

    // if the existing node is newer than the partial, don't update it
    if (this.isNewer(node, updatedValues)) return;

    // store the node with the new value
    node = {
      ...node,
      ...updatedValues,
    }
    this._storeNode(node);

    // save just the changes to local storage
    // this is to prevent syncing the entire node
    let local = localNode.get(id) || {id};
    local = {
      ...local,
      ...updatedValues,
    }
    localNode.set(id, local);

    // add the node to the list of nodes to sync
    if (this.persistNodeIds.indexOf(id) === -1) {
      this.persistNodeIds.push(id);
      localNode.set("list", this.persistNodeIds);
    }
  }
  
  _updateBond = (partial:BondPartial): void => {
    let {
      id,
      updatedAt,
      // if archivedAt is not set, ensure we un-archive it
      archivedAt=null,
    } = partial;

    // ensure updatedAt is set
    if (!updatedAt) throw new Error("updatedAt is required")

    const updatedValues = {
      archivedAt,
      updatedAt,
      ...partial,
    }

    // if the id is not provided, check if the bond exists, otherwise create it
    if (!id) {
      const {
        nodeId,
        parentId,
      } = partial;
      if (nodeId === undefined) throw new Error("nodeId is required when id is not provided")
      if (parentId === undefined) throw new Error("parentId is required when id is not provided")
      const existing = this.getBondFromNodes(parentId, nodeId, partial.kind) || {id: this.newId()};
      id = existing.id;
    }
    // add the bond to the list of bonds to sync
    if (!id) throw new Error("bond id is required")

    // get or create the bond
    let bond = this.getBondMaybe(id) || this.newBond({id, updatedAt});

    // if the existing bond is newer, skip this one
    if(this.isNewer(bond, updatedValues)) return;

    // update the bond with the new value
    bond = {
      ...bond,
      ...updatedValues,
    }
    // add the bond to the state
    this._storeBond(bond);

    // save just the changes to local storage
    let local = localBond.get(id) || {id};
    local = {
      ...local,
      ...updatedValues,
    }
    localBond.set(id, local);
    // add the bond to the list of bonds to sync
    if (this.persistBondIds.indexOf(id) === -1) {
      this.persistBondIds.push(id);
      localBond.set("list", this.persistBondIds);
    }
  }

  /**
   * Adds the given node to the nodeMap
   */
  _storeNode = (node:AnyNode): void => {
    const {id} = node;
    const {
      parentBonds = [],
      ...newNode
    } = node;

    // only store the node if it's newer than the existing one
    const existingNode = this.nodeMap[id];

    if (!this.isNewer(newNode, existingNode, true)) {
      log("not storing node", newNode, existingNode)
      return
    }
    
    this.nodeMap[id] = newNode

    // add parent nodes
    parentBonds.forEach(bond => {
      this._storeBond(bond);
    })
  }

  /**
   * Adds the given bond to the bondMap
   */
  _storeBond = (bond:AnyBond) => {
    this._storeBondNode(bond, BondNodeKey.NODE, StateNodesKey.PARENTS)
    this._storeBondNode(bond, BondNodeKey.PARENT, StateNodesKey.CHILDREN)
    this._storeBondNode(bond, BondNodeKey.VALUE)
    this._storeSubgroupBond(bond)
    
    const existingBond = this.bondMap[bond.id];
    if (this.isNewer(bond, existingBond, true)){
      if (!bond.sortIndex) bond.sortIndex = Math.random();
      this.bondMap[bond.id] = {...bond}
    } else {
      console.log("not storing bond", bond, existingBond)
    }
  }

  /**
   * add the node from a bond to the state
   * and removes the node object from the bond object.
   * 
   * stateKey - is the key the bond should be added to for the given node
   */
  _storeBondNode = (bond:AnyBond, nodeKey:BondNodeKey, stateKey?:StateNodesKey | null) => {
    // find the key for the nodeId, for example valueId
    const nodeIdKey = `${nodeKey}Id` as keyof BondNodeIds;
    const nodeId = bond[nodeIdKey] || "";
    
    // get the node for the key
    const node = bond[nodeKey]
    // and remove it from the stored bond
    delete bond[nodeKey]

    // Associate the bondId with the nodeId's other bonds for this stateKey
    // AKA, add the bondId as a child bond of the parent node
    // or parent bond of the child node
    if (stateKey) {
      const bondId = bond.id
      this[stateKey][nodeId] = addListItem(bondId, this[stateKey][nodeId])
    };

    // if this bond doesn't have that key, return early
    if (!nodeId) return
    
    // add the node to the state
    if (node) {
      this._storeNode(node);
    }

    
  }

  /**
   * Adds a subgroup bond to the state
   */
  _storeSubgroupBond = (bond:AnyBond):void => {
    // return early if the bond is not a subgroup kind
    if (bond.kind !== BondKind.SUBGROUP) return;
    
    const {
      id:bondId,
      parentId,
      nodeId,
    } = bond;

    this.parentSubgroupIds[nodeId] = addListItem(bondId, this.parentSubgroupIds[nodeId])
    this.childSubgroupIds[parentId] = addListItem(bondId, this.childSubgroupIds[parentId])
  }

  /**
   * Determine if the first object is newer than the second object
   */
  isNewer(obj1:updatableObject|undefined|null, obj2:updatableObject|undefined|null, orEqual=false):boolean{
    if (!obj1) return false;
    if (!obj2) return true;
    const time1 = new Date(obj1.updatedAt).getTime();
    const time2 = new Date(obj2.updatedAt).getTime();
    if (orEqual) return time1 >= time2;
    return time1 > time2;
  }

  newId = ():string => {
    return uuidv4();
  }

  now = (offsetMs = 0):string => {
    const date = new Date();
    if (offsetMs) {
      date.setMilliseconds(date.getMilliseconds() + offsetMs);
    }
    return date.toISOString();
  }

  lastPosition = ():number => {
    return Date.now();
  }

  newNode = (overrides:Partial<AnyNode>={}):AnyNode => {
    return {
      id: this.newId(),
      createdAt: this.now(),
      updatedAt: this.now(),
      text:"",
      icon:"",
      isParent: false,
      kind: NodeKind.NODE,
      propType: PropType.NODE,
      archivedAt: null,
      matchText: false,
      ...overrides,
    };
  }
  
  newBond = (overrides:Partial<AnyBond>):AnyBond => {
    return {
      id: this.newId(),
      createdAt: this.now(),
      updatedAt: this.now(),
      position: this.lastPosition(),
      archivedAt: null,
      parentId: "",
      nodeId: "",
      boolean: false,
      datetime: null,
      number: null,
      isHidden: false,
      valueId: null,
      kind: BondKind.CONTENT,
      ...overrides,
    };
  }


  getBondIdx = (bonds:Bond[], bondId:string):number => {
    return bonds.findIndex((bond) => bond.id === bondId);
  }

  getBondNodeIdx = (bonds:Bond[], nodeId:string):number => {
    return bonds.findIndex((bond) => bond.nodeId === nodeId);
  }

  getBond = (bondId:string):Bond => {
    return this.bondMap[bondId];
  }
  
  getBondMaybe = (bondId:string | null | undefined):Bond | undefined => {
    if (!bondId) return undefined;
    return this.getBond(bondId);
  }

  getNode = (nodeId:string):Node => {
    return this.nodeMap[nodeId];
  }
  
  getNodes = (nodeIds:string[]):Node[] => {
    return nodeIds.map(nodeId => this.getNode(nodeId));
  }

  getNodeMaybe = (nodeId:string | null | undefined):Node | undefined => {
    if (!nodeId) return undefined;
    return this.getNode(nodeId);
  }

  getNodeByText = (text:string):Node | undefined => {
    text = text.toLowerCase();
    // remove leading and trailing whitespace
    text = text.trim();
    if (!text) return undefined;
    const nodeIds = Object.keys(this.nodeMap);
    const nodeId = nodeIds.find(nodeId => this.nodeMap[nodeId].text.toLowerCase().trim() === text);
    if (!nodeId) return undefined;
    return this.nodeMap[nodeId];
  }

  getChildBonds = (nodeId:string, kinds:BondKind[] | null = [BondKind.CONTENT, BondKind.SUBGROUP], filterArchived=true):Bond[] => {
    const bondIds: string[] = this.children[nodeId] || [];
    const childBonds = bondIds.map(bondId => this.bondMap[bondId]);
    // filter archived bonds
    return childBonds.filter(bond => {
      if (!bond) return false;
      if (filterArchived && bond.archivedAt) return false;
      if (kinds && kinds.length && !kinds.includes(bond.kind!)) return false;
      return true;
    });
  }

  isParent = computedFn((nodeId:string):boolean => {
    // if the node is marked as a parent, return true
    const node = this.getNode(nodeId);
    if (node.isParent) return true;

    // if the node has local children, return true
    const bonds = this.getChildBonds(nodeId);
    return bonds.length > 0;
  })

  /**
   * Get a list of the bonds that are parents of the given nodeId
   */
   getParentBonds = computedFn((nodeId:string, kind:BondKind | null = BondKind.CONTENT, filterArchived=true):Bond[] => {
    const bondIds: string[] = this.parents[nodeId] || [];
    const parentBonds = bondIds.map(bondId => this.bondMap[bondId]);
    // filter archived bonds
    return parentBonds.filter(bond => {
      if (!bond) return false;
      if (filterArchived && bond.archivedAt) return false;
      if (kind && bond.kind !== kind) return false;
      return true;
    });
  })

  /**
   * Get a bond object for the given parent and child node ids
   */
  getBondFromNodes = (parentId:string, childId:string, kind:BondKind | null = BondKind.CONTENT):Bond | undefined => {
    const childBonds = this.getChildBonds(parentId, null, false);
    return childBonds.find(bond => {
      if (!bond) return false;
      if (kind && bond.kind !== kind) return false;
      return bond.nodeId === childId
    });
  }

  /**
   * Get a list of bonds that are subgroups of the given parent node id
   */
  getSubgroupNodeIds = (parentId:string):string[] => {
    const bondIds = this.childSubgroupIds[parentId] || [];
    let bonds = bondIds.map(bondId => this.bondMap[bondId]);
    // filter archived bonds
    bonds = bonds.filter(bond => {
      if (!bond) return false;
      if (bond.archivedAt) return false;
      return true;
    })
    return bonds.map(bond => bond.nodeId);
  }

  generatePositionAfterIndex = (bonds:Bond[], idx:number):number => {
    /*
    Generate an position float that is halfway between the bond
    at the given index and the one after it.
    These positions are in unix time.
    */
    let position1, position2;
    try {
      position1 = bonds[idx - 1].position
    } catch(error){
      // if there is no first bond
      // set position1 as the beginning of unix time
      position1 = 0.0
    }
    try {
      position2 = bonds[idx].position;
    } catch(error) {
      // if there is no next item, put it at the end
      return this.lastPosition();
    }
    // if the positions are the same, put it slightly before
    if (position1 === position2){
      const str = position1.toString();
      const decimalPlace = str.includes('.') ? str.split('.')[1].length : 0;
      const additionalDecimal = Math.pow(10, -1 * (decimalPlace + 1));
      return position1 - additionalDecimal;
    }
    // return a number between the two positions
    return (position1 + position2) / 2;
  }

  /**
   * generates a new position for a child of parentId
   * relative to the given nodeId
   */
  getNewPositionForIndex = (parentId:string, newIdx:number) =>{
    const bonds = this.getChildBonds(parentId);
    const position = this.generatePositionAfterIndex(bonds, newIdx);
    return position
  }

  /**
   * Get the auto bonds for the given nodeId
   */
  getAutoBonds = (nodeId:string):Bond[] => {
    return this.getParentBonds(nodeId, BondKind.AUTO);
  }

  /**
   * Get the auto subgroup bonds for the given nodeId
   */
  getAutoSubgroupBonds = (nodeId:string):Bond[] => {
    return this.getChildBonds(nodeId, [BondKind.AUTO_SUBGROUP]);
  }

  /**
   * Find nodes with the matching text
   */
  searchNodes = (params:{term:string, pageSize:number}):Node[] => {
    let {term, pageSize} = params;
    // remove leading and trailing whitespace
    term = term.trim().toLocaleLowerCase();
    const nodeIds = Object.keys(this.nodeMap);
    const nodes = nodeIds.map(nodeId => this.nodeMap[nodeId]);
    const matchingNodes =  nodes.filter(node => {
      if (!node) return false;
      if (node.archivedAt) return false;
      if (!node.text) return false;
      return node.text.toLowerCase().includes(term);
    });
    // limit the number of nodes returned
    return matchingNodes.slice(0, pageSize);
  }

  /**
   * Get if the given nodeId is pinned
   */
  getIsPinned = computedFn((nodeId:string):boolean => {
    const bond = this.getBondFromNodes("", nodeId, BondKind.PIN);
    log("getIsPinned", bond)
    // if the bond doesn't exist, it's not pinned 
    if (!bond) return false;
    // if the bond is archived, it's not pinned
    return !Boolean(bond.archivedAt);
  })

  addPin = (nodeId:string):void => {
    log("addPin", nodeId)
    const bond = {
      parentId: "",
      nodeId,
      kind: BondKind.PIN,
      archivedAt: null,
    }
    this.updateObjects({bonds:[bond]});
  }

  removePin = (nodeId:string):void => {
    log("removePin", nodeId)
    const bond = {
      parentId: "",
      nodeId,
      kind: BondKind.PIN,
      archivedAt: this.now(),
    }
    this.updateObjects({bonds:[bond]});
  }

  // TESTING METHODS
  storeNewNode = (overrides:Partial<AnyNode>={}):Node => {
    const node = this.newNode(overrides);
    this._storeNode(node);
    return node;
  }
  storeNewBond = (overrides:Partial<AnyBond>={}):Bond => {
    const bond = this.newBond(overrides);
    this._storeBond(bond);
    return bond;
  }

  /**
   * Creates a new node with the given overrides
   */
  oldNode = (overrides:Partial<AnyNode>):AnyNode => {
    return this.newNode({
      ...overrides,
      createdAt: this.now(-1000),
      updatedAt: this.now(-1000),
    })
  }

  /**
   * Creates a bond that is older than the current time
   */
  oldBond = (overrides:Partial<AnyBond>):AnyBond => {
    return this.newBond({
      ...overrides,
      createdAt: this.now(-1000),
      updatedAt: this.now(-1000),
    })
  }

}
