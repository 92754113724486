import React from "react";
import clsx from "clsx"

import { getDebugLog } from "common";
import { option, SelectToggle, SelectDropDown } from "common/components/Selectors";
import NodeLookupInput from "components/NodeLookupInput";
import { TagType } from "components/Tag/types";
import LineInput from "components/LineInput";
import { Node, NodeKind, PropType } from "components/graphql";
import { truncate } from "common/utils";
import useTag from "./useTag"
import "./tag.scss"
import { BoxCheckedIcon, BoxUncheckedIcon, CloseIcon } from "common/icons";
import SelectDatetime from "components/SelectDatetime";
import { useStateRef } from "common/hooks/state";

const log = getDebugLog(false, "Tag");


const OPERATOR_PROP_TYPES = [
  PropType.NUMBER,
  PropType.DATETIME,
]

const OPERATOR_OPTIONS = [
  option("=", ""),
  option(">", "gt"),
  option("≥", "gte"),
  option("<", "lt"),
  option("≤", "lte"),
];

const INCLUDE_OPTIONS = [
  option("+", true),
  option("-", false),
]

export default function Tag(){
  const {needsSelection} = useTag();

  const classNames = clsx("tag",  needsSelection && "needs-selection")
  return (
    <span className={classNames}>
      <Include/>
      <ParentNode/>
      <Operator/>
      <NodeValue/>
      <BoolValue/>
      <NumberValue/>
      <DatetimeValue/>
      <Remove/>
    </span>
  )
}

function Include(){
  const { type, include=true, id, onPropValueUpdate} = useTag()

  if (type !== TagType.FILTER) return null;

  function setInclude(include:boolean){
    onPropValueUpdate(id, {include})
  }

  return (
    <SelectToggle
      value={include}
      setValue={setInclude}
      options={INCLUDE_OPTIONS}
    />
  )
}

function Operator(){
  const { 
    id,
    type,
    parentNode,
    operator=OPERATOR_OPTIONS[0].value,
    onPropValueUpdate,
  } = useTag()

  // only use operators for filters
  if (type !== TagType.FILTER) return null;

  if (!parentNode) return null;
  
  // only show if the parent node is a prop
  if (parentNode.kind !== NodeKind.PROP) return null;
  
  // only show if the prop type uses an operator
  if (!OPERATOR_PROP_TYPES.includes(parentNode.propType)) return null;

  function setOperator(operator:string){
    onPropValueUpdate(id, {operator})
  }
  
  return (
    <SelectDropDown
      value={operator}
      setValue={setOperator}
      options={OPERATOR_OPTIONS}
    />
  )
}

function Remove(){
  const {showRemove, remove} = useTag();
  if (!showRemove) return null;
  
  return (
    <button
      data-selection-nav
      onClick={remove}
    >
      <CloseIcon/>
    </button>
  )
}

function ParentNode(){
  const {parentNode, select} = useTag();

  function selectNode(e:React.MouseEvent<HTMLElement>){
    select(e.altKey);
  }

  if (!parentNode) return null;

  return (
    <button
      data-selection-nav  
      onClick={selectNode}
    >
      {truncate(parentNode.text)}
    </button>
  )
}

function NodeValue(){
  const {
    showSuggestions,
    parentNode,
    valueNode,
    id,
    setShowSuggestions,
    onPropValueUpdate,
  } = useTag();

  function handleSelect(node:Node){
    onPropValueUpdate(id, {valueId:node.id})
    setShowSuggestions(false);
  }

  const openOptions = () => {
    setShowSuggestions(true);
  }
  const handleClose = () => {
    log(`close suggestions`)
    setShowSuggestions(false);
  }

  if (!parentNode) return null;

  // only show if the parent node is a prop
  if (parentNode.kind !== NodeKind.PROP) return null;

  // only show if the parent node is prop type is NODE
  if (parentNode.propType !== PropType.NODE) return null;

  // show the value node if it exists
  if (valueNode && !showSuggestions) return (
    <>
      <Divider/>
      <button
        data-selection-nav  
        onClick={openOptions}
      >
        {truncate(valueNode.text)}
      </button>
    </>
  )
  // show node select if no value node exists
  return (
    <>
      <Divider/>
      <NodeLookupInput
        minWidth={100}
        onSelectNode={handleSelect}
        orderBy="last_value"
        showSuggestions={showSuggestions}
        propId={parentNode.id}
        placeholder=""
        onClose={handleClose}
      />
    </>
  )
}

function NumberValue(){
  const { 
    id,
    type,
    number,
    parentNode,
    onPropValueUpdate,
  } = useTag()

  const [localNumber, setLocalNumber, localNumberRef] = useStateRef(number);

  if (!parentNode) return null;

  // only show if the parent node is a prop
  if (parentNode.kind !== NodeKind.PROP) return null;

  // only show if this is a number prop
  if (parentNode.propType !== PropType.NUMBER) return null;

  function updateValue(value:string){
    const number = parseFloat(value) || null;
    console.log("update number", number, localNumber)
    if (localNumber === number) return;
    onPropValueUpdate(id, {number})
    setLocalNumber(number);
  }
  
  const isFilter = type === TagType.FILTER;

  const adjust = (adjust:number) => {
    if (localNumberRef.current === null) return;
    setLocalNumber(localNumberRef.current + adjust);
  }

  return (
    <>
      {!isFilter && <Divider/>}
      <LineInput
        actionBarLabel="tag"
        text={localNumber}
        defaultValue={localNumber || ""}
        onBlur={updateValue}
        onEnter={updateValue}
        onArrowUp={() => adjust(1)}
        onArrowUpAlt={() => adjust(10)}
        onArrowDown={() => adjust(-1)}
        onArrowDownAlt={() => adjust(-10)}
      />
    </>
  )
}

function BoolValue(){
  const { 
    id,
    boolean,
    parentNode,
    onPropValueUpdate,
  } = useTag()

  if (!parentNode) return null;

  // only show if the parent node is a prop
  if (parentNode.kind !== NodeKind.PROP) return null;

  // only show if this is a boolean prop
  if (parentNode.propType !== PropType.BOOLEAN) return null;


  function toggleProp(e:React.MouseEvent<HTMLButtonElement>){
    onPropValueUpdate(id, {
      boolean:!boolean,
    })
  }

  return (
    <button
      onClick={toggleProp}
      data-action-bar-label="tag"
    >
      {boolean ? (
        <BoxCheckedIcon/>
      ) : (
        <BoxUncheckedIcon/> 
      )}
    </button>
  )
}

function DatetimeValue(){
  const {
    id,
    datetime,
    parentNode,
    onPropValueUpdate,
    type,
  } = useTag();

  // only show if the parent node is a prop
  if (parentNode?.kind !== NodeKind.PROP) return null;
  // only show if this is a datetime prop
  if (parentNode?.propType !== PropType.DATETIME) return null;
  
  function updateProp(datetime:string){
    log("update datetime", datetime)
    onPropValueUpdate(id, {
      datetime,
    })
  }

  const isFilter = type === TagType.FILTER;

  return (
    <>
      {!isFilter && <Divider/>}
      <SelectDatetime
        datetime={datetime}
        onDatetimeSelect={updateProp}
      />
    </>
  )
}

function Divider(){
  return <span className="divider">:</span>
}

