import {State, View, SetLocalDatetimeTextPayload as Payload} from "../types";


export function setLocalDatetimeText(state:State, payload:Payload): State {
  let { localDatetimeText } = payload;

  const parts = localDatetimeText.split("/");
  const year = parts[0] ? Number(parts[0]) : new Date().getFullYear();
  const month = parts[1] ? Number(parts[1]) - 1 : 0
  const day = parts[2] ? Number(parts[2]) : 1;
  const date = new Date(year, month, day);

  // if the date is valid, update the localDatetime
  const localDatetime = isNaN(date.getTime()) ? state.localDatetime : date;

  return {
    ...state,
    localDatetimeText,
    localDatetime,
    view: View.CALENDAR,
  }
}