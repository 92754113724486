import {useCallback, useEffect} from "react"
import { gql, useLazyQuery} from '@apollo/client';

import usePersist from "controllers/usePersist";
import useErrors from 'controllers/useErrors';
import { ContextValue } from "../types";
import { FRAG_BOND_PARENT, FRAG_NODE, FRAG_AUTO_BOND_SUBGROUP } from "components/graphql";

export default function useGetAutoBonds(context: ContextValue){
  const {stateRef} = context;

  const {persist} = usePersist()
  const {hasResponseError, onGqlError} = useErrors();
  
  const onCompleted = useCallback(
    function onCompleted(data:any){
      const res =  data.nodeAutoBonds;
      if (hasResponseError(res)) return;

      const {
        node,
        autoBonds,
        autoSubgroups,
      } = res;

      persist.storeObjects({
        nodes:[node],
        bonds:[...autoBonds, ...autoSubgroups],
      })
    },
    [hasResponseError, persist]
  )

  const [query] = useLazyQuery(GQL, {
    fetchPolicy: "no-cache",
    onCompleted,
    onError:onGqlError,
  });


  const getInitialBonds = useCallback(
    () => {
      const {nodeId} = stateRef.current;
      const variables = {
        nodeId,
      }
      return query({variables})
    },
    [query, stateRef]
  )

  useEffect(
    ()=>{
      getInitialBonds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]
  )
}

const GQL = gql`
  query NodeAutoBonds(
    $nodeId:ID!
  ){
    nodeAutoBonds(
      nodeId: $nodeId
    ){
      success
      errorMessage
      errorData
      node {
        ...NodeBase
      }
      autoBonds {
        ...BondParent
      }
      autoSubgroups {
        ...BondAutoSubgroup
      }
    }
  }
  ${FRAG_NODE}
  ${FRAG_BOND_PARENT}
  ${FRAG_AUTO_BOND_SUBGROUP}
`