
import { State } from "./types";

export default function defaultState(overrides:Partial<State>={}): State {
  let {
    end = null,
    bonds = [],
    ...otherOverrides
  } = overrides;
  end = end || bonds.length - 1;
  return {
    bonds: bonds,
    sortedBonds: [],
    loopRemaining: 1,
    optionA: null,
    optionB: null,
    bondWins: {},
    start: 0,
    end: end,
    next: 0,
    ...otherOverrides,
  };
}