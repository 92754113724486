
import { State } from "./types";

export default function defaultState(overrides:Partial<State>={}): State {
  return {
    isMobile: false,
    keyboardOpen: false,
    width: 0,
    height: 0,
    offsetTop: 0,
    offsetLeft: 0,
    bottom: 0,
    ...overrides,
  };
}