
import React, {createContext, useCallback, useContext} from "react"
import {observer} from "mobx-react-lite"

import useRoot from "controllers/useRoot";
import useEditor from "components/editor/useEditor";

import {ContextValue, LineProps} from "./types"


const Context = createContext<ContextValue>({} as ContextValue);

export default function useLineContext(){
  return useContext(Context);
}

interface ProviderProps extends LineProps {
  children: React.ReactNode,
}

export const LineContextProvider = observer(function LineContextProvider(props:ProviderProps){
  const {nodeId, bondId, parentId=""} = props;
  const {editor} = useEditor()
  const node = editor.persist.getNode(nodeId);
  const bond = editor.persist.getBond(bondId);


  const {setRoot} = useRoot();

  const setNodeAsRoot = useCallback(
    ()=>{setRoot(node.id)}, [node, setRoot],
  )
  
  const value = {
    ...props,
    node,
    bond,
    parentId,
    setNodeAsRoot,
  }
  return (<Context.Provider value={value}>{props.children}</Context.Provider>);
})