import React from "react";
import { observer } from "mobx-react-lite";

import useMetaEditor from "controllers/useMetaEditor";
import { Bond, FilterParam, AnyNode} from "components/graphql";
import AddTag from "components/AddTag";
import Tag from "components/Tag";
import { TagType } from "components/Tag/types";
import EditorBackButton from "components/EditorBackButton/EditorBackButton";
import KindSelect from "components/editor/components/KindSelect";
import usePersist from "controllers/usePersist";
import { MaxWidthWrapper } from "components/layout";

const MetaEditor = observer(function(){
  const {nodeId} = useMetaEditor()
  const {persist} = usePersist();
  const node = persist.getNodeMaybe(nodeId);

  if (!node) return <EditorBackButton/>;

  return (
    <div className="meta-editor">
      <MaxWidthWrapper>
        <EditorBackButton/>
        <h1>Settings: {node.text}</h1>
        <h2>Value Type:</h2>
        <p>Select a value type to assign a value along with this label.</p>
        <KindSelect/>
        <AutoBonds/>
      </MaxWidthWrapper>
    </div>
  )  
})
export default MetaEditor

const AutoBonds = observer(function(){
  const {nodeId} = useMetaEditor()
  
  const {persist} = usePersist();
  const node = persist.getNode(nodeId);
  const autoBonds = persist.getAutoBonds(nodeId);
  const autoSubgroups = persist.getAutoSubgroupBonds(nodeId);

  return (
    <>
      <h2>Auto Labels:</h2>
      <p>Items of <b>{node?.text}</b> will automatically get the following labels:</p>
      <div className="label-container">
        {autoBonds.map(autoBond => (
          <AutoBond key={autoBond.id} bond={autoBond}/>
          ))}
        <AddAutoBond/>
      </div>
      <h2>Auto Subgroups:</h2>
      <p>
        Items of <b>{node?.text}</b> will automatically get the following sub groups:
      </p>
      <div className="label-container">
        {autoSubgroups.map(autoSubgroup => (
          <AutoSubgroup key={autoSubgroup.id} bond={autoSubgroup}/>
        ))}
        <AddAutoSubGroup/>
      </div>
    </>
  )
})

const AddAutoBond = observer(function(){
  const {createAutoBond} = useMetaEditor()


  function createAutoBondToNode(node:AnyNode){
    createAutoBond(node.id)
  }
  return (
    <AddTag
      onNodeSelect={createAutoBondToNode}
      orderBy="last_tagged"
    />
  )
})

function AutoBond(props:{bond:Bond}){
  const {bond} = props;
  const {removeAutoBond, updateAutoPropValue} = useMetaEditor()

  function remove(bondId:string){
    removeAutoBond(bondId)
  }

  function handlePropValueUpdate(bondId:string, params:FilterParam){
    const {
      boolean,
      number,
      valueId,
    } = params;
    
    const variables = {
      bondId,
      boolean,
      number,
      valueId,
    }
    updateAutoPropValue(variables)
  }

  return (
    <Tag
      id={bond.id}
      parentId={bond.parentId}
      valueId={bond.valueId}
      boolean={bond.boolean}
      number={bond.number}
      onRemove={remove}
      onPropValueUpdate={handlePropValueUpdate}
      type={TagType.BOND}
    />
  )
}



function AddAutoSubGroup(){
  const {createAutoSubgroup} = useMetaEditor()

  function createAutoSubgroupToNode(node:AnyNode){
    createAutoSubgroup(node.id)
  }
  return (
    <AddTag
      onNodeSelect={createAutoSubgroupToNode}
      orderBy="last_subgroup"
    />
  )
}


function AutoSubgroup(props:{bond:Bond}){
  const {bond} = props;
  const {removeAutoSubgroup} = useMetaEditor()

  function remove(bondId:string){
    removeAutoSubgroup(bondId)
  }

  return (
    <Tag
      id={bond.id}
      parentId={bond.nodeId}
      valueId=""
      onRemove={remove}
      onPropValueUpdate={()=>{}}
      type={TagType.BOND}
    />
  )
}