import { action } from 'mobx';

import { getDebugLog } from 'common';

import {EditorStoreType, UpdateTextParams} from "./storeTypes";

const log = getDebugLog(false, "updateText");

export const updateText = action(function(
  this:EditorStoreType,
  params: UpdateTextParams,
){
  const {
    nodeId,
    text: newText,
    append=false,
    debounce=true,
  } = params;

  let text = newText
  if (append){
    const node = this.persist.getNode(nodeId);
    text = node.text + " " + text;
  }

  // update the local state immediately
  log("updating text", nodeId, text)
  const payload = {
    nodes: [{
      id:nodeId,
      text,
    }]
  }
  if (debounce){
    this.persist.debounceUpdateObjects(payload)
  } else {
    this.persist.updateObjects(payload)
  }
  // see if the text is still empty
  this.checkSelectedIsEmpty();
})