let BACKEND_URL = ""
let WEBSOCKET_URL = ""
const loc = window.location;

// assign the protocol
if (loc.protocol === "https:") {
  BACKEND_URL = "https:";
  WEBSOCKET_URL = "wss:";
} else {
  BACKEND_URL = "http:";
  WEBSOCKET_URL = "ws:";
}

// get the defined host or take whatever the current host is
const host = process.env.REACT_APP_HOST || loc.host
BACKEND_URL += "//" + host;
WEBSOCKET_URL += "//" + host;

const GRAPHQL_BACKEND_URL = BACKEND_URL + process.env.REACT_APP_BACKEND_PATH
const GRAPHQL_WEBSOCKET_URL = WEBSOCKET_URL + process.env.REACT_APP_WEBSOCKET_PATH


function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const csrftoken = getCookie("csrftoken");

export {
  csrftoken,
  BACKEND_URL,
  WEBSOCKET_URL,
  GRAPHQL_BACKEND_URL,
  GRAPHQL_WEBSOCKET_URL,
}