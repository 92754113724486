import { BondPartial, PersistState } from "controllers/usePersist";
import { 
  Bond,
  FilterParam, 
  NodeKind, 
  PropType, 
  SortParam,
} from "components/graphql";

import {ErrorStore} from "controllers/useErrors"

export enum VIEW {
  EDITOR = "editor",
  META = "meta",
  SORT = "sort",
}

export enum LAYOUT {
  COLUMNS = "columns",
  ROWS = "rows",
}

export interface ToggleChildrenParams {
  bondId:string
  force?:boolean
}
export interface AdjustPositionParams {
  parentBondId:string
  parentId:string
  nodeId:string
  adjustment:number
}

export interface SetPositionIndexParams {bondId:string, index:number}

export interface DeleteBondParams {bondId:string, parentBondId?:string, updateSelection?:boolean}

export interface CreateChildBondParams  {
  bondId?:string,
  parentId?:string,
  idx?:number,
  text?:string,
  positionIndex?:number,
}

export interface SetSelectedBondParams {parentId:string, nodeId:string}

export interface ConvertToSubgroupParams {bondId:string}

export interface CreateParentBondParams {nodeId:string, parentId?:string}

export interface CreateSiblingBondParams  {bondId:string, parentBondId:string, text?:string, before?:boolean, positionIndex?:number}

export interface AddFilterParams {
  parentId?:string,
  valueId?:string | null,
  boolean?:boolean,
  number?:number | null,
  datetime?:string | null,
}

export interface UpdateFilterParams {
  idx:number|string,
  payload:FilterParam,
}

export interface UpdateSortParamParams {
  idx:number,
  payload:Partial<SortParam>,
}

export interface UpdateKindParams  {nodeId:string, kind:NodeKind, propType:PropType}

export interface ReplaceChildParams {
  bondId:string,
  newNodeId:string,
  parentBondId?:string,
}

export interface ReplaceParentParams {
  bondId:string,
  parentBondId:string,
  newParentBondId:string,
  indent:boolean,
}

export interface UpdatePropValueParams {
  bondId:string,
  valueId?:string,
  datetime?:string,
  number?:number | null,
  boolean?:boolean,
}

export interface UpdateTextParams {
  nodeId:string,
  text:string,
  append?:boolean,
  debounce?:boolean,
}

export interface DisplayParentBondParams {
  bond: Bond,
  filterParams: FilterParam[],
}

export interface SelectedBond {
  isRoot?:boolean,
  bondId:string
  parentId:string
  nodeId:string
  grandBondId:string
  parentBondId:string
}
export interface EditorStoreType {
  persist:PersistState
  errorStore:ErrorStore
  
  // observables
  rootNodeId?: string | null
  view:VIEW
  layout:LAYOUT 
  filtersTouched:boolean 
  filterParams:FilterParam[] 
  defaultFilterParams:FilterParam[]
  sortParams:SortParam[]
  sortParamsTouched:boolean 
  defaultSortParams:SortParam[]
  // the node that has focus
  selectedNodeId: string 
  selectedNodeIdRenderCount: number
  // A map of which bonds should display their children
  showChildren: {[bondId:string]:boolean}
  openFilter: boolean
  // Information about the bond that has focus
  selectedBond?: SelectedBond | null
  showSelectedLookup: boolean
  selectedIsEmpty: boolean

  // computed
  hasSortParams:boolean
  canSaveView:boolean
  rootText:string

  // computed functions
  getShowChildren(bondId:string):boolean

  // methods
  isRoot(nodeId:string):boolean
  toggleChildren(params:ToggleChildrenParams):void
  getFilteredChildBonds(bondId:string):Bond[]
  orderBonds(parentId:string, bonds:Bond[]):void
  getOrderValue(sortParam:SortParam, bond:Bond):any
  getNewPosition(parentBondId:string, nodeId:string, adjustment:number):{bond:Bond, position:number}
  adjustPosition(params: AdjustPositionParams):void
  setPositionIndex(params: SetPositionIndexParams):void
  deleteBond(params: DeleteBondParams):void
  createChildBond(params: CreateChildBondParams):void
  setSelectedBond(params: SetSelectedBondParams):void
  convertToSubgroup(params: ConvertToSubgroupParams):void
  addBondsForFilters(bonds:BondPartial[], nodeId:string):void
  getSiblingBond(bondId:string, parentBondId:string, offset:number):Bond | void
  createParentBond(params: CreateParentBondParams):void
  createSiblingBond(params: CreateSiblingBondParams):void
  createManySiblingBonds(params: CreateSiblingBondParams[]):void
  setFilterParams(params:FilterParam[], filtersTouched:boolean):void
  setDefaultFilterParams(params:FilterParam[]):void
  removeFilter(idx:number|string):void
  addFilter(params: AddFilterParams):void
  updateFilter(params: UpdateFilterParams):void
  setSortParams(params:SortParam[], touched:boolean):void
  setDefaultSortParams(params:SortParam[]):void
  addSortParam(params:SortParam):void
  updateSortParam(params:UpdateSortParamParams ):void
  removeSortParam(idx:number):void
  updateKind(params: UpdateKindParams):void
  setOpenFilter(openFilter:boolean):void
  openMetaEditor():void
  openEditor():void
  openSort():void
  replaceChild(params: ReplaceChildParams):void
  replaceParent(params: ReplaceParentParams):void
  updatePropValue(params: UpdatePropValueParams):void
  updateText(params:UpdateTextParams):void
  updateViewParams():void
  getRoot():void
  displayParentBond(params:DisplayParentBondParams):void
  setSelectedBondInfo(bond:SelectedBond):void
  toggleLayout():void
  // selectedBond
  convertSelectedToSubgroup():void
  setShowSelectedLookup(show:boolean):void
  checkSelectedIsEmpty():void
}