import React, { createContext, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";

import {useContextController} from "common"

import { ContextValue, State} from "./types";
import defaultState from "./constants";
import * as reducerFunctions from "./reducers"
import methodHooks from "./hooks";

type ProviderProps = {
  datetime?: string | null,
  children: React.ReactNode,
  onDatetimeSelect?(datetime:string):void,
}

export const Provider = observer((props: ProviderProps)=>{
  const {
    children,
    datetime,
    onDatetimeSelect,
  } = props;

  let initialState = defaultState()
  
  // enrich the initial state with any props passed in
  initialState = reducerFunctions.setDatetime(initialState, {
    datetime: datetime ? new Date(datetime) : null,
  })

  const passedValues = {
    // inherited methods or values
    onDatetimeSelect,
  }
  const context: ContextValue = useContextController<State, ContextValue>(initialState, reducerFunctions, methodHooks, passedValues)

  const {dispatch} = context
  // Update the state when the datetime prop changes
  useEffect(
    () => {
      dispatch({
        type:"setDatetime",
        payload:{
          datetime: datetime ? new Date(datetime) : null,
        }
      })
    },
    [
      dispatch,
      datetime,
    ]
  )

  return (<Context.Provider value={context}>{children}</Context.Provider>);
})

/**
 * A convenience hook for accessing the context value
 */
export default function useSelectDatetime(){
  return useContext(Context);
}

const Context = createContext<ContextValue>({} as ContextValue);





