import { ContextValue } from "../types";

import useRootNode from "./useRootNode";
import useSelectedNode from "./useSelectedNode";
import useUpdateQueryString from "./useUpdateQueryString";
import useRootTitle from "./useRootTitle";
import useHelper from "./useHelper";

const methodHooks: {(context: ContextValue): void}[] = [
  useHelper,
  useRootNode,
  useSelectedNode,
  useUpdateQueryString,
  useRootTitle,
];

export default methodHooks