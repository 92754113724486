import React from "react";
import { observer } from "mobx-react-lite";

import useEditor from "components/editor/useEditor";
import useLineContext from "./useLineContext";
import { ChevronIcon } from "common/icons";

const ToggleChildrenButton = observer(function(){
  const {bondId} = useLineContext();

  const {editor} = useEditor();
  const {toggleChildren} = editor
  
  // the root does not have a bond
  if (!bondId) return null;

  const bond = editor.persist.getBond(bondId);
  const nodeId = bond?.nodeId;
  const isParent = editor.persist.isParent(nodeId);
  const isOpen = editor.getShowChildren(bondId);

  function handleClick(){
    toggleChildren({bondId});
  }

  if (!isParent) return null;
  return (
    <button
      data-selection-nav
      onClick={handleClick}
    >
      <ChevronIcon direction={isOpen ? "down" : "right"}/>
    </button>
  )
})

export default ToggleChildrenButton;