import { makeObservable, observable, action , computed} from "mobx"

export default class LoadingStore {
  
  @observable loadingCount: number = 0

  constructor() {
    makeObservable(this)
  }

  @computed get isLoading() {
      return this.loadingCount > 0
  }

  @action startLoading = () => {
    this.loadingCount++
    console.log("start loading", this.loadingCount)
  }
  @action stopLoading = () => {
    this.loadingCount--
    if (this.loadingCount < 0) {
      this.loadingCount = 0
    }
    console.log("stop loading", this.loadingCount)
  }
}