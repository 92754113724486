import { action } from "mobx"

import { BondPartial } from "controllers/usePersist";
import { EditorStoreType, ReplaceChildParams } from "./storeTypes";
import { getDebugLog } from "common";
import { BondKind } from "components/graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = getDebugLog(false, "replaceChild");

/**
 * This is used when a node is selected from the dropdown menu
 * to replace the current node in a bond.
 */
export const replaceChild = action(function(
  this: EditorStoreType,
  params: ReplaceChildParams,
){
  const {
    bondId,
    parentBondId,
    newNodeId,
  } = params;

  log("params", params)

  const bonds:BondPartial[] = []

  const bond = this.persist.getBond(bondId);

  // archive the old bond
  bonds.push({
    id: bondId,
    archivedAt:this.persist.now(),
  })

  // create a new bond to the new node
  bonds.push({
    kind:bond.kind,
    parentId:bond.parentId,
    nodeId:newNodeId,
    position:bond.position,
    archivedAt:null,
  })
  if (parentBondId){
    const parentBond = this.persist.getBond(parentBondId);
    // if the parent bond is a subgroup, add it's parent as a filter as well
    if (parentBond.kind === BondKind.SUBGROUP){
      const subGroupBond = {
        parentId:parentBond.parentId,
        nodeId:newNodeId,
        archivedAt:null,
      }
      log("adding subgroup parent bond", subGroupBond)
      bonds.push(subGroupBond)
    }
  }

  this.addBondsForFilters(bonds, newNodeId);

  this.persist.updateObjects({bonds})

  // set the new bond as selected
  this.setSelectedBond({
    parentId:bond.parentId,
    nodeId:newNodeId,
  })
})