import React from 'react';
import clsx from 'clsx';

import { getDebugLog } from 'common';
import Button from 'components/Button';
import { dateDisplay } from 'common/utils/dates';

import { ClickOutsideContainer } from 'components/ClickOutsideContainer/ClickOutsideContainer';
import LineInput from 'components/LineInput/LineInput';

import useSelectDatetime, {View} from './useSelectDatetime';
import Calendar from './Calendar';

const log = getDebugLog(false, "SelectDatetime");

export default function SelectDatetimeContainer(){
  const {close, view} = useSelectDatetime();

  const containerClasses = clsx(
    "dropdown-container",
    view === View.CALENDAR && "is-open",
  )
  
  if (view === View.DISPLAY) return <Display/>;
  
  return (
    <ClickOutsideContainer 
      className={containerClasses}
      onClose={close}
    >
      <FreeInput/>
      <div
        className="dropdown"
      >
        <ViewRouter/>
      </div>
    </ClickOutsideContainer>
  )
}

function ViewRouter(){
  const {view} = useSelectDatetime();
  if (view === View.DISPLAY) return null;
  if (view === View.EDIT) return <Editor/>
  if (view === View.CALENDAR) return <Calendar/>
  return <div>view: {view}</div>;
} 

/**
 * The displayed value of the datetime in the tag
 */
function Display(){
  const {openEditor, datetime} = useSelectDatetime();
  const value = datetime ? dateDisplay(datetime) : "Not Set";
  return (
    <Button
      tooltip="Edit this date."
      isSelectable
      onClick={openEditor}
    >
      {value}
    </Button>
  )
}

/**
 * The input field for the user to free type a date
 * in the format YYYY/MM/DD
 */
function FreeInput(){
  const {select, localDatetimeText, setLocalDatetimeText, stateRef} = useSelectDatetime();
  
  function selectDate(){
    const datetime = stateRef.current.localDatetimeText;
    if (datetime === "") return;
    const newDate = parseDate(datetime);
    log("selectDate", datetime, newDate)
    select(newDate);
  }

  return (
    <>
      <LineInput
        autoFocus
        autoSelectionId
        text={localDatetimeText}
        onTextChange={setLocalDatetimeText}
        onEnter={selectDate}
        placeholder='YYYY/MM/DD'
      />
    </>
  )
}

function Editor(){
  const {select, openCalendar, selectRelative} = useSelectDatetime();
  return (
    <>
      <Button tooltip="Select today's date" autoSelectionId onClick={() => selectRelative(0)}>Today</Button>
      <Button tooltip="Open a calender" autoSelectionId onClick={openCalendar}>Calendar</Button>
      <Button tooltip="Remove the selected date" autoSelectionId onClick={()=>select(null)}>Clear</Button>
    </>
  )
}


function parseDate(datetime:string) {
  const parts = datetime.split("/");

  const year = parseInt(parts[0], 10);
  const month = parts[1] ? parseInt(parts[1], 10) - 1 : 0; // month is 0-indexed in JavaScript
  const day = parts[2] ? parseInt(parts[2], 10) : 1;

  return new Date(year, month, day);
}
