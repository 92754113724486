import React, { createContext, useContext} from "react";
import { observer } from "mobx-react-lite";

import { ContextValue } from "./types";
import methodHooks from "./hooks";


export const Context = createContext<ContextValue>({} as ContextValue);

type ProviderProps = {
  children: React.ReactNode,
  nodeId?: string,
}

export const Provider = observer(function(props: ProviderProps){
  const {
    children,
    nodeId,
  } = props;

  const rootNodeId = nodeId;

  let contextValue = {
    rootNodeId,
  } as unknown as ContextValue;

  methodHooks.forEach((hook) => {
    hook(contextValue);
  });

  
  return (<Context.Provider value={contextValue} >{children}</Context.Provider>);
})

/**
 * A convenience hook for accessing the context value
 */
 export default function useViewContext(){
  return useContext(Context);
}