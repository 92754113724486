import {EditorStoreType} from "./storeTypes";

import {getDebugLog} from "common"
import { 
  Bond,
  BondKind,
  FilterParam,
} from "components/graphql";

const log = getDebugLog(false, "getFilteredChildBonds");
/**
* Get a list of the child bonds that should be displayed
* below the given bond.
*/
export const getFilteredChildBonds = function(
  this:EditorStoreType,
  bondId:string
){
  const {
    rootNodeId,
  } = this;

  const {
    getBond,
    getChildBonds,
    getSubgroupNodeIds,
  } = this.persist;

  let childBonds: Bond[] = [];
  let kind: BondKind = BondKind.CONTENT;
  let nodeId:string = '';
  let parentNodeId:string = '';
  let filterParams: FilterParam[] = [];

  /**
  * if the bond doesn't exist, this is the root node.
  * Start with all of the root node's children.
  * 
  * Otherwise, start with the bond.nodeIds's children.
  */
  log("filtering", {bondId, rootNodeId})

  if (!bondId){
    nodeId = rootNodeId!;
    // filter root children by the filter params
    filterParams = [...this.filterParams];
  } else {
    let bond = getBond(bondId);
    nodeId = bond.nodeId;
    parentNodeId = bond.parentId;
    kind = bond.kind!;
  }
  
  if (kind === BondKind.SUBGROUP) {
    // if the parentNodeId is the root node, also apply the filter params
    // to these children
    if (parentNodeId === rootNodeId){
      filterParams = [...this.filterParams];
    }
    // add the bond parent as a filter param
    filterParams.push({
      parentId: parentNodeId,
    })
  }

  /**
   * Filter out children that should appear in a subgroup
   */
  const subgroupNodeIds = getSubgroupNodeIds(nodeId);
  subgroupNodeIds.forEach((subgroupNodeId) => {
    filterParams.push({
      parentId: subgroupNodeId,
      include: false,
    })
  })

  // get the child bonds of the node
  childBonds = getChildBonds(nodeId);

  // filter out any archived bonds
  childBonds = childBonds.filter((bond) => !bond.archivedAt);

  // apply the filters
  if (filterParams.length > 0){
    log("filterParams", filterParams, childBonds)
    
    // filter children by the filter params
    // if any filter excludes the bond return false
    filterParams.forEach((filterParam) => {
      const {
        parentId: filterParentId,
        valueId: filterValueId,
        boolean,
        number,
        datetime,
        include=true,
        operator="",
      } = filterParam;

      childBonds = childBonds.filter((childBond) => {
        log("filter bond?", {childBond, filterParam})
        
        // always include subgroup unless it's a subgroup of a subgroup
        // NOTE: This is a temp fix until subgroups are more sophisticated
        const isSubgroup = childBond.kind === BondKind.SUBGROUP;
        const isParentSubgroup = kind === BondKind.SUBGROUP;
        if (isSubgroup && !isParentSubgroup) return true;

        let isMatch = null;
        
        // check if the childBond node has a bond to the filter parent
        const filterBond = this.persist.getBondFromNodes(filterParentId!, childBond.nodeId);
        
        // if filterBond doesn't exist or is archived, it doesn't match the filter
        if (!filterBond || filterBond.archivedAt) {
          isMatch = false
        } 
        // if valueId is defined, check if the bond.valueId matches the filter.valueId
        else if (filterValueId){
          isMatch = compareValues(filterBond.valueId, filterValueId, operator)
        }
        else if (boolean !== undefined){
          isMatch = compareValues(filterBond.boolean, boolean, operator)
        }
        else if (number !== undefined){
          isMatch = compareValues(filterBond.number, number, operator)
        }
        else if (datetime){
          if (!filterBond.datetime) {
            isMatch = false
          } else {
            isMatch = compareValues(
              new Date(filterBond.datetime),
              new Date(datetime),
              operator
            )
          }
        // if the filter doesn't have any properties, it matches
        } else {
          isMatch = true;
        }
        // include the bond if isMatch matches the filter.include param
        log("isMatch", isMatch, include)
        return isMatch === include;
      })
    })
  }

  // order the bonds
  this.orderBonds(nodeId, childBonds);

  return childBonds
}

function compareValues(value1:any, value2:any, operator?:string):boolean{
  log("compareValues", {value1, value2, operator})
  log("compareValues typeof value1", typeof value1)
  log("compareValues typeof value2", typeof value2)
  // Check if the values are date strings and convert them to Date objects
  if (isDate(value1)) value1 = new Date(value1);
  if (isDate(value2)) value2 = new Date(value2);

  switch (operator) {
    case "gt":
      return value1 > value2;
    case "gte":
      return value1 >= value2;
    case "lt":
      return value1 < value2;
    case "lte":
      return value1 <= value2;
    default:
      // For equality, compare date values directly if they are dates
      if (value1 instanceof Date && value2 instanceof Date) {
        return value1.getTime() === value2.getTime();
      }
      return value1 === value2;
  }
}

function isDate(value:any) {
  return !isNaN(Date.parse(value));
}