import { useEffect, useState} from "react";
import { ContextValue } from "../types";
import useEditor from "components/editor/useEditor";

/**
 * A context hook adds an effect responsible for updating the position
 * of bonds as they are compared.
 */
export default function useSetPosition(context:ContextValue){
  const { sortedBonds } = context;
  const {editor} = useEditor();
  const {setPositionIndex} = editor;

  const [lastLength, setLastLength] = useState(0);

  useEffect(
    () => {
      console.log("sortedBonds:", sortedBonds.length, "lastLength:", lastLength)
      if (sortedBonds.length === 0) return;
      if (sortedBonds.length === lastLength) return;

      // loop over the new bonds and update their position
      for (let i = lastLength; i < sortedBonds.length; i++) {
        console.log("set position:", sortedBonds[i].id, i);
        setPositionIndex({bondId: sortedBonds[i].id, index: i})
      }
      // update the lastLength
      setLastLength(sortedBonds.length);
    }, 
    [sortedBonds, setPositionIndex, lastLength, setLastLength]
  );

}