import React from 'react';
import { observer } from 'mobx-react-lite';

import { PlusIcon, SearchIcon, QuestionIcon } from 'common/icons';
import useRoot from "controllers/useRoot";
import { ActionBarContainer, ActionButton } from 'components/ActionBar';


export default function NavActionBar(){
  return (
    <ActionBarContainer>
      <SearchButton/>
      <RandomButton/>
      <NewNodeButton/>
    </ActionBarContainer>
  )
}

const SearchButton = observer(()=>{
  const {openLaunchPad} = useRoot()
  return (
    <ActionButton onClick={openLaunchPad}>
      <SearchIcon/>
      <div className="text">Search</div>
    </ActionButton>
  )
})

const NewNodeButton = observer(()=>{
  const {createNewPage} = useRoot()
  return (
    <ActionButton onClick={createNewPage}>
      <PlusIcon/>
      <div className="text">New</div>
    </ActionButton>
  )
})

const RandomButton = observer(()=>{
  const {openRandomPage} = useRoot()
  return (
    <ActionButton onClick={openRandomPage}>
      <QuestionIcon/>
      <div className="text">Random</div>
    </ActionButton>
  )
})
