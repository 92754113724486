import React from 'react';

export function TextSelectionIcon() {
  return (
    <svg
      className='icon'
      width="25" height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M4 0C1.79086 0 0 1.79086 0 4V21C0 23.2091 1.79086 25 4 25H21C23.2091 25 25 23.2091 25 21V4C25 1.79086 23.2091 0 21 0H4ZM5 4C3.89543 4 3 4.89543 3 6C3 7.10457 3.89543 8 5 8H10.5V21C10.5 22.1046 11.3954 23 12.5 23C13.6046 23 14.5 22.1046 14.5 21V8H20C21.1046 8 22 7.10457 22 6C22 4.89543 21.1046 4 20 4H12.5H5Z"
        fill="currentColor"
        />
    </svg>
  );
}
