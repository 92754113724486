import { action } from "mobx"

import { EditorStoreType, SelectedBond } from "./storeTypes";


export const setSelectedBondInfo = action(function(
  this: EditorStoreType,
  selectedBond:SelectedBond,
){
  this.selectedBond = selectedBond;
  this.setShowSelectedLookup(false);
  this.checkSelectedIsEmpty();
})

export const convertSelectedToSubgroup = action(function(
  this: EditorStoreType,
){
  if (!this.selectedBond) return;
  const { bondId } = this.selectedBond;
  this.convertToSubgroup({bondId});
  this.setShowSelectedLookup(true);
})

export const setShowSelectedLookup = action(function(
  this: EditorStoreType,
  showSelectedLookup:boolean,
){
  this.showSelectedLookup = showSelectedLookup;
})

export const checkSelectedIsEmpty = action(function(
  this: EditorStoreType,
){
  if (!this.selectedBond) {
    this.selectedIsEmpty = true;
    return;
  }
  const { nodeId } = this.selectedBond;
  const {text} = this.persist.getNode(nodeId);
  this.selectedIsEmpty = text.trim() === "";
})