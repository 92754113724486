import React from "react"

import View from "./Sort"
import {Provider} from "./useSort"

export default function Sort(){
  return (
    <Provider>
      <View/>
    </Provider>
  )
}