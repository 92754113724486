import { State, LoadMorePayload } from "../types";

export default function loadMore(state:State, payload:LoadMorePayload):State{
  const {nodes} = payload;
  
  // add the new nodes to the existing state nodes
  const existingNodeIds = state.nodeIds;
  const newIds = nodes.map(({id}) => id);

  // prevent pagination if the number of nodes returned is less than the page count
  const hasMore = newIds.length === state.pageSize;
  return {
    ...state,
    nodeIds: [...existingNodeIds, ...newIds],
    hasMore,
    // set the active suggestion to the first new node
    activeSuggestion: nodes.length,
    // add 1 to the page number
    pageNumber: state.pageNumber + 1,
  }
}