import { gql } from '@apollo/client';
import { action } from 'mobx';

import { apollo } from "common/apollo";

import {EditorStoreType} from "./storeTypes";

export const updateViewParams = action(function(
  this: EditorStoreType
){
  const {rootNodeId, filterParams, sortParams} = this;
  const variables = {
    nodeId:rootNodeId,
    filterParams,
    sortParams,
  };
  apollo.mutate({
    fetchPolicy: "no-cache",
    mutation:GQL,
    variables
  }).then(({data})=>{
    const res =  data.nodeUpdateViewParams;
    if (this.errorStore.hasResponseError(res))return;
    this.setDefaultFilterParams(filterParams);
    this.setDefaultSortParams(sortParams);
  })
})

const GQL = gql`
  mutation NodeUpdateViewParams(
    $nodeId: ID!,
    $filterParams: [FilterParamInput],
    $sortParams: [SortParamInput],
  ){
    nodeUpdateViewParams(
      nodeId: $nodeId,
      filterParams: $filterParams,
      sortParams: $sortParams,
    ){
      success
      errorMessage
    }
  }
`