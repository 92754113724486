import { action } from "mobx"

import { CreateChildBondParams, EditorStoreType } from "./storeTypes";
import { BondKind } from "components/graphql";
import { BondPartial } from "controllers/usePersist";
import { getDebugLog } from "common";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = getDebugLog(false, "createChildBond");

/**
 * Create a new content child bond for the given bondId / parentId
 */
export const createChildBond =  action(function(
  this:EditorStoreType,
  params: CreateChildBondParams,
){
  let {
    parentId = "",
    bondId = "",
    idx = 0,
    text = "",
    positionIndex = 0,
  } = params;
  log("params", params)

  if (!bondId && !parentId) {
    throw new Error("Must defined parent or bondId");
  }
  
  // create the new node
  const newNodeId = this.persist.newId();
  const node = {
    id: newNodeId,
    text,
    createdAt: this.persist.now(),
  }
  
  // if bond if defined, then use it's nodeId as the parentId
  let subGroupParentId = null;
  if (bondId){
    const bond = this.persist.getBond(bondId);
    parentId = bond.nodeId;

    // if the bond is a subgroup, add it's parent as a filter as well
    if (bond.kind === BondKind.SUBGROUP){
      subGroupParentId = bond.parentId;
    }
  }

  // generate a new position for the new bond
  const childBonds = this.getFilteredChildBonds(bondId); 
  let position = this.persist.generatePositionAfterIndex(childBonds, idx) + positionIndex;
  // add the positionIndex to the position to support creating
  // multiple siblings at once
  position = position + positionIndex;

  // create the new child bond
  const newBond = {
    id: this.persist.newId(),
    createdAt: this.persist.now(),
    parentId,
    nodeId: newNodeId,
    position,
  }
  const bonds:BondPartial[] = [newBond]
  log("newBond", newBond)

  /**
   * If the parent bond is a subgroup
   * also create a bond to the parent's parent.
  */
  if (subGroupParentId) {
    const subGroupBond = {
      id: this.persist.newId(),
      createdAt: this.persist.now(),
      parentId:subGroupParentId,
      nodeId: newNodeId,
    }
    log("subGroupBond", subGroupBond)
    bonds.push(subGroupBond)
  }

  this.addBondsForFilters(bonds, newNodeId);

  log("bonds", bonds)
  
  this.persist.updateObjects({
    nodes: [node, {id:parentId, isParent:true}],
    bonds,
  })

  // show the children of the new parent bond
  this.toggleChildren({
    bondId:bondId,
    force:true,
  });

  // set the new bond as selected
  this.setSelectedBond({
    nodeId: newBond.nodeId,
    parentId: newBond.parentId,
  })
})