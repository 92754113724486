import React from 'react';

export function MenuIcon() {
  return (
    <svg
      className='icon'
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="5"
        width="18"
        height="2"
        rx="1"
        ry="1"
        fill="currentColor"
      />
      <rect
        x="3"
        y="11"
        width="18"
        height="2"
        rx="1"
        ry="1"
        fill="currentColor"
      />
      <rect
        x="3"
        y="17"
        width="18"
        height="2"
        rx="1"
        ry="1"
        fill="currentColor"
      />
    </svg>
  );
}
