import { has } from "lodash";
import { makeObservable, observable, action, computed } from "mobx"
import { ResObj } from "components/graphql";

export interface errorData {
  login?:boolean
  root_not_found?:boolean
}

export default class ErrorStore {
  constructor() {
    makeObservable(this);
  }

  @observable errors: string[] = [];
  @observable errorData: object[] = [];
  @observable showLogin: boolean = false;

  @computed get hasErrors() {
    return this.errors.length > 0;
  }

  @action setShowLogin = (showLogin:boolean) => {
    this.showLogin = showLogin;
  }

  @action addError = (error: string, errorData: errorData = {login:false}) => {
    if (errorData.login){
      this.setShowLogin(true)
      return
    }
    // quietly ignore root_not_found errors
    if (errorData.root_not_found) return;

    this.errors = [...this.errors, error];
    this.errorData = [...this.errorData, errorData];
  }

  @action removeError = (index: number) => {
    if (!has(this.errors, index)) return
    this.errors.splice(index, 1);
    this.setShowLogin(false)
    if (!has(this.errorData, index)) return
    this.errorData.splice(index, 1);
  }

  @action clearErrors = () => {
    this.errors = [];
    this.errorData = [];
  }

  @action hasResponseError = (res: ResObj) => {
    if (res?.errorMessage) {
      let errorData = res?.errorData ? JSON.parse(res?.errorData) : {};
      this.addError(res?.errorMessage, errorData);
      return true;
    }
    return false
  }

  @action onGqlError = (err: any) => {
    this.addError(err.message);
  }
}
