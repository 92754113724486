import { useCallback } from "react";
import { ContextValue } from "../types";

/**
 * A context hook that provides a method for setting 
 * one of the options as greater than the other.
 */
export default function useSetDecision(context:ContextValue){
  const { dispatch, stateRef } = context;

  context.setDecision = useCallback(
    (greaterId: string) => {
      const {optionA, optionB} = stateRef.current;
      
      // set the lesserId to the id of the option that is not greaterId
      const lesserId = optionA!.id === greaterId ? optionB!.id : optionA!.id;
      
      dispatch({
        type: "setDecision",
        payload: {
          greaterId,
          lesserId,
        },
      });
    },
    [dispatch, stateRef]
  );

}