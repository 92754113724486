import React, { createContext, useContext }  from "react";

import {useContextController} from "common"

import { ContextValue, State} from "./types";

import defaultState from "./constants";
import * as reducerFunctions from "./reducers"
import methodHooks from "./hooks";

type ProviderProps = {
  children: React.ReactNode,
}

export function Provider(props: ProviderProps){
  const {
    children,
  } = props;

  let initialState = defaultState({})
  
  const passedValues = {
    // inherited methods or values
  }
  const value: ContextValue = useContextController<State, ContextValue>(initialState, reducerFunctions, methodHooks, passedValues)

  return (<Context.Provider value={value}>{children}</Context.Provider>);
}

/**
 * A convenience hook for accessing the context value
 */
export default function useView(){
  return useContext(Context);
}

const Context = createContext<ContextValue>({} as ContextValue);





