import {useCallback} from "react"
import { gql, useMutation } from '@apollo/client';

import { getDebugLog } from "common";
import { FRAG_NODE } from 'components/graphql';
import useErrors from "controllers/useErrors";

import { ContextValue } from "../types";
import { localNode } from "../state";

const log = getDebugLog(false, "usePersistNode");
/**
 * Send the updated node to the server
 */
export default function usePersistNode(context:ContextValue){
  const {persist} = context;

  const {hasResponseError, onGqlError} = useErrors()
  
  const [mutate] = useMutation(GQL, {onError:onGqlError});
  
  context.persistNode = useCallback(
    (nodeId:string)=>{
      log("persistNode", nodeId)
      // get the local node value
      const node = localNode.get(nodeId);

      if (!node) {
        log("node not found", nodeId);
        persist.removePersistNodeId(nodeId)
        return;
      }

      // sync it to the backend
      const variables = {node};
      mutate({variables}).then(({data, errors})=>{
        if (errors) {
          log("errors", errors)
          persist.removePersistNodeId(nodeId)
          return;
        }
        const res = data.nodePersist;
        if (hasResponseError(res)){
          log("checkForErrors", res)
          persist.removePersistNodeId(nodeId)
          return false
        };
        const {node} = res;

        log("node persisted", node)
        // remove the node id from the persistNodeIds
        persist.postPersistNode({node});
      });
    },
    [persist, mutate, hasResponseError]
  )
}

const GQL = gql`
  mutation NodePersist(
    $node: NodeInput!,
  ) {
    nodePersist(
      node: $node,
    ) {
      node {
        ...NodeBase
      }
      success
      errorMessage
      errorData
    }
  }
  ${FRAG_NODE}
`;