import { dateDisplay } from "common/utils/dates";
import {State, SetDatetimePayload} from "../types";

export function setDatetime(state:State, payload:SetDatetimePayload): State {
  let { datetime } = payload;
  datetime = datetime ? new Date(datetime) : null;
  const localDatetime = datetime;
  const localDatetimeText = datetime ? dateDisplay(datetime) : "";
  
  return {
    ...state,
    datetime,
    localDatetime,
    localDatetimeText,
  }
}