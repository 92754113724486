import { action } from "mobx"

import { EditorStoreType, UpdateSortParamParams } from "./storeTypes";
import { SortParam } from "components/graphql";


const SORT_REMOVE_KEYS = ["__typename", "parent"]
const SORT_DEFAULTS = {
  parentId: [undefined, null],
  desc: [undefined, null, false],
  key: ["", null, undefined],
}

function cleanSortParams(sortParams:SortParam[]):SortParam[]{
  // for each sortParam, remove keys with null values
  sortParams.forEach(
    (param)=>{
      Object.keys(param).forEach(
        (key)=>{
          const value: any = param[key as keyof SortParam];
          if (
            SORT_REMOVE_KEYS.includes(key)
            ||SORT_DEFAULTS[key as keyof SortParam].includes(value)
          ) {
            delete param[key as keyof SortParam]
          }
        }
      )
    }
  )
  return sortParams
}


export const setSortParams = action(function(
  this:EditorStoreType,
  sortParams:SortParam[],
  touched:boolean,
){
  this.sortParams = cleanSortParams(sortParams);
  this.sortParamsTouched = touched;
})

export const setDefaultSortParams = action(function(
  this:EditorStoreType,
  sortParams:SortParam[],
){
  this.defaultSortParams = cleanSortParams(sortParams);
})

export const addSortParam = action(function(
  this:EditorStoreType,
  sortParam:SortParam,
){
  this.setSortParams([...this.sortParams, sortParam], true);
})

export const updateSortParam = action(function(
  this:EditorStoreType,
  params: UpdateSortParamParams,
){
  const {
    idx,
    payload,
  } = params;
  const newSortParams = [...this.sortParams];
  newSortParams[idx] = {
    ...newSortParams[idx],
    ...payload,
  }
  this.setSortParams(newSortParams, true);
})

export const removeSortParam = action(function(
  this:EditorStoreType,
  idx:number,
){
  const newSortParams = [...this.sortParams];
  newSortParams.splice(idx, 1);
  this.setSortParams(newSortParams, true);
})

