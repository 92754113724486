import useDocumentTitle from "common/hooks/useDocumentTitle"

import {
  ContextValue,
} from "../types";

export default function useRootTitle(context:ContextValue){
  const {editor} = context;
  useDocumentTitle(editor.rootText || "BrainSpace")
}
