import React from "react";
import { createContext, useContext } from "react";
import { observer } from "mobx-react-lite"

import ErrorStore from "./ErrorStore";

type ProviderProps = {
  children: React.ReactNode,
}

export const errorStore = new ErrorStore();

const Context = createContext<ErrorStore>(errorStore);

export const Provider = observer(function(props: ProviderProps){
  const {
    children,
  } = props;

  return (<Context.Provider value={errorStore}>{children}</Context.Provider>);
})

/**
 * A convenience hook for accessing the context value
 */
export default function useErrors(){
  return useContext(Context);
}






