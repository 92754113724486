import { action } from "mobx";

import { DeleteBondParams, EditorStoreType } from "./storeTypes";
import { BondPartial } from "controllers/usePersist";
import { BondKind } from "components/graphql";

/**
 * Mark a bond as archived
 */
export const deleteBond = action(function(
  this:EditorStoreType,
  params: DeleteBondParams,
){
  const {
    bondId,
    parentBondId = "",
    updateSelection = true,
  } = params;

  if (!bondId) return;
  
  const bond = this.persist.getBond(bondId);
  const parentBond = this.persist.getBond(parentBondId);

  const bonds:BondPartial[] = [{
    id: bondId,
    archivedAt: this.persist.now(),
  }];

  // when removing the bond from a subgroup, also remove the bond
  // between the bond child and subgroup parent
  if (parentBondId && parentBond?.kind === BondKind.SUBGROUP){
    // get the parentBonds parents' child bonds
    const otherBond = this.persist.getBondFromNodes(parentBond.parentId,  bond.nodeId)
    if (otherBond) {
      bonds.push({
        id: otherBond.id,
        archivedAt: this.persist.now(),
      })
    }
  }

  // archive hidden bonds
  bonds.forEach(aBond=>{
    // if there is a valueId, also archive the node's bond to the value
    if (!aBond.valueId) return;
    const valueBond = this.persist.getBondFromNodes(aBond.valueId, aBond.valueId)
    if (!valueBond) return;
    bonds.push({
      id: valueBond.id,
      archivedAt: this.persist.now(),
    })
  })

  // select the previous sibling, or the parent
  if (updateSelection) {
    const childBonds = this.getFilteredChildBonds(parentBondId);
    const idx = this.persist.getBondIdx(childBonds, bondId);
    let prevBond = childBonds[idx - 1];
    if (prevBond){
      // set the previous sibling as selected
      this.setSelectedBond({
        parentId: prevBond.parentId,
        nodeId: prevBond.nodeId,
      })
    } else if (bond.parentId === this.rootNodeId){
      // set the root as selected
      this.setSelectedBond({
        parentId: "",
        nodeId: this.rootNodeId,
      })
    } else {
      // set the parent as selected
      this.setSelectedBond({
        parentId: bond.parentId,
        nodeId: bond.parentId,
      })
    }
  }

  this.persist.updateObjects({
    bonds,
  })
})
