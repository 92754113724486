import React from 'react';

export function QuestionIcon() {
  return (
    <svg
      className="icon"
      width="24"
      height="24"
      viewBox="0 0 20 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 5 6 A 5 5 0 1 1 10 12 L 10 16" 
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 22L10 22"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
