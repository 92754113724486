import { State, SetActiveSuggestionPayload } from "../types";

/**
 * updates the currently selected suggestion index
 */
 export default function setActiveSuggestion(state:State, payload:SetActiveSuggestionPayload): State {
  let {activeSuggestion} = payload

  if (activeSuggestion === state.nodeIds.length) activeSuggestion = -1

  return {
    ...state,
    activeSuggestion,
    touched: true
  }
}