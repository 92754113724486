import { useEffect } from "react";
import useConnection from "controllers/useConnection";

import { ContextValue } from "../types";
import { getDebugLog } from "common";

const log = getDebugLog(false, "useSync");

export default function useSync(context:ContextValue){
  const {
    persistBond,
    persistNode,
    persist,
  } = context;
  
  const {isOffline} = useConnection();

  /**
   * This effect will trigger any time a node or bond is added
   * or removed from the persist lists.
   */
  useEffect(
    () => {
      // console.log("PREVENT SYNC")
      // return;
      log("checking if we need to sync")
      if (persist.isSyncing) {
        log("already syncing")
        return;
      }
      // check if we need to sync
      if (!persist.needsSync) {
        log("no need to sync")
        return persist.setSyncing(false);
      } else if (isOffline) {
        log("no connected")
        return persist.setSyncing(false);
      }
      // set the isSyncing flag
      persist.setSyncing(true);
      
      // first sync all nodes because they
      // need to be created before the bonds
      log("persistNodeIds", persist.persistNodeIds)
      if (persist.persistNodeIds.length){
        const nextNodeId = persist.persistNodeIds[0];
        log("syncing next node", nextNodeId)
        persistNode(nextNodeId);
        return;
      }
      // sync all bonds after the nodes have been synced
      if (persist.persistBondIds.length){
        const nextBondId = persist.persistBondIds[0];
        log("syncing next bond", nextBondId)
        persistBond(nextBondId);
        return;
      }
    },
    [
      persist,
      persist.needsSync,
      persist.isSyncing,
      isOffline,
      persistNode,
      persistBond,
    ]
  )
  
}


