import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { ChevronIcon, EllipsisIcon } from "common/icons";

import { Node, Bond, BondKind, AnyNode } from "components/graphql";
import { TagType } from "components/Tag/types";
import Tag from "components/Tag";
import AddTag from "components/AddTag";

import useEditor from "../editor/useEditor";
import useLineContext from "./useLineContext";
import { getDebugLog } from "common";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = getDebugLog(false, "LineTags");

interface UpdateBond {
  valueId?:string,
  boolean?:boolean,
  number?:number | null,
  datetime?:string,
}

function useLineTags(){
  const {bondId, nodeId, parentId, parentBondId} = useLineContext();
  const {editor} = useEditor();
  const {rootNodeId, filterParams} = editor
  const [filteredBonds, setFilteredBonds] = useState<Bond[]>([]);
  const [shouldFilter, setShouldFilter] = useState(true);

  function toggleShouldFilter(){
    setShouldFilter(current => !current);
  }
  
  const parentBonds = editor.persist.getParentBonds(nodeId, BondKind.CONTENT);

  // filter bonds by parent and filterParams
  useEffect(()=>{
    // get the bond
    const bond = editor.persist.getBond(bondId);

    const isSubgroup = bond && bond?.kind === BondKind.SUBGROUP;

    // if there are no bond we good
    if (!parentBonds) return;
    
    // filter out the root and filter params parents
    if (shouldFilter && parentId){
      
      // for subgroups, show no parent bonds when filtered
      if (isSubgroup){
        setFilteredBonds([]);
        return;
      }
      const parentIsRoot = parentId === rootNodeId;
  
      // the params that should not be displayed
      // hide filterParams if the parent is the root, since we know they're there
      const params = parentIsRoot ? [...filterParams] : [];
      
      // hide the parent node from the list
      const parentNode = editor.persist.getNode(parentId);
      params.push(makeParentParam(parentNode));

      // hide the parent's parent if its a subgroup
      const parentBond = editor.persist.getBond(parentBondId);
      if (parentBond && parentBond.kind === BondKind.SUBGROUP){
        const grandParentNode = editor.persist.getNode(parentBond.parentId)
        params.push(makeParentParam(grandParentNode))
      }
      const updatedBonds = parentBonds.filter((bond:Bond) => {
        return editor.displayParentBond({bond, filterParams:params});
      })
      setFilteredBonds(updatedBonds);
    
    } else if (isSubgroup){
      // for subgroups, only show the parent bond when unfiltered
      setFilteredBonds([bond]);
    } else {
      // show all the parent bonds
      const updatedBonds = parentBonds.filter((bond:Bond) => !bond.isHidden);
      setFilteredBonds([...updatedBonds]);
    }
  }, [
    editor,
    parentBonds,
    parentBondId,
    bondId,
    nodeId,
    shouldFilter,
    parentId,
    rootNodeId,
    filterParams,
  ])

  
  function createParentBond(node:AnyNode){
    const parentId = node?.id;
    editor.createParentBond({
      nodeId,
      parentId,
    })
  }
 
  return {
    toggleShouldFilter,
    createParentBond,
    filteredBonds,
    shouldFilter,
    nodeId,
  }
}

export const LineTags = observer(function(){
  const {
    toggleShouldFilter,
    createParentBond,
    filteredBonds,
    shouldFilter,
    nodeId,
  } = useLineTags();

  return (
    <>
      <AddTag
        onNodeSelect={createParentBond}
        orderBy="last_tagged"
        nodeId={nodeId}
      />
      
      <button
        data-selection-nav
        onClick={toggleShouldFilter}
      >
        {shouldFilter ? <EllipsisIcon/> : <ChevronIcon direction="left"/>}
      </button>

      <div className="line-tag-container">
        {filteredBonds.map((bond) => (
          <LineTag
            key={bond.id}
            bondId={bond.id}
            showRemove={!shouldFilter}
          />
        ))}
      </div>
    </>
  )
})

const LineTag = observer(function(props:{bondId:string, showRemove:boolean}){
  const {bondId} = props
  const {nodeId, parentId} = useLineContext();
  const {editor} = useEditor()
  const {deleteBond, updatePropValue, addFilter, setSelectedBond} = editor

  const bond = editor.persist.getBond(bondId);

  function onDelete(bondId:string){
    deleteBond({bondId, updateSelection:false});
  }

  function handlePropValueUpdate(bondId:string, params:UpdateBond){
    const {
      boolean,
      number,
      valueId,
      datetime,
    } = params;
    
    const variables = {
      bondId,
      boolean,
      number,
      valueId,
      datetime,
    }
    log("update tag prop value", variables)
    updatePropValue(variables)
    // move the selection back to the line
    setSelectedBond({nodeId, parentId})
    
  }

  return (
    <Tag
      key={bond.id}
      id={bond.id}
      parentId={bond.parentId}
      valueId={bond.valueId}
      boolean={bond.boolean}
      number={bond.number}
      datetime={bond.datetime}
      onRemove={onDelete}
      onPropValueUpdate={handlePropValueUpdate}
      onAltClick={addFilter}
      type={TagType.BOND}
      showRemove={props.showRemove}
    />
  )
})

/**
 * adds a filter param for the parent of a line
 */
function makeParentParam(node:Node){
  const param = {
    parentId: node.id
  };
  return param
}


