import {useCallback} from "react"
import { gql, useMutation } from '@apollo/client';

import { getDebugLog } from "common";
import { FRAG_BOND_ANY, FRAG_BOND_PARENT } from 'components/graphql';
import useErrors from "controllers/useErrors";

import { ContextValue } from "../types";
import { localBond } from "../state";

const log = getDebugLog(false, "usePersistBond");
/**
 * Send the updated bond to the server
 */
export default function usePersistBond(context:ContextValue){
  const {persist} = context;

  const {hasResponseError, onGqlError} = useErrors()

  const [mutate] = useMutation(GQL, {onError:onGqlError});


  context.persistBond = useCallback(
    (bondId:string)=>{
      log("persistBond", bondId)
      // get the local bond value
      const bond = localBond.get(bondId);

      if (!bond) {
        log("bond not found", bondId);
        persist.removePersistBondId(bondId)
        return;
      }

      let {
        sortIndex,
        ...cleanBond
      } = bond;


      // sync it to the backend
      const variables = {bond:cleanBond};
      mutate({variables}).then(({data, errors})=>{
        if (errors) {
          console.log("errors", errors)
          persist.removePersistBondId(bondId)
          return;
        }
        const res = data.bondPersist;
        if (hasResponseError(res)) {
          persist.removePersistBondId(bondId)
          return;
        };
        const {bond, newBonds, deletedBondIds} = res;
        // remove the node id from the persistNodeIds
        persist.postPersistBond({bond, newBonds, deletedBondIds});
      });
    },
    [persist, mutate, hasResponseError]
  )
}

const GQL = gql`
  mutation BondPersist(
    $bond: BondInput!,
  ) {
    bondPersist(
      bond: $bond,
    ) {
      success
      errorMessage
      errorData
      bond {
        ...BondParent
      }
      newBonds {
        ...BondAny
      }
      deletedBondIds
    }
  }
  ${FRAG_BOND_PARENT}
  ${FRAG_BOND_ANY}
`;