import { useCallback } from "react";


import { ContextValue, View} from "../types";

export default function useLocalDatetime(context:ContextValue):void{
  const {dispatch } = context;

  context.setLocalDatetimeText = useCallback(
    (localDatetimeText: string) => {
      
      dispatch({
        type: "setLocalDatetimeText",
        payload: {
          localDatetimeText,
        }
      })
    },
    [dispatch]
  )

  context.setLocalDatetime = useCallback(
    (localDatetime: Date) => {
      dispatch({
        type: "updateState",
        payload: {
          localDatetime,
          view:View.CALENDAR
        }
      })
    },
    [dispatch]
  )
}
