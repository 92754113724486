import React from "react"
import {observer} from "mobx-react-lite"

import View from "./MetaEditor"
import {MetaEditorProvider} from "controllers/useMetaEditor"
import useEditor from "components/editor/useEditor"


const MetaEditor = observer(function(){
  const {rootNodeId} = useEditor()
  if (!rootNodeId) return <>Missing Node Id</>;
  const metaProps = {
    nodeId: rootNodeId,
  }
  return (
    <MetaEditorProvider {...metaProps}>
      <View/>
    </MetaEditorProvider>
  )
})

export default MetaEditor