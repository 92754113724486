import React from 'react';

export function TargetIcon() {
  return (
    <svg
      className='icon'
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15"
        cy="15"
        r="10"
        stroke="currentColor"
        strokeWidth="5"
      />
    </svg>
  );
}
