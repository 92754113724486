
import React, { useState } from "react";

import NodeLookupInput from "components/NodeLookupInput";
import {AnyNode} from "components/graphql"
import { OrderBy} from "components/NodeLookupInput/useNodeLookup/types";
import { PlusIcon } from "common/icons";
import { getDebugLog } from "common/utils";

const log = getDebugLog(false, "AddTag");

interface AddTagProps {
  onNodeSelect:(node:AnyNode)=>void,
  orderBy:OrderBy,
  nodeId?:string,
  filterNodeId?:string,
}

function AddTag(props:AddTagProps){
    const {
      onNodeSelect,
      orderBy,
      nodeId="",
      filterNodeId="",

    } = props;
    
    const [active, setActive] = useState(false);

    function handleNodeSelect(node:AnyNode, created:boolean){
      onNodeSelect(node);
      setActive(false);
    }

    function open(event:React.MouseEvent){
      log("open")
      setActive(true);
      event.preventDefault()
      event.stopPropagation()
    }

    function close(){
      log("close")
      setActive(false);
    }

    if (!active) return <AddTagButton onClick={open}/>
    
    log("rendering tag input")
    return (
        <NodeLookupInput
          showSuggestions
          onSelectNode={handleNodeSelect}
          onBackspaceEmpty={close}
          pageSize={10}
          orderBy={orderBy}
          nodeId={nodeId}
          filterNodeId={filterNodeId}
          onClose={close}
          minWidth={100}
        />
    )
}

interface ButtonProps {
  onClick: (event:React.MouseEvent)=>void,
}

function AddTagButton(props:ButtonProps){
    const {onClick} = props;

    return (
      <button
        data-selection-nav
        onClick={onClick}
      >
        <PlusIcon/>
      </button>
    );
}

const AddTagMemo = React.memo(AddTag);

export default AddTagMemo