import { ContextValue } from "../types";
import useSetDecision from "./useSetDecision";
import useSetPosition from "./useSetPosition";
import useUpdateState from "./useUpdateState";

const methodHooks: {(context: ContextValue): void}[] = [
  useUpdateState,
  useSetDecision,
  useSetPosition,
];

export default methodHooks