import { ContextValue } from "../types";
import useNeedsSelection from "./useNeedsSelection";
import useNodes from "./useNodes";
import useRemove from "./useRemove";
import useSelect from "./useSelect";

const methodHooks: {(context: ContextValue): void}[] = [
  useNodes,
  useRemove,
  useSelect,
  useNeedsSelection,
];

export default methodHooks