
/**
 * When active, it returns function that logs to the console
 */
export function getDebugLog(enable:boolean, nameSpace="") {
  if (enable) {
    const log = (...args: any[]) => console.log(`${nameSpace}:`, ...args);
    log("enabled")
    return log;
  }
  else return (...args: any[]) => {};
}
