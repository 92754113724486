import { useCallback } from "react";
import { ContextValue } from "../types";

export default function useSetActiveSuggestion(context:ContextValue):void{
  const {dispatch, loadMore, stateRef} = context

  context.setActiveSuggestion = useCallback(
    (activeSuggestion:number)=>{
      const {paginate, nodeIds} = stateRef.current
      if (paginate && activeSuggestion === nodeIds.length) {
        loadMore()
      } else {
        dispatch({
          type:"setActiveSuggestion",
          payload: {
            activeSuggestion
          }
        })
      }
    },
    [dispatch, stateRef, loadMore]
  )
}
