import React from "react";
import { observer } from "mobx-react-lite";
import { createContext, useContext } from "react";

import {useContextController} from "common"

import { ContextValue, State} from "./types";
import defaultState from "./constants";

import * as reducerFunctions from "./reducers"
import methodHooks from "./hooks";
import useEditor from "components/editor/useEditor";
import { bracketSort } from "./helpers";
import { shuffle } from "lodash";
import {BondKind} from "components/graphql"

type ProviderProps = {
  children: React.ReactNode,
}

const Context = createContext<ContextValue>({} as ContextValue);

export const Provider = observer(function(props: ProviderProps){
  const {
    children,
  } = props;

  const bonds = useBonds();
  
  let initialState = defaultState({
    bonds,
  })
  
  // set the initial state to be the sorted bonds
  initialState = bracketSort(initialState);
  const passedValues = {
    // inherited methods or values
  }
  const value: ContextValue = useContextController<State, ContextValue>(initialState, reducerFunctions, methodHooks, passedValues)

  return (<Context.Provider value={value}>{children}</Context.Provider>);
})

/**
 * A convenience hook for accessing the context value
 */
export default function useView(){
  return useContext(Context);
}

function useBonds(){
  const {editor} = useEditor();
  
  // get the current root bonds
  let bonds = editor.getFilteredChildBonds("");
  
  // only show content bonds
  bonds = bonds.filter(bond => bond.kind === BondKind.CONTENT)

  // randomize the order of the bonds
  bonds = shuffle(bonds)
  
  return bonds;
}


