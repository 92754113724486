import { State, UpdateSearchPayload } from "../types";

export default function updateSearch(state:State, payload:UpdateSearchPayload):State{
  const {text, nodes} = payload;
  
  const nodeIds = nodes.map(({id}) => id);
  // set hasMore to false if the number of nodes returned is less than the limit
  const hasMore = nodeIds.length === state.pageSize;

  return {
    ...state,
    nodeText:text,
    nodeIds,
    hasMore,
    loading:false,
    // reset the input to the active selection
    activeSuggestion:-1,
    // reset the page number
    pageNumber:1,
  }
}