import React from 'react'
import {observer} from 'mobx-react-lite'

import EditorBackButton from 'components/EditorBackButton/EditorBackButton'
import useEditor from 'components/editor/useEditor'

import useSort from "./useSort"
import { Bond } from 'components/graphql'
import { MaxWidthWrapper } from 'components/layout'

export default function Sort(){
  return (
    <div className='editor'>
      <MaxWidthWrapper>
        <EditorBackButton/>
        <h1>Compare</h1>
        <SortOptions/>
        <SortedProgress/>
      </MaxWidthWrapper>
    </div>
  )
}

/**
 * Determines the next two nodes to be sorted.
 */
function SortOptions(){
  const {optionA, optionB } = useSort();

  if (!optionA || !optionB) return null

  return (
    <div className="sort-options">
      <h2>Which is greater?</h2>
      <Option bond={optionA}/>
      <>or</>
      <Option bond={optionB}/>
    </div>
  )
}

const SortedProgress = observer(function(){
  const {sortedBonds, bonds, loopRemaining} = useSort();
  const {editor} = useEditor()

  return (
    <>
      {loopRemaining ? (
        <>
        {/* <div>~{loopRemaining} comparisons until the next item is sorted.</div> */}
        <div>{sortedBonds.length}/{bonds.length} sorted:</div>
        </>
      ) : (
        <div>Done!</div>
      )}
      <ol>
        {sortedBonds.map(bond => {
          const {nodeId} = bond;
          const {text} = editor.persist.getNode(nodeId);
          return <li key={nodeId}>{text}</li>
        })}
      </ol>
    </>
  )
})

/**
 * Sort Option Component
 * 
 * Displays the text for the given nodeId
 * 
 * When clicked it denotes the option as the greater of the two.
 */
const Option = observer(function (props:{bond:Bond}){
  const {bond} = props;
  const {editor} = useEditor()
  const {setDecision} = useSort();
  const {text} = editor.persist.getNode(bond.nodeId);

  function handleClick(){
    setDecision(bond.id);
  }
  
  return (
    <div>
      <button className="option" onClick={handleClick}>
        <p>{text}</p>
      </button>
    </div>
  )
})