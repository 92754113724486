import { 
  PropType, 
  NodeKind, 
  Bond,
  SortParam,
} from "components/graphql";
import { EditorStoreType } from "./storeTypes";

/**
 * Sorts a list of bonds based on bond.position
 */
export const orderBonds = function(this:EditorStoreType, parentId:string, bonds:Bond[]):void {
  bonds.sort((bond1, bond2) => {
    let a, b;
    // only sort the root children by sort params
    const sortParams = parentId === this.rootNodeId ? this.sortParams : [];
    // loop over each sort param
    for (let i = 0; i < sortParams.length; i++){
      const sortParam = sortParams[i];
      // flip the sort order if desc
      const [gt, lt] = !sortParam.desc ? [1, -1] : [-1, 1]; 
      a = this.getOrderValue(sortParam, bond1)
      b = this.getOrderValue(sortParam, bond2)
      // if there's a difference, return the difference
      if (a > b) return gt;
      if (a < b) return lt;
      // otherwise check the next param
    }
    // if there's no difference, sort by position
    a = bond1.position;
    b = bond2.position;
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  })
}

/**
 * Get the value of a bond based on the sortParam
 * for use in sorting the bonds.
 */
export const getOrderValue = function(this:EditorStoreType, sortParam:SortParam, bond:Bond):any{
    const {getNode, getBondFromNodes} = this.persist;
    
    const node = getNode(bond.nodeId);
    
    if (sortParam.key === "created_at") return new Date(bond.createdAt);
    if (sortParam.key === "updated_at") return new Date(node.updatedAt);
    if (sortParam.key === "alpha") return node.text.toLowerCase();
    if (sortParam.key === "random") return bond?.sortIndex || -1;
    if (sortParam.key === "parent" && sortParam.parentId) {
      // get the bond between this child and the sort parentId
      // the check if the bond parent is the same as the sort parentId
      // otherwise, look up the parent bond
      const parent = getNode(sortParam.parentId);
      if (!parent) return;
      const parentBond = parent.id === bond.parentId ? bond : getBondFromNodes(parent.id, bond.nodeId);
      // if the parent is a plain node, return if the child has a bond to the parent
      if (parent.kind === NodeKind.NODE) return parentBond ? 0 : 1;
      // if the bond doesn't exist, return undefined
      if (!parentBond) return;
      if (parent.propType === PropType.BOOLEAN) {
        if (parentBond.boolean === true) return 0;
        if (parentBond.boolean === false) return 1;
        return 2;
      }
      if (parent.propType === PropType.NUMBER) return parentBond.number;
      if (parent.propType === PropType.DATETIME) return parentBond.datetime ? new Date(parentBond.datetime) : 0;
      if (parent.propType === PropType.NODE) {
        const valueNode = getNode(parentBond.valueId!);
        return valueNode.text.toLowerCase();
      }
    }
  }