import React from "react"
import {observer} from "mobx-react-lite"

import useErrors from "controllers/useErrors"
import Modal from "components/Modal";

const Errors = observer(function(){
  const {hasErrors, errors, removeError} = useErrors();

  function clearErrors(){
    removeError(0)
  }
  if (!hasErrors) return null;

  const errorMessage = errors[0];

  return (
    <Modal onCancel={clearErrors}>
      {errorMessage}
    </Modal>
  )
})
export default Errors