import React, { createContext, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";

import {useContextController} from "common"

import { ContextValue, State, ProviderFunctionProps, ProviderStateProps} from "./types";
import defaultState from "./constants";
import reducerFunctions from "./reducers"
import methodHooks from "./hooks";

interface ProviderProps extends ProviderFunctionProps, ProviderStateProps {
  children: React.ReactNode,
}

export const NodeSearchContext = createContext<ContextValue>({} as ContextValue);


export const Provider = observer(function (props: ProviderProps){
  const {
    children,
    nodeId="",
    filterNodeId="",
    propId="",
    orderBy,
    pageSize,
    onSelectNode,
    onBackspaceEmpty,
    showSuggestions,
    showZeroState=false,
    preventClose=false,
    placeholder="Search or Create",
    onClose,
    selectionId="",
    paginate=false,
    searchTerm:nodeText = "",
    onTextChange,
    minWidth=50,
    onBlur=()=>{},
  } = props;

  const initialState = defaultState({
    nodeId,
    propId,
    filterNodeId,
    orderBy,
    pageSize,
    showSuggestions,
    placeholder,
    preventClose,
    selectionId,
    paginate,
    showZeroState,
    nodeText,
  })

  const value: ContextValue = useContextController<State, ContextValue>(
    initialState,
    reducerFunctions,
    methodHooks,
    {
      onSelectNode,
      onBackspaceEmpty,
      onClose,
      onBlur,
      minWidth,
    }
  )

  // update the parent when the text changes
  useEffect(()=>{
    onTextChange?.(value.nodeText)
  }, [onTextChange, value.nodeText])

  return (<NodeSearchContext.Provider value={value}>{children}</NodeSearchContext.Provider>);
})

/**
 * A convenience hook for accessing the context value
 */
export default function useView(){
  return useContext(NodeSearchContext);
}
