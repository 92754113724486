import React, {useEffect} from 'react';
import clsx from 'clsx';
import useViewport from 'common/contexts/useViewport';

import "./action-bar.scss";
import { getDebugLog } from 'common';
import useActiveElement from 'common/hooks/useActiveElement';


const log = getDebugLog(false, "ActionBar")
interface ActionBarProps {
  label?: string,
  children?: React.ReactNode;
  mobileOnly?: boolean;
}

export function ActionBarContainer(props:ActionBarProps){
  const {
    children,
    label="",
    mobileOnly=false,
  } = props;

  const {
    isMobile,
  } = useViewport();


  const activeElement = useActiveElement();
  const {actionBarLabel=""} = activeElement?.dataset || {};
  // only show when the active element has the node action bar label
  if (label !== actionBarLabel) {
    log("hide action bar", {label, actionBarLabel})
    return null
  }

  // only show on mobile
  if (mobileOnly && !isMobile){
    log("hide action bar", {mobileOnly, isMobile, label})
    return null;
  }
  
  return (
    <ActionBar
      label={label}
    >
      {children}
    </ActionBar>
  )
}

function ActionBar(props:ActionBarProps){
  const {
    children,
    label="",
  } = props;

  const {keyboardOpen} = useViewport();

  const [style, setStyle] = React.useState<React.CSSProperties>({})

  function updateStyle(retry=true){
    const {height = 0} = window.visualViewport || {}
    // add the scroll offset to the top
    const {scrollY} = window;
    const scrollOffset = scrollY > 0 ? scrollY : 0;
    const top = height + scrollOffset;
    log("update style", {retry, label, top, height, scrollOffset, scrollY})
    setStyle({
      top,
    })
    // set a timeout to try again
    if (retry) {
      // call it again in 1s
      log("retry update style")
      setTimeout(() => {
        updateStyle(false);
      }, 200);
    }
  }

  // listen to scroll events
  useEffect(() => {
    
    const updateStyleListener = () => {
      updateStyle();
    }
    
    // call on mount
    updateStyleListener();

    window.addEventListener("scroll", updateStyleListener);
    window.addEventListener("resize", updateStyleListener);
    window.addEventListener("focusin", updateStyleListener);
    window.addEventListener("focusout", updateStyleListener);
    window.addEventListener("visibilitychange", updateStyleListener);
    return () => {
      window.removeEventListener("scroll", updateStyleListener);
      window.removeEventListener("resize", updateStyleListener);
      window.removeEventListener("focusin", updateStyleListener);
      window.removeEventListener("focusout", updateStyleListener);
      window.removeEventListener("visibilitychange", updateStyleListener);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[])

  const classNames = clsx("action-bar", keyboardOpen && "keyboard-visible");

  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  )
}

interface ActionButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export function ActionButton(props:ActionButtonProps){
  const {children, onClick, disabled=false} = props;

  function handleClick(e:React.MouseEvent){
    e.preventDefault();
    e.stopPropagation();
    if (onClick) onClick();
  }

  if (disabled) return null;

  return (
    <button className='action-button button-default' onMouseDown={handleClick}>
      <label>{children}</label>
    </button>
  )
}

