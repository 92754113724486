import { ContextValue } from "../types";

import useUpdateState from "./useUpdateState";
import useViewportListener from "./useViewportListener";

const methodHooks: {(context: ContextValue): void}[] = [
  useUpdateState,
  useViewportListener,
];

export default methodHooks