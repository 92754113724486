import React, {useState} from "react";

import { getDebugLog } from "common";
import { ClickOutsideContainer } from "components/ClickOutsideContainer/ClickOutsideContainer";
import "./selectors.scss"


const log = getDebugLog(false, "Selectors");

type OptionValue = string | boolean | object
interface Option {
  display:string,
  value:OptionValue,
  key:string,
}

export function option(display:string, value:OptionValue): Option{
  return {
    display,
    value,
    key: value.toString(),
  }
}

interface SelectProps {
  value:OptionValue,
  setValue(value:OptionValue):void,
  options:Option[],
}

export function SelectToggle(props:SelectProps){
  const {value, setValue, options} = props;
  
  const selected = options.find(option => option.value === value);

  if (!selected) return null;

  function selectNextOption(){
    let idx = options.findIndex(option => option.value === value);
    idx += 1
    if (idx === options.length) idx = 0;
    setValue(options[idx].value);
  }

  return (
    <button
      onClick={selectNextOption}
    >
      {selected.display}
    </button>
  )
}


export function SelectDropDown(props:SelectProps){
  const {
    value,
    setValue,
    options,
  } = props;

  const selected = options.find(option => option.value === value);
  const longestDisplay = options.reduce((longest, option) => {
    if (option.display.length > longest.length) return option.display;
    return longest;
  }, "");
  const [show, setShow] = useState(false);

  function showOptions(){
    setShow(true)
  }

  function hideOptions(){
    setShow(false)
  }

  function selectOption(option:Option){
    setValue(option.value)
    setShow(false)
  }

  if (!selected) return null;

  return (
    <div className="selector-container">
      {show ? (
        <>
          <button className="spacer">{longestDisplay}</button>
          <ClickOutsideContainer onClose={hideOptions} className="selector-dropdown">
            {options.map(option =>(
              <div
              key={option.key}
              className="selector-option"
              onClick={() => selectOption(option)}
              >
                {option.display}
              </div>
            ))}
          </ClickOutsideContainer>
        </>
      ) : (
        <button
          onClick={showOptions}
        >
          {selected.display}
        </button>
      )}
    </div>
  )
}