import { ContextValue } from "../types";
import usePersistBond from "./usePersistBond";
import usePersistNode from "./usePersistNode";
import useSync from "./useSync";

const methodHooks: {(context: ContextValue): void}[] = [
  usePersistNode,
  usePersistBond,
  useSync,
];

export default methodHooks