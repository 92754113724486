import updateSearch from "./updateSearch"
import setActiveSuggestion from "./setActiveSuggestion"
import loadMore from "./loadMore"

const reducerFunctions = {
  updateSearch,
  setActiveSuggestion,
  loadMore,
}

export default reducerFunctions