import { useMutation, gql } from '@apollo/client';

import useErrors from "controllers/useErrors";

import { ContextValue } from "../types";
import { useCallback } from 'react';


export default function useCreatePage(context:ContextValue):void{
  const {onGqlError, setShowLogin} = useErrors();

  const [mutate] = useMutation(GQL, {onError:onGqlError});

  context.logout = useCallback(()=>{
    mutate();
    setShowLogin(true);
  }, [mutate, setShowLogin]);
}


const GQL = gql`
  mutation Logout {
    logout {
      ok
    }
  }
`;