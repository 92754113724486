import { State } from "./types";

/**
 * A helper function to determine if bondA is greater than bondB.
 * or if a decision hasn't been made yet.
 */
export function isGreaterThan(state:State, idxA:number, idxB:number){
  const {bonds} = state;
  const bondA = bonds[idxA];
  const bondB = bonds[idxB];
  if (hasWin(state, bondA.id, bondB.id)) return true;
  if (hasWin(state, bondB.id, bondA.id)) return false;
  return null;
}

/**
 * A helper function to determine if bondA has beaten bondB.
 */
export function hasWin(state:State, bondIdA:string, bondIdB:string){
  const wins = state.bondWins[bondIdA] || [];
  return wins.includes(bondIdB);
}


// BRACKET SORT

export function bracketSort(state:State):State {
  let { start, end, next } = state;
  const sortedBonds = [...state.sortedBonds];
  const total = state.bonds.length;
  console.log("bracketSort!!!", "start", start, "end", end, "next", next, "total", total, state.bonds)
  while(end > start){
    // loop over the unsorted bonds
    
    while(end > start) {
      
      // loop over each pair of bonds in the remaining list
      while(next <= end) {
        const idxA = next;
        const idxB = next + 1;
        const needsComparison = idxB <= end;
        let greaterIdx = idxA;
        console.log("comparing bonds", idxA, idxB, needsComparison)
        // if there is a second bond, compare the two bonds
        if (needsComparison) {
          const isGreater = isGreaterThan(state, idxA, idxB);
          // if the system needs a decision, return the state
          if (isGreater === null) {
            console.log("NEEDS DECISION!")
            return {
              ...state,
              bonds: [...state.bonds],
              sortedBonds,
              optionA: state.bonds[idxA],
              optionB: state.bonds[idxB],
              start,
              end,
              next,
            };
          }
          // otherwise, set the greater bond index
          greaterIdx = isGreater ? idxA : idxB;
          console.log("HAVE DECISION!", isGreater, greaterIdx)
        }
        
        // move the greater bond to the front of the unsorted bonds
        // remove it from it's current index
        const [bond] = state.bonds.splice(greaterIdx, 1);
        // add it to the start of the unsorted bonds
        state.bonds.splice(start, 0, bond);
        
        // go to the next pair of bonds
        next += 2;
      }
      // move the end to the middle of the unsorted bonds
      let winnerCount = end - start;
      // we want to end halfway through the unsorted bonds
      // subtract half of the winner count from the current end
      end -= Math.ceil( winnerCount / 2);
      next = start;
      console.log("NEXT round of brackets!", "start", start, "end", end, "next", next)
    }

    // there should be 1 item left
    // add it to the sorted bonds
    console.log("SORTED!", "start", start);
    sortedBonds.push(state.bonds[start]);
    // move the start to the next unsorted bond
    start++;
    // reset the end to the end of all bonds
    end = total - 1;
    next = start;
    console.log("Sorted Another!", "start", start, "end", end, "next", next)
  }
  // push the last bond to the sorted bonds
  sortedBonds.push(state.bonds[end]);
  return {
    ...state,
    bonds: [...state.bonds],
    sortedBonds,
    loopRemaining:0,
    optionA: null,
    optionB: null,
    start,
    end,
    next,
  };
}


// LINEAR SORT

/**
 * This is a linear sort implementation.
 * This approach is optimized to find the greatest bond first,
 * then the second greatest bond, etc.
*/
export function linearSort(state:State):State {
  const { bonds } = state;
  const sortedBonds = [...state.sortedBonds];
  const unsortedTotal = bonds.length - sortedBonds.length;
  let end = unsortedTotal - 1 ;
  
  // each of these loops will find the greatest unsorted bond
  while(end > 0) {
    let greatestIdx = 0;

    // iterate through the unsorted bonds
    // and keep comparing the current greatest bond to the next bond 
    for (let nextIndex = 1; nextIndex <= end; nextIndex++) {
      
      // if the current bond is the greatest bond, skip it
      if (greatestIdx === nextIndex) continue;

      // check if the next bond is greater than the current greatest bond
      const isGreater = isGreaterThan(state, nextIndex, greatestIdx);

      // if the system needs a decision, return the state
      if (isGreater === null) {
        return {
          ...state,
          sortedBonds,
          loopRemaining: end - nextIndex + 1,
          optionA: bonds[greatestIdx],
          optionB: bonds[nextIndex],
        };
      }
      
      // if the next bond is greater, update the greatest bond
      if (isGreater) {
        // moves the previous greatest bond to the start of the unsorted bonds
        // so it will be tried first in the next iteration
        const [lastGreatestBond] = bonds.splice(greatestIdx, 1);
        bonds.unshift(lastGreatestBond)
        // set the next bond as the greatest bond
        greatestIdx = nextIndex;

      }
    }

    
    // swap the greatest bond with the last bond
    const greatestBond = state.bonds[greatestIdx];
    state.bonds[greatestIdx] = state.bonds[end];
    state.bonds[end] = greatestBond;

    // move the greatest bond to the sorted bonds
    sortedBonds.push(greatestBond);
    // iterate over the remaining bonds
    end--;
  }
  // add the last bond to the sorted bonds
  sortedBonds.push(bonds[0]);
  
  return {
    ...state,
    sortedBonds,
    loopRemaining:0,
    optionA: null,
    optionB: null,
  };
}


// HEAP SORT

/**
 * This is a heap sort implementation that uses a max heap.
 * 
 * If the system needs a decision, it will return the state with the optionA and optionB properties set.
 */
export function heapSort(state:State):State {
  const total = state.bonds.length;
  // Start with the last non-leaf node
  let currentIdx = Math.floor(total / 2 - 1);
  
  
  // create the max heap
  while (currentIdx >= 0) {
    state = heapify(state, total, currentIdx);
    // if the system needs a decision, return the state
    if (state.optionA) return state;
    currentIdx--;
  }
  
  // sort the heap
  let end = total - 1;
  state.sortedBonds = [];
  while (end > 0) {
    // swap the first bond with the last bond
    const temp = state.bonds[0];
    state.bonds[0] = state.bonds[end];
    state.bonds[end] = temp;
    state.sortedBonds.push(temp);
    // call heapify on the reduced heap
    state = heapify(state, end, 0);
    // if the system needs a decision, return the state
    if (state.optionA) return state;
    end--;
  }
  state.sortedBonds.push(state.bonds[0]);

  // the bonds are sorted
  return {
    ...state,
  };
}

/**
 * This is a max heap implementation.
 * 
 * If the system needs a decision, it will return the state with the optionA and optionB properties set.
 * 
 * Otherwise, it will return the state with the bonds property set to the sorted bonds.
 */
export function heapify(state:State, total:number, currentIdx = 0):State {
  const { bonds } = state;

  let greatestIdx = currentIdx;
  const leftIdx = 2 * currentIdx + 1;
  const rightIdx = 2 * currentIdx + 2;

  // if the left index exists
  if (leftIdx < total){
    // check if the left bond is greater than the current greatest bond
    const decision = isGreaterThan(state, leftIdx, greatestIdx);
    if (decision === true) greatestIdx = leftIdx;
    else if (decision === null) {
      return {
        ...state,
        optionA: bonds[leftIdx],
        optionB: bonds[greatestIdx],
      }
    }
  }

  // if the right index exists
  if (rightIdx < total){
    // check if the right bond is greater than the current greatest bond
    const decision = isGreaterThan(state, rightIdx, greatestIdx);
    if (decision === true) greatestIdx = rightIdx;
    else if (decision === null) {
      return {
        ...state,
        optionA: bonds[rightIdx],
        optionB: bonds[greatestIdx],
      }
    }
  }

  if (currentIdx !== greatestIdx) {
    // swap the current bond with the greatest bond
    const temp = bonds[currentIdx];
    bonds[currentIdx] = bonds[greatestIdx];
    bonds[greatestIdx] = temp;
    // recursively call setOptions on the new greatest bond
    return heapify(state, total, greatestIdx);
  }

  return {
    ...state,
    bonds: [...bonds],
    optionA: null,
    optionB: null,
  };
}