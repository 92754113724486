import React from 'react';
import clsx from "clsx";

import useSelectDatetime from "../useSelectDatetime"
import "./calendar.scss";
import { ChevronIcon } from 'common/icons';



export default function Calendar(){
  const {
    select,
    localDatetime,
    setLocalDatetime,
  } = useSelectDatetime();

  const date = localDatetime || new Date();

  const currentDateNum = date.getDate();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();
  
  
  // get the last day of the month
  const lastDateOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  
  // get an array of the days of the month
  const daysOfTheMonth = []
  // figure out which day of the week the first day of the month is
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
  // add empty days from sunday to the first day of the month
  for (let i = 0; i < firstDayOfMonth; i++){
    daysOfTheMonth.push(null);
  }
  // add the days of the month
  for (let i = 1; i <= lastDateOfMonth; i++){
    daysOfTheMonth.push(i);
  }
  // add empty days to the end of the month
  while (daysOfTheMonth.length % 7 !== 0){
    daysOfTheMonth.push(null);
  }
  // split the days into weeks
  const weeks = [];
  for (let i = 0; i < daysOfTheMonth.length; i += 7){
    weeks.push(daysOfTheMonth.slice(i, i + 7));
  }

  const daysOfTheWeek = ["S", "M", "T", "W", "T", "F", "S"];

  const selectDay = (day:number | null) => {
    if (day === null) return;
    const newDate = new Date(currentYear, currentMonth, day);
    select(newDate);
  }

  const changeMonth = (adjust:number) => {
    const newDate = new Date(currentYear, currentMonth + adjust, 1);
    setLocalDatetime(newDate);
  }

  return (
    <div className="calendar">

      <div className="calendar-header">
        <button className="calendar-button" onClick={() => changeMonth(-1)}>
          <ChevronIcon direction="left"/>
        </button>
        <div className="calendar-month">
          {date.toLocaleString("default", {month: "long"})}
          {" "}
          {currentYear}
        </div>
        <button className="calendar-button" onClick={() => changeMonth(1)}>
          <ChevronIcon direction="right" />
        </button>
      </div>
      
      <div className="calendar-weekdays">
        {daysOfTheWeek.map((day, idx) => (
          <div className='calendar-weekday' key={idx}>{day}</div>
        ))}
      </div>
      
      {weeks.map((week, idx) => (
        <div key={idx} className="calendar-week">
          {week.map((day, idx) => {
            if (day === null) return <div key={idx} className="calendar-day"></div>
            return( 
              <div 
                className="calendar-day" 
                key={idx}
              >
                <button
                  className={clsx(
                    day === currentDateNum && "selected",
                  )}
                  onClick={() => selectDay(day)}
                >
                  {day}
                </button>
              </div>
            )
          })}
        </div>
      ))}

    </div>
  )
}