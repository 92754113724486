import React from "react";
import { observer } from "mobx-react-lite";

import Button from 'components/Button';
import useEditor from "components/editor/useEditor";
import ButtonNavBar from "components/ButtonNavBar";

const EditorBackButton = observer(function (){
  const {editor} = useEditor()
  const {openEditor} = editor
  return (
    <ButtonNavBar>

    <Button
      tooltip="Go back to the editor"
      onClick={openEditor}
    >
      Done
    </Button>
  </ButtonNavBar>
  )
})
export default EditorBackButton