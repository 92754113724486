import React from "react";

import { useRouteMatch, Route } from "react-router-dom";

import EditorApp from './EditorApp';

export default function Home() {
  let { path } = useRouteMatch();
  return (
    <div className="app">
      <Route path={`${path}:nodeId?`} component={EditorApp}/>
    </div>
  );
}
