import { action } from "mobx"

import { CreateParentBondParams, EditorStoreType } from "./storeTypes";
import { getDebugLog } from 'common';

const log = getDebugLog(false, "useCreateParent");

/**
 * Add the parent node id as a parent bond of the node id.
 */
export const createParentBond =  action(function(
  this:EditorStoreType,
  params: CreateParentBondParams,
){
  const {
    nodeId,
    parentId,
  } = params;

  // error if no parentId
  if (!parentId) return log("error: no parentId")

  this.persist.updateObjects({
    bonds:[{
      nodeId,
      parentId,
    }],
    nodes:[
      {
        id:parentId,
        isParent:true,
        lastTaggedAt:this.persist.now(),
      },
      {
        id:nodeId,
        isOrphan:false,
      },
    ],
  });
})